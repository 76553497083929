import React from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { getChallenge } from "../../Actions/ChallengesAction";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import EventTab from "./EventTabs";
import axios from "axios";
import { spacecapitalize } from "../../Utils/StringFunctions";
import moment from "moment";
import PubSub from "../../Utils/pubsup";
// function TabContainer(props) {
//   return (
//     <Typography component="div" style={{ padding: 8 * 3 }}>
//       {props.children}
//     </Typography>
//   );
// }
// TabContainer.propTypes = {
//   children: PropTypes.node.isRequired
// };

const styles = theme => {
  return {
    "@global": {
      body: {
        backgroundColor: "#fff"
      }
    },
    eventMainName: {
      fontWeight: 600,
      fontSize: "20px",
      // lineHeight: "27px",
      color: "#222222",
      margin: "15px",
      width: "90%"
    },
    heroUnit: {
      [theme.breakpoints.up(300)]: {
        // backgroundColor: "red"
      }
    },
    eventName: {
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "22px",
      color: "#222222"
    },
    groupSection: {
      display: "flex"
    },
    groupDetail: {
      display: "flex",
      flexDirection: "column"
    },
    eventContent: {
      display: "flex",
      justifyContent: "space-around"
    },
    teamGropNumber: {
      fontSize: "16px",
      lineHeight: "19px",
      color: "#222222",
      margin: "0px"
    },
    teamText: {
      fontSize: "12px",
      lineHeight: "19px",
      color: "#ABABAB",
      margin: "0px",
      textAlign: "left"
    },
    icon: {
      color: "#327C93",
      marginRight: "10px",
      background: "#E8F7FC",
      padding: "6px",
      width: "1.5em",
      height: "1.5em",
      border: "1px solid #E8F7FC",
      borderRadius: "6px"
    },
    eventDetail: {
      padding: "16px 10px",
      width: "90%",
      position: "relative",
      margin: "0 auto",
      top: "-24px",
      background: "#fff",
      borderRadius: "5px",
      textAlign: "center",
      boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)"
    },
    logo: {
      border: "1px solid #efefef",
      padding: "10px",
      borderRadius: "10px",
      height: "70px",
      width: "70px"
    },
    nodata: {
      textAlign: "center",
      marginTop: "100px",
      marginBottom: "200px"
    },
    mainchallenges: {
      height: "40px"
    },
    banner: {
      height: "180px"
    }
  };
};

class MobileChallengesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: {},
      value: 0,
      drawer: false,
      applied: []
    };
    this.state.id = this.props.match.params.id;
  }

  async componentDidMount() {
    this.props.getChallenge(this.state.id).then(r => {
      PubSub.send(
        "onNavBarTitleChange",
        this.props && this.props.challenge && this.props.challenge.event_name
      );
    });
    this.checkApplied();
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (nextProps.challenges !== prevState.challenges) {
  //     return { challenges: nextProps.challenges };
  //   } else return null;
  // }

  // componentDidUpdate(prevProps) {
  //   if (prevProps.challenges !== this.props.challenges) {
  //     this.setState({
  //       challenges: this.props.challenges
  //     });
  //   }
  // }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  closeDrawer = () => {
    this.setState({ drawer: false });
  };

  checkApplied = () => {
    axios
      .get(`/api/application/v2/participants`)
      .then(response => {
        this.setState({ applied: response.data });
      })
      .catch(error => {
        console.log(error);
      });
  };

  getCard() {
    const { classes, challenge } = this.props;
    const eventdate = moment(challenge.start_date);
    const todaysdate = moment();
    const date = eventdate.diff(todaysdate, "days");
    return (
      <div>
        <div className={classes.heroUnit}>
          <div>
            <img
              src={challenge.banner}
              alt=""
              width="100%"
              className={classes.banner}
            />
          </div>
          <div className={classes.challenges}>
            <div className={classes.eventDetail}>
              <img
                src={challenge && challenge.company && challenge.company.logo}
                alt="img"
                className={classes.logo}
              />
              <p className={classes.eventName}>
                {spacecapitalize(challenge.event_name)}
              </p>
              <div className={classes.eventContent}>
                <div className={classes.groupSection}>
                  <GroupAddIcon className={classes.icon} />
                  <div className={classes.groupDetail}>
                    <p className={classes.teamGropNumber}>
                      {challenge.team_min} - {challenge.team_max} People
                    </p>
                    <p className={classes.teamText}>Team</p>
                  </div>
                </div>

                {date > 0 && (
                  <div className={classes.groupSection}>
                    <QueryBuilderIcon className={classes.icon} />
                    <div className={classes.groupDetail}>
                      <p className={classes.teamGropNumber}>{date}</p>
                      <p className={classes.teamText}>Days left</p>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* {challenge && challenge.pages && challenge.pages.length > 0 ? ( */}
            <div className={classes.mainchallenges}>
              <EventTab
                eventData={challenge}
                applied={this.state.applied}
                select
              />
            </div>
            {/* // ) : (
              // <div>Nodata</div>
            // )} */}
          </div>
        </div>
      </div>
    );
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        <main>{this.getCard()}</main>
      </React.Fragment>
    );
  }
}

MobileChallengesPage.propTypes = {
  classes: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    challenge: state.events.challenge,
    auth: state.auth
  };
};

export default connect(mapStateToProps, {
  getChallenge
})(withStyles(styles)(MobileChallengesPage));
