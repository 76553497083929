import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { useRouteMatch } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { logoutUser } from "./../../Actions/authAction";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useToasts } from "../../ToastManager";
import NavigatorItems from "../../Components/Layout/ParticipantDrawer";
import { clearCurrentProfile } from "./../../Actions/profileAction";
// import { createMuiTheme } from "@material-ui/core/styles";
import Overview from "../../Screens/EventPage/ViewaEventNotParticipated";
import SingleChallenge from "../../Screens/MobileScreen/MobileChallengesPage";
import Application from "../../Screens/EventPage/MyApplicationPage";
import Profile from "../../Screens/EventPage/MyProfile/Profile";
// import Profile from "../../Screens/EventPage/MyProfile/Profile1";
import ApplicationByEvent from "../../Screens/EventPage/MyApplicationPageByEvent";

import Team from "../../Screens/EventPage/MyTeamWebView";
import Stack from "../../Screens/EventPage/CreateStack";
import MobileTeam from "../../../src/Screens/MobileScreen/MyTeam";
import Drawer from "@material-ui/core/Drawer";
import Mychallenges from "../../../src/Screens/MobileScreen/Mychallenges";
import ApplicationForm from "../../../src/Screens/MobileScreen/ApplicationForm";
import ApplicationFormByEvent from "../../../src/Screens/MobileScreen/ApplicationFormByEvent";
// import MyProfile from "../../../src/Screens/MobileScreen/MyProfile";
import MbMyProfile from "../../../src/Screens/MobileScreen/MbMyProfile";
// import ViewMyProfile from "../../../src/Screens/EventPage/MyProfile/VIewMyProfile";
import MobileDrawer from "../../../src/Screens/MobileScreen/MobileDrawer";
import clsx from "clsx";
import UserAgent from "../../Utils/userAgent";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
// import ViewProfile from "../../Screens/EventPage/MyProfile/ViewProfile";

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: "flex",
    border: "none"
  },
  ListItems: {
    width: "240px",
    border: "none",
    position: "relative",
    transition: "width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
    whiteSpace: "nowrap"
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  drawerPaper: {
    // position: "relative",
    whiteSpace: "nowrap",
    // border: "none",
    // borderRight: "1px solid #EFEFEF",
    borderRight: props =>
      props.location.pathname === "/participant/my-application"
        ? "none"
        : "1px solid #EFEFEF",
    // boxShadow: " 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    width: drawerWidth,
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    zIndex: 1
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  appBarSpacer: {
    marginTop: "50px"
  },
  fixedHeight: {
    height: 240
  }
});
const theme = createMuiTheme({
  overrides: {
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: "none"
      }
    }
  }
});
const Participant = props => {
  const { classes } = props;
  const [open, setOpen] = useState(true);
  const { path } = useRouteMatch();
  console.log(
    "props.match.url",
    props.location.pathname === "/participant/my-application"
  );
  console.log("routeeeeee", props.location.pathname);
  const toastManager = useToasts();
  // const handleClick = () => {
  //   setOpen(!open);
  // };
  useEffect(() => {
    const { type, text, show } = props.notifications;

    if (show) toastManager.addToast({ type, text });
  }, [props.notifications]);
  useEffect(() => {
    onpopstate = event => {
      window.location.href = "/challenges";
    };
  }, []);
  const mt = UserAgent();
  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        {/* <Route
        exact
        path={`${path}/my-application`}
        component={mt ? Mychallenges : Application}
        history={props.history}
      /> */}
        {props.auth.isAuthenticated && (
          <div>
            {mt ? (
              <MobileDrawer history={props.history} />
            ) : (
              <Drawer
                variant="permanent"
                classes={{
                  paper: clsx(
                    classes.drawerPaper,
                    !open && classes.drawerPaperClose
                  )
                }}
                open={open}
              >
                <div className={classes.ListItems}>
                  <NavigatorItems history={props.history} />
                </div>
              </Drawer>
            )}
          </div>
        )}
        <Switch>
          <Route
            exact
            path={`${path}/overview/:id`}
            component={mt ? SingleChallenge : Overview}
          />
          <Route
            exact
            path={`${path}/my-application`}
            component={mt ? ApplicationForm : Application}
            history={props.history}
          />

          <Route
            exact
            path={`${path}/my-application/:id`}
            component={mt ? ApplicationFormByEvent : ApplicationByEvent}
            history={props.history}
          />
          <Route
            exact
            path={`${path}/my-team/:id`}
            component={mt ? MobileTeam : Team}
          />
          <Route exact path={`${path}/create-stack/:id`} component={Stack} />
          <Route
            exact
            path={`${path}/my-challenges`}
            component={mt && Mychallenges}
          />
          <Route
            exact
            path={`${path}/profile`}
            component={mt ? MbMyProfile : Profile}
            // component={mt ? ViewProfile : Profile}
            history={props.history}
          />
          {/* <Route
          exact
          path={`${path}/view-profile`}
          component={mt ? ViewProfile : ViewMyProfile}
          history={props.history}
        /> */}
        </Switch>
      </React.Fragment>
    </ThemeProvider>
  );
};

Participant.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    notifications: state.notifications.notification
  };
};

export default connect(mapStateToProps, { logoutUser, clearCurrentProfile })(
  withStyles(styles)(withRouter(Participant))
);
