import React, { useState, useEffect } from "react";

import _get from "lodash/get";
import SideDrawer from "./SideDrawer";
import { withStyles, Paper } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/icons/Menu";
import { connect } from "react-redux";
import PubSub from "../../Utils/pubsup";
import { spacecapitalize } from "../../Utils/StringFunctions";

const styles = () => ({
  container: {
    display: "flex",
    width: "100%"
  },
  IconButton: {
    width: "10%",
    padding: "0px",
    margin: "0px",
    marginLeft: "10px"
  },
  menuIcon: { fontSize: 25, margin: 15, width: "100%" },
  eventMainName: {
    fontWeight: 600,
    fontSize: "20px",
    color: "#222222",
    margin: "15px",
    width: "90%"
  }
});

const routeConfig = {
  "/participant/my-overview": {
    headerTitle: "Overview",
    options: []
  },
  "/participant/my-challenges": {
    headerTitle: "My Challenges",
    options: []
  },
  "/participant/my-team": {
    headerTitle: "My Teams",
    options: []
  },
  "/participant/my-application": {
    headerTitle: "My Application",
    options: []
  },
  "/challenges": {
    headerTitle: "Challenges",
    options: []
  },
  "/participant/profile": {
    headerTitle: "My Profile",
    options: []
  }
};

function MobileDrawer(props) {
  const { classes, pathName } = props;
  const [drawer, setdrawer] = useState(false);
  const [headerTitle, setHeaderTitle] = useState(
    _get(routeConfig, `${pathName}.headerTitle`, "")
  );

  const closeDrawer = () => {
    setdrawer(false);
  };

  useEffect(() => {
    PubSub.addEventListener("onNavBarTitleChange", setHeaderTitle);

    return function cleanup() {
      PubSub.removeEventListener("onNavBarTitleChange", setHeaderTitle);
    };
  }, []);

  useEffect(() => {
    setHeaderTitle(_get(routeConfig, `${pathName}.headerTitle`, ""));
  }, [pathName]);

  return (
    <div>
      <Paper className={classes.container}>
        <IconButton
          onClick={() => {
            setdrawer(true);
          }}
          className={classes.IconButton}
        >
          <Menu className={classes.menuIcon} />
        </IconButton>
        <p className={classes.eventMainName}>{spacecapitalize(headerTitle)}</p>
      </Paper>

      <SideDrawer open={drawer} close={closeDrawer} history={props.history} />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    pathName: state.router.location.pathname
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(MobileDrawer));
