/* eslint-disable jsx-a11y/anchor-is-valid */

import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, CssBaseline, Paper } from "@material-ui/core";
import logo from "../../Assets/eventlogo.png";
import classNames from "classnames";
import { connect } from "react-redux";
import { getEvent } from "../../Actions/eventActions";
import { getChallenge } from "../../Actions/ChallengesAction";
// import Settings from "../../Components/Common/Settings";
// import eventBanner from "../../Assets/eventbanner.png";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import ViewaEventTabs from "../ChallengesPage/ChallengeTabs";
import AddParticipantTeamModal from "../Landing/AddParticipantTeamModal";
import AddTeammateModel from "../Landing/InviteTeamMemberModal";
import axios from "axios";
import { spacecapitalize } from "../../Utils/StringFunctions";

const moment = require("moment");
const drawerWidth = 240;

// const ASSET_URL = process.env.REACT_APP_ASSET_URL;
const styles = () => ({
  mainsection: {
    width: `calc(100% - ${drawerWidth}px )`,
    position: "relative",
    left: drawerWidth,
    paddingTop: "5%"
  },
  pageheader: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "16px 0px"
  },
  mainLink: {
    textDecoration: "none",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "18px",
    color: "#222222",
    marginRight: "20px"
  },
  appBar: {
    position: "relative",
    backgroundColor: "transparent",
    height: "-webkit-fill-available"
  },
  logo: {
    minWidth: "70%"
  },
  orangeAvatar: {
    width: "60%",
    height: 95,
    fontSize: "36px",
    position: "relative",
    top: "-30px",
    left: "10%",
    borderRadius: "4px",
    objectFit: "contain",
    backgroundColor: "#fff"
  },
  eventTitle: {
    margin: "11px 0px 0px -8px",
    paddingBottom: "8px"
  },
  eventbasicdetail: {
    backgroundColor: "#fff",
    position: "relative"
  },
  alignTitle: {
    display: "flex"
  },
  eventName: {
    fontWeight: 600,
    fontSize: "32px",
    lineeight: "44px",
    color: "#222222"
  },
  subFont: {
    fontSize: "14px",
    paddingLeft: "4px",
    color: "#222222",
    display: "flex"
  },
  dateSection: {
    display: "flex",
    width: "100%"
  },
  icon: {
    color: "#327C93",
    marginRight: "6px",
    padding: "6px",
    width: "1.7em",
    height: "1.7em",
    borderRadius: "6px"
  },
  teamDeatail: {
    fontSize: "16px",
    lineHeight: "19px",
    color: "#222222",
    marginTop: "12px"
  },
  teamSection: {
    display: "flex",
    marginRight: "36px"
  },
  participateButton: {
    background: "#327C93",
    borderRadius: "5px",
    padding: "10px 30px",
    color: "#ffffff",
    margin: "26px auto",
    display: "flex",
    "&:hover": {
      background: "#327C93",
      color: "#ffffff"
    }
  }
});

class ViewaEventNotParticipated extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      teammodalOpen: false,
      teammatemodel: false,
      selectedimage: "",
      teamname: "",
      eventData: ""
    };
    this.state.id = this.props.match.params.id;
  }

  componentDidMount() {
    // this.getEventData(this.state.id);
    this.props.getChallenge(this.state.id);
  }

  componentDidUpdate(prevProps) {
    const currentRouterId = this.props.router.split("/")[3];
    const prevRouterId = prevProps.router.split("/")[3];
    if (currentRouterId !== prevRouterId) {
      // this.getEventData(currentRouterId);
      this.props.getChallenge(currentRouterId);
    }
  }

  getEventData = async id => {
    const url = `/api/v2/events/${id}`;
    const eventResponse = await axios.get(url);
    this.setState({ eventData: eventResponse.data });
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleEditButton = () => {
    const event = this.props && this.props.event;
    this.props.history.push(`/events/edit/${event._id}`);
  };

  openTeamModal = () => {
    this.setState({ teammodalOpen: true });
  };
  handleClose = () => {
    this.setState({ teammodalOpen: false });
  };
  closeTeammatemodel = () => {
    this.setState({ teammatemodel: false });
  };

  setteamname = name => {
    this.setState({ teamname: name });
  };

  setteammatemodel = flag => {
    this.setState({ teammatemodel: flag });
  };

  setselectedimage = image => {
    this.setState({ selectedimage: image });
  };
  render() {
    const { classes, event, eventData } = this.props;
    // const { eventData } = this.state;

    // const Banner = event.banner ? `${ASSET_URL}${event.banner}` : bannerImage;
    // const eventNameChar = event && event.event_name ? event.event_name : "";
    const eventdate = moment(eventData.end_date);
    const todaysdate = moment();
    const date = eventdate.diff(todaysdate, "days");
    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.mainsection}>
          <main>
            <Paper className={classes.root}>
              <div
                style={{
                  backgroundImage: `url(${eventData.banner})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  height: "340px"
                }}
              >
                {/* <AppBar position="static" className={classes.appBar}>
                  <Toolbar /> */}
                <div className={classes.verticalIcon}>
                  {/* <Settings editMethod={this.handleEditButton} view /> */}
                </div>
                {/* </AppBar> */}
              </div>
              <div className={classNames(classes.layout, classes.cardGrid)}>
                <div className={classes.eventbasicdetail}>
                  <Grid
                    container
                    item
                    lg={12}
                    xs={12}
                    style={{ paddingLeft: "20px", paddingRight: "20px" }}
                  >
                    <Grid item lg={2} xs={2}>
                      <div className={classes.logo}>
                        <img
                          className={classes.orangeAvatar}
                          src={
                            event && event.company
                              ? eventData &&
                                eventData.company &&
                                eventData.company.logo
                              : logo
                          }
                          alt="logo"
                        />
                      </div>
                    </Grid>
                    <Grid item lg={7} xs={7}>
                      <div className={classes.eventTitle}>
                        <div className={classes.alignTitle}>
                          <Typography
                            component="h3"
                            className={classes.eventName}
                            gutterBottom
                          >
                            {spacecapitalize(eventData.event_name)}
                          </Typography>
                        </div>
                        <Typography
                          className={classNames(classes.subFont)}
                          variant="subtitle1"
                          component="p"
                        >
                          <span className={classes.dateSection}>
                            <span className={classes.teamSection}>
                              <GroupAddIcon className={classes.icon} />
                              <p className={classes.teamDeatail}>
                                Teams {eventData.team_max} people
                              </p>
                            </span>

                            <span className={classes.teamSection}>
                              <QueryBuilderIcon className={classes.icon} />
                              <p className={classes.teamDeatail}>
                                {date} days left
                              </p>
                            </span>
                          </span>
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <ViewaEventTabs data={eventData && eventData.pages} />
                <AddParticipantTeamModal
                  closeTeamModal={this.handleClose}
                  openTeamModal={this.state.teammodalOpen}
                  teammatemodel={this.state.teammatemodel}
                  setteammatemodel={this.setteammatemodel}
                  data={event}
                  selectedimage={this.state.selectedimage}
                  setselectedimage={this.setselectedimage}
                  setteamname={this.setteamname}
                  teamname={this.state.teamname}
                />
                <AddTeammateModel
                  data={event}
                  teammatemodel={this.state.teammatemodel}
                  // setteammatemodel={setteammatemodel}
                  closeTeammatemodel={this.closeTeammatemodel}
                  teamname={this.state.teamname}
                  selectedimage={this.state.selectedimage}
                />
              </div>
            </Paper>
          </main>
        </div>
      </React.Fragment>
    );
  }
}

ViewaEventNotParticipated.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    event: state.events.event,
    eventData: state.events.challenge,
    router: state.router.location.pathname
  };
};

export default connect(mapStateToProps, { getEvent, getChallenge })(
  withStyles(styles)(ViewaEventNotParticipated)
);
