import React, { useState, useEffect } from "react";
import ListItem from "@material-ui/core/ListItem";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import axios from "axios";
import { spacecapitalize } from "../../Utils/StringFunctions";
import { connect } from "react-redux";
import OverViewG from "../../Assets/svgs/challenges/overViewGWeb.svg";
import MyApplicationG from "../../Assets/svgs/challenges/myApplicationGWeb.svg";
import MyTeamG from "../../Assets/svgs/challenges/myTeamG.svg";
import OverViewC from "../../Assets/svgs/challenges/overViewC.svg";
import MyApplicationC from "../../Assets/svgs/challenges/myApplicationC.svg";
import MyTeamC from "../../Assets/svgs/challenges/myTeamC.svg";
const $quoteSym = " ";

const styles = theme => ({
  item: {
    paddingTop: 8,
    paddingBottom: 8
    // paddingTop: "40%"
  },
  itemPrimary: {
    fontSize: theme.typography.fontSize,
    lineHeight: "19px",
    color: " #222222",
    "&$dense": {
      fontSize: theme.typography.fontSize
    }
  },
  moreIcon: {
    color: "#222222"
  },
  icon: {
    // width: "20px",
    // marginRight: "5px",
    margin: "0px 30px 0px 7px"
  },
  dropdownIcon: {
    marginRight: "5px",
    margin: "0px 30px 0px 7px",
    color: "#222222"
  },
  statusDropdown: {
    color: "black",
    borderRadius: "4px",
    height: "33px",
    padding: "13px 12px 13px",
    fontSize: "14px",
    top: "84px",
    left: "13px",
    position: "absolute",
    zIndex: 1,
    width: "85%"
  },
  menuLayout: {
    marginTop: "48%"
  }
});
const theme = createMuiTheme({
  overrides: {
    MuiListItem: {
      root: {
        color: "#ababab",
        padding: "12px 16px !important",
        "&$selected": {
          // borderRight: "3px solid #4CABD0",
          backgroundColor: "#fff",
          color: "#222222",
          fontWeight: 600,
          "&:after": {
            top: "8px",
            right: "0px",
            bottom: "0px",
            width: "4px",
            height: "30px",
            backgroundColor: "#4CABD0",
            content: `'${$quoteSym}'`,
            position: "absolute",
            borderRadius: "110px"
          }
        }
      }
    },
    MuiListItemIcon: {
      root: {
        color: "#ababab",
        "&$selected": {
          color: "#4CABD0"
        }
      }
    },
    MuiTypography: {
      body1: {
        fontWeight: "600",
        fontSize: "14px",
        lineHeight: "19.07px"
      }
    },
    MuiMenuItem: {
      root: {
        padding: "8px 8px 8px 12px !important",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "19px",
        color: "#000000"
      }
    },
    MuiSelect: {
      selectMenu: {
        fontSize: "14px",
        fontWeight: 600,
        color: "#222222",
        lineHeight: "19.07px"
      },
      select: {
        "&:focus": {
          backgroundColor: "none"
        }
      }
    }
  }
});
const ParticipantDrawer = props => {
  const { classes } = props;
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [eventList, seteventList] = useState([]);
  const [routerData, setrouterData] = useState(props.router);
  const [selected, setSelected] = useState(props.router.split("/")[3] || "");

  useEffect(() => {
    setrouterData(props.router);
    const event_id = props.router.split("/")[3];
    setSelected(event_id);
  }, [props.router]);

  const handleListItemClick = (event, index, route) => {
    // console.log("item click", index);
    setSelectedIndex(index);
    props.history.push(`/participant/${route}/${selected}`);
  };

  const handleClick = el => {
    setOpen(true);
    setSelected(el.target.value);
    props.history.push(`/participant/overview/${el.target.value}`);
  };

  useEffect(() => {
    getEventsData();
  }, []);

  // console.log("selected menu valueeee", selected);
  const getEventsData = async () => {
    const url = `/api/application/mychallenges`;
    const eventResponse = await axios.get(url);
    const eventsData = eventResponse.data.data;
    const eventList = eventsData.map(item => {
      return {
        value: item && item.event && item.event._id,
        label: spacecapitalize(item && item.event && item.event.event_name)
      };
    });
    // remove duplicate event data
    let filteredEventList = eventList.filter(
      (ele, ind) =>
        ind === eventList.findIndex(elem => elem.value === ele.value)
    );
    seteventList(filteredEventList);
  };

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <div>
          <div className={classes.root}>
            <Select
              className={classes.statusDropdown}
              value={selected}
              onChange={handleClick}
              name="name"
              disableUnderline
              variant="standard"
              classes={{
                icon: classes.dropdownIcon
              }}
            >
              {eventList.map(event => {
                return (
                  <MenuItem
                    value={event.value}
                    key={event.value}
                    style={{ padding: 0 }}
                    className={classes.challengeName}
                  >
                    {event.label}
                  </MenuItem>
                );
              })}
            </Select>
            <List
              component="nav"
              aria-label="main mailbox folders"
              className={classes.menuLayout}
            >
              <ListItem
                button
                selected={selectedIndex === 0}
                onClick={event => handleListItemClick(event, 0, "overview")}
              >
                <img
                  src={selectedIndex === 0 ? OverViewC : OverViewG}
                  alt="overview"
                  className={classes.icon}
                />
                <ListItemText primary="Overview" />
              </ListItem>
              <ListItem
                button
                selected={selectedIndex === 1}
                onClick={event =>
                  handleListItemClick(event, 1, "my-application")
                }
              >
                <img
                  src={selectedIndex === 1 ? MyApplicationC : MyApplicationG}
                  alt="My Application"
                  className={classes.icon}
                />

                <ListItemText primary="My Application" />
              </ListItem>
              <ListItem
                button
                selected={selectedIndex === 2}
                onClick={event => handleListItemClick(event, 2, "my-team")}
              >
                <img
                  src={selectedIndex === 2 ? MyTeamC : MyTeamG}
                  alt="My Team"
                  className={classes.icon}
                />

                <ListItemText primary="My Team" />
              </ListItem>
              {/* <ListItem
                button
                selected={selectedIndex === 3}
                onClick={event => handleListItemClick(event, 3, "create-stack")}
              >
                
                <ListItemText primary=" Create Stack" />
              </ListItem> */}
            </List>
          </div>
        </div>
      </ThemeProvider>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    router: state.router.location.pathname
  };
};
export default connect(
  mapStateToProps,
  {}
)(withStyles(styles)(ParticipantDrawer));
