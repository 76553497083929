import React from "react";
import { Paper, Grid, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
// import memberProfile from "../../Assets/User.png";
// import classNames from "classnames";
import StackCard from "../../Components/Common/Cards/StackCard";
const drawerWidth = 240;

const styles = () => ({
  root: {
    paddingTop: "5%",
    paddingBottom: "15px",
    background: "#ffffff",
    width: `calc(100% - ${drawerWidth}px )`,
    position: "relative",
    left: drawerWidth
  },
  mystack_main: {
    padding: "10px 24px"
  },
  layout: {
    marginTop: "19px !important",
    padding: "20px 25px"
  },
  main_title: {
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "27px",
    color: "#222222",
    textAlign: "center",
    marginBottom: "0px"
  },

  member_name: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#222222",
    marginBottom: "0px"
  },
  team_detail: {
    display: "flex",
    justifyContent: "space-between"
  },
  company_name: {
    marginBottom: "0px"
  },
  mailid: {
    marginTop: "2px",
    color: "#ababab",
    fontSize: "12px"
  },
  member_image: {
    width: "80px",
    height: "80px"
  },
  teamcard: {
    textAlign: "center",
    background: "#FFFFFF",
    boxShadow: " 0px 0px 20px rgba(0, 0, 0, 0.05)",
    borderRadius: "10px",
    margin: "16px 0px 12px 0px"
  },
  acepted_status: {
    color: "#009246",
    fontSize: "12px"
  },
  rejected_status: {
    color: "#F44336",
    fontSize: "12px"
  },
  invited_status: {
    color: "#407BFF",
    fontSize: "12px"
  },
  active_member: {
    border: "5px solid #B9E5F3",
    borderRadius: "50px",
    padding: "4px",
    width: "88px",
    height: "88px"
  },
  add_icon: {
    color: "#EFEFEF",
    width: "3em",
    height: "3em",
    background: "#F8F8F8",
    borderRadius: "50px"
  },
  pageTitle: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "19px",
    color: " #327C93"
  }
});

function CreateStack(props) {
  const { classes } = props;

  return (
    <React.Fragment>
      <CssBaseline />
      <main>
        <Paper className={classes.root}>
          <div className={classes.mystack_main}>
            <p className={classes.pageTitle}>My Stack</p>
            <Grid container spacing={2}>
              <Grid item lg={3} xs={6} sm={5} className={classes.addteamcard}>
                <StackCard />
              </Grid>
            </Grid>
          </div>
          <div className={classes.mystack_main}>
            <p className={classes.pageTitle}>All Stack</p>
            <Grid container spacing={2}>
              <Grid item lg={3} xs={6} sm={5} className={classes.addteamcard}>
                <StackCard create="yes" />
              </Grid>
            </Grid>
          </div>
        </Paper>
      </main>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {
    errors: state.errors
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(CreateStack));
