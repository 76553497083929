/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { Grid, Typography, Button, InputAdornment } from "@material-ui/core";
import * as Yup from "yup";
import { Formik } from "formik";
import { Form } from "react-formik-ui";
import { InputField } from "../../Components/Common/Form";
import { registerUser, loginUser } from "../../Actions/authAction";
import IconButton from "@material-ui/core/IconButton";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Checkbox from "@material-ui/core/Checkbox";
// import EmailIcon from "@material-ui/icons/Email";
import googleOAuthCredentials from "../../Utils/googleOauthCredentials";
import gmail from "../../Assets/gmail.png";
import github from "../../Assets/github.png";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { FormikTextField } from "formik-material-fields";
const useStyles = makeStyles({
  list: {
    width: "100%",
    height: "560px",
    backgroundColor: "white",
    padding: "16px",
    overflow: "auto"
  },
  fullList: {
    width: "auto"
  },
  paper: {
    top: "48px"
  },
  modalTitle: {
    fontWeight: 600,
    left:"5%",
    fontSize: "24px",
    lineHeight: "28px",
    textAlign: "center",
    color: "#222222",
    display: "block",
    position: "sticky",
    // top: "-39px"
    width: "90%",
    alignSelf: "center",
    marginRight: "7.5%"
  },
  forgotLink: {
    color: "#327C93",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "21px",
    textDecoration: "none"
  },
  modalForm: {
    maxWidth: "100%"
  },
  submitButton: {
    background: "#4CABD0",
    // fontSize: "17px",
    borderRadius: "5px",
    padding: "10px 30px",
    // fontFamily: "roboto",
    color: "#ffffff",
    width: "100%",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
    "&:hover": {
      background: "#327C93",
      color: "#ffffff"
    }
  },
  githubbutton: {
    padding: " 6px 8px",
    textTransform: "capitalize",
    background: "#FFFFFF",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.08)",
    marginLeft: "10px"
  },
  signinButton: {
    margin: "16px 0px 18px 0px"
  },
  modalFooter: {
    width: "100%",
    backgroundColor:"#fff",
    margin: "16px 0px 10px 0px"
  },
  loginWithText: {
    fontSize: "12px",
    lineHeight: "21px",
    color: "#222222",
    display: "inline-block",
    position: "relative",
    top: "-23px",
    background: "#fff",
    padding: "0px 6px"
  },
  border: {
    textAlign: "center",
    borderTop: "1px solid #D5EDF4",
    height: 0,
    margin: "34px 6px"
  },
  signupLink: {
    fontSize: "14px",
    lineHeight: "16px",
    textAlign: "center",
    backgroundColor: "white",
    color: "#222222",
    marginTop: "10px"
  },
  signup: {
    color: "#327C93",
    textDecoration: "none"
  },
  backIcon: {
    height: "1.5em",
    position: "relative",
    // left: "-8px",
    width: "1.5em"
  },
  iconButtondiv: {
    display: "flex",
    width: "100%"
  },
  iconButton: {
    width: "15%",
    alignItems: "center",
    color: "#000"
  },
  loginButton: {
    color: "#4CABD0",
    marginTop: "10px",
    marginBottom: "10px"
  }
});
const theme = createMuiTheme({
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: "#F9F9F9"
      }
    },
    MuiTypography: {
      body1: {
        color: "#ABABAB",
        fontSize: "12px"
      }
    },
    MuiInput: {
      root: {
        marginTop: "20px",
        // width:"150%",
        paddingLeft: "10px !important",
        fontSize: "14px",
        borderRadius: "5px",
        border: "1px solid #EDE8EF",
        height: "40px"
      }
    }
  }
});

const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
const SignupSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, "Please enter more than 2 character")
    .max(15, "")
    .required("Required"),
  last_name: Yup.string()
    .min(2, "Please enter more than 2 character")
    .max(15, "")
    .required("Required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Required"),
  password: Yup.string()
    .required("Required")
    .min(6, "Password should be greater than 6 characters")
    .max(50, "Password should not be greater than 50 characters"),
  contact_number: Yup.string()
    .matches(phoneRegExp, "Please enter valid number.")
    .required("Required.")
});

function SignupEventDrawer(props) {
  const classes = useStyles();
  const [ShowPass, setShowPass] = useState(true);
  const closeSignUp = () => {
    props.toggleDrawerSignup();
    props.setopenModal(true);
  };
  const handleSubmitsignup = (values, setErrors, setSubmitting) => {
    props.registerUser(values, setErrors, setSubmitting).then(async result => {
      if (result && result.data.success) {
        closeSignUp();
      }
    });
    // closeSignUp();
  };
  const sideList = () => (
    <div className={classes.list} padding="yes" role="presentation">
      <Formik
        initialValues={{
          first_name: "",
          last_name: "",
          email: "",
          contact_number: "",
          password: ""
        }}
        validationSchema={SignupSchema}
        onSubmit={(values, { setErrors, setSubmitting }) => {
          handleSubmitsignup(values, setErrors, setSubmitting);
        }}
      >
        {({ values, setFieldValue, setFieldTouched, errors, touched }) => (
          <Form
            className={classes.form}
            // onSubmit={onSubmit}
            encType="multipart/form-data"
            autoComplete="off"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} s m={12} className={classes.modalForm}>
                <div className={classes.iconButtondiv}>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    onClick={() => props.toggleDrawerSignup()}
                    className={classes.iconButton}
                  >
                    <ChevronLeftIcon className={classes.backIcon} />
                  </IconButton>
                  <Typography
                    variant="subtitle1"
                    className={classes.modalTitle}
                  >
                    Signup
                  </Typography>
                </div>
                <Grid item xs={12} sm={12}>
                  <InputField
                    name="first_name"
                    placeholder="Enter your First Name"
                  />
                  <InputField
                    name="last_name"
                    placeholder="Enter your Last Name"
                  />
                  <InputField name="email" placeholder="Enter your Email ID " />

                  <InputField
                    name="contact_number"
                    placeholder="Enter your Contact Number"
                  />
                  <FormikTextField
                    name="password"
                    placeholder="Enter your Password"
                    fullWidth
                    type={ShowPass ? "password" : "text"}
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                              setShowPass(!ShowPass);
                            }}
                            // onMouseDown={handleMouseDownPassword}
                          >
                            {ShowPass ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>

                <Grid
                  container
                  item
                  display="flex"
                  justify="center"
                  alignItems="center"
                  direction="row"
                  className={classes.modalFooter}
                >
                  <Button
                    className={classes.submitButton}
                    type="submit"
                    variant="contained"
                  >
                    Signup
                  </Button>
                </Grid>
                <div className={classes.border}>
                  <p className={classes.loginWithText}>or Login with</p>
                </div>
                <Grid
                  container
                  item
                  display="flex"
                  justify="center"
                  alignItems="center"
                  direction="row"
                  className={classes.modalFooter}
                >
                  <Button
                    className={classes.githubbutton}
                    variant="contained"
                    href={`https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email&include_granted_scopes=true&response_type=token&redirect_uri=http://localhost:3000&client_id=${
                      googleOAuthCredentials[window.origin]
                    }`}
                  >
                    {/* <EmailIcon /> */}
                    <img
                      alt="gmail"
                      src={gmail}
                      className={classes.loginButton}
                    />
                    {/* Google */}
                  </Button>
                  <Button
                    className={classes.githubbutton}
                    variant="contained"
                    href="https://github.com/login/oauth/authorize?client_id=Iv1.0b9a42e52d03c7d0&scope=user&redirect_url=http://localhost:3000"
                  >
                    {/* <EmailIcon /> */}
                    <img
                      alt="github"
                      src={github}
                      className={classes.loginButton}
                    />
                    {/* Github */}
                  </Button>
                </Grid>
                <p className={classes.signupLink}>
                  Already have an account?
                  <a
                    href="#"
                    onClick={() => closeSignUp()}
                    className={classes.signup}
                  >
                    &nbsp;Login
                  </a>
                </p>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
  return (
    <ThemeProvider theme={theme}>
      <SwipeableDrawer
        anchor="bottom"
        disableSwipeToOpen={true}
        open={props.openSignupModal}
        className={classes.mainDrawer}
        onClose={() => {
          props.toggleDrawerSignup(false);
        }}
        onOpen={() => {
          props.toggleDrawerSignup(true);
        }}
        PaperProps={{
          style: { borderTopLeftRadius: "20px", borderTopRightRadius: "20px" }
        }}
      >
        {sideList()}
      </SwipeableDrawer>
    </ThemeProvider>
  );
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    errors: state.errors,
    ru: state.errors
  };
};

export default connect(mapStateToProps, { registerUser, loginUser })(
  SignupEventDrawer
);
