import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  withStyles,
  Grid,
  Button,
  Paper,
  ThemeProvider
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "../../../Components/Common/Button";
import Select from "react-select";
import CommonStyles from "../../../Theme/commonStyles";

const styles = () => ({
  root: {
    width: "100%",
    position: "relative",
    boxShadow: "none",
    height: "calc(100vh - 200px)",
    paddingBottom: "30px !important"
  },
  header: {
    fontSize: 14,
    color: "#626262",
    fontFamily: "Roboto"
  },
  errorShow: {
    color: "red"
  },
  listBox: {
    borderRadius: 10,
    border: "1px solid #EFEFEF",
    padding: 5,
    marginTop: 10
  },
  tag: CommonStyles.tag,
  closeTag: CommonStyles.closeTag,
  closeTagView: CommonStyles.closeTagView,
  formFooter: {
    marginTop: "30px",
    position: "absolute",
    bottom: 0
  },
  cancelButton: CommonStyles.cancelButton,
  submitButton: CommonStyles.submitButton
});

function TechnicalSkill(props) {
  const { classes } = props;
  // const [skill, setSkill] = useState("");
  // const [duplicateSkill, setduplicateSkill] = useState(false);
  const [allSkills, setAllSkills] = useState([]);
  const [userData, setUserData] = useState({});
  const [selectedSkill, setSelectedSkill] = useState([]);

  useEffect(() => {
    callUserApi();
  }, []);

  const callUserApi = async () => {
    const userId = localStorage.getItem("user_id");
    const query = { user_id: userId };
    await axios
      .get(`/api/v2/userprofile`, {
        params: {
          filter: JSON.stringify(query)
        }
      })
      .then(response => {
        setUserData(response.data);
        const techSkill =
          response.data && response.data[0] && response.data[0].technical_skill;
        if (techSkill && techSkill.length >= 0) {
          setSelectedSkill(techSkill);
        }
      })
      .catch(error => {
        console.log("error--->>>", error);
      });
    const skillList = await axios.get(`/api/v2/userprofileskill`);

    setAllSkills(skillList && skillList.data);
  };

  const handleSubmit = async () => {
    let technical_skill = [];
    for (let i = 0; i < selectedSkill.length; i++) {
      technical_skill.push(selectedSkill[i]);
    }

    const user = userData[0];
    const _id = user && user._id;

    const apiCall = await axios.put(`/api/v2/userprofile/${_id}`, {
      technical_skill,
      isSubmit: true
    });
    console.log("api call", apiCall);
    if (apiCall && apiCall.status === 200) {
      props.viewProfileHandler(0, true);
    }
  };

  const handleClose = () => {
    setAllSkills([]);
    props.viewProfileHandler(0);
  };
  const deleteTab = u => {
    let newArray = [...selectedSkill];
    const data = newArray.filter(x => x.value !== u.value);
    setSelectedSkill(data);
  };
  const handleChange = newValue => {
    const newArray = [];
    if (selectedSkill.length > 0) {
      for (let i = 0; i < selectedSkill.length; i++) {
        newArray.push(selectedSkill[i]);
      }
    }
    if (newValue && newValue.length > 0) {
      for (let i = 0; i < newValue.length; i++) {
        if (!newArray.find(x => x.value === newValue[i].value)) {
          newArray.push(newValue[i]);
        }
      }
    }
    setSelectedSkill(newArray);
  };
  const options = data => {
    return data.map(u => {
      const { name, _id } = u;
      return {
        value: _id,
        label: name
      };
    });
  };
  return (
    <ThemeProvider>
      <Paper className={classes.root}>
        <div>
          <h6 className={classes.header}>Technical Skills</h6>

          <Select
            isMulti
            placeholder="Enter Skills"
            onChange={handleChange}
            options={options(allSkills)}
          />
          {selectedSkill && selectedSkill.length > 0 && (
            <div className={classes.listBox}>
              {selectedSkill &&
                selectedSkill.map((u, i) => {
                  return (
                    <p className={classes.tag} key={i}>
                      {u && u.label}
                      <Button
                        color="primary"
                        className={classes.closeTagView}
                        onClick={() => deleteTab(u)}
                      >
                        <CloseIcon className={classes.closeTag} />
                      </Button>
                    </p>
                  );
                })}
            </div>
          )}

          <Grid
            item
            container
            xs={12}
            sm={12}
            direction="row"
            justify="flex-end"
            alignItems="center"
            className={classes.formFooter}
          >
            <Button onClick={handleClose} className={classes.cancelButton}>
              Cancel
            </Button>
            <CustomButton
              type="submit"
              label="Submit"
              onClick={() => {
                handleSubmit();
              }}
              disabled={selectedSkill && selectedSkill.length === 0}
              className={classes.submitButton}
            ></CustomButton>
          </Grid>
        </div>
      </Paper>
    </ThemeProvider>
  );
}

export default withStyles(styles)(TechnicalSkill);
