import React, { useEffect } from "react";
import { connect } from "react-redux";
import { logoutUser, getuserdata } from "../../Actions/authAction";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import UserLogo from "../../Assets/applicant.png";
import OverViewG from "../../Assets/svgs/challenges/overViewG.svg";
import MyApplicationG from "../../Assets/svgs/challenges/myApplicationG.svg";
import mychallenges from "../../Assets/svgs/challenges/mychallenges.svg";
import Profile from "../../Assets/svgs/challenges/profile.svg";
import LogoutG from "../../Assets/svgs/challenges/logoutG.svg";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  list: {
    width: "100%",
    backgroundColor: "#F9F9F9",
    alignItems: "center",
    justifyContent: "center"
  },

  paper: {
    top: "48px"
  },
  mainDrawer: {
    width: "400px"
  },
  UpperContainer: {
    width: "250px",
    padding: "16px",
    textAlign: "center"
  },
  username: {
    fontSize: 19,
    alignSelf: "center",
    marginTop: "3px",
    marginBottom: "1px",
    textTransform: "capitalize"
  },
  useremail: {
    fontSize: "14px",
    color: "#ABABAB",
    marginTop: "2px"
  },
  listItem: {
    paddingLeft: "15px"
  },
  icon: {
    margin: "0px 30px 0px 0px"
  },
  menuName: { fontWeight: 500, color: "#000", fontSize: "14px" },
  signoutContainer: { bottom: "0px", position: "absolute", width: "100%" }
});
const theme = createMuiTheme({
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: "#F9F9F9"
      }
    },
    MuiTypography: {
      body1: {
        color: "#ABABAB",
        fontSize: "12px"
      }
    }
  }
});

const UpperBlock = props => {
  const classes = useStyles();
  const { userData } = props;
  return (
    <div className={classes.UpperContainer}>
      <img src={UserLogo} alt="User Profile" height="90px" width="90px" />
      <p className={classes.username}>
        {userData && userData.first_name} {userData && userData.last_name}
        {/* {localStorage.getItem("first_name")} {localStorage.getItem("last_name")} */}
      </p>
      <p className={classes.useremail}>
        {/* {localStorage.getItem("email")} */}
        {userData && userData.email}
      </p>
    </div>
  );
};

const Menu = props => {
  const classes = useStyles();
  const { close, logout } = props;

  return (
    <ThemeProvider theme={theme}>
      <div>
        <List>
          <ListItem
            button
            className={classes.listItem}
            onClick={() => {
              props.history.push("/challenges");
              close();
            }}
          >
            <img src={OverViewG} alt="overview" className={classes.icon} />

            <ListItemText
              secondary={
                <Typography className={classes.menuName}>
                  All Challenges
                </Typography>
              }
            />
          </ListItem>
          <ListItem
            button
            className={classes.listItem}
            onClick={() => {
              props.history.push("/participant/my-application");
              close();
            }}
          >
            <img
              src={MyApplicationG}
              alt="My Application"
              className={classes.icon}
            />
            <ListItemText
              secondary={
                <Typography className={classes.menuName}>
                  My Application
                </Typography>
              }
            />
          </ListItem>

          <ListItem
            button
            className={classes.listItem}
            onClick={() => {
              props.history.push("/participant/my-challenges");
              close();
            }}
          >
            <img src={mychallenges} alt="My Team" className={classes.icon} />
            <ListItemText
              secondary={
                <Typography className={classes.menuName}>
                  My Challenges
                </Typography>
              }
            />
          </ListItem>

          <ListItem
            button
            className={classes.listItem}
            onClick={() => {
              props.history.push("/participant/profile");
              close();
            }}
          >
            <img src={Profile} alt="My Team" className={classes.icon} />
            <ListItemText
              secondary={
                <Typography className={classes.menuName}>My Profile</Typography>
              }
            />
          </ListItem>
        </List>
        <List className={classes.signoutContainer}>
          <ListItem
            button
            className={classes.listItem}
            onClick={() => {
              logout();
              close();
              props.history.push("/challenges");
            }}
          >
            <img src={LogoutG} alt="Logout" className={classes.icon} />

            <ListItemText
              secondary={
                <Typography className={classes.menuName}>Logout</Typography>
              }
            />
          </ListItem>
        </List>
      </div>
    </ThemeProvider>
  );
};

function SideDrawer(props) {
  const { open, close, history, userData } = props;
  const classes = useStyles();
  useEffect(() => {
    props.getuserdata();
  }, []);
  const logout = () => {
    props.logoutUser();
  };

  const sideList = () => (
    <div className={classes.list} padding="yes" role="presentation">
      <UpperBlock userData={userData} />
      <Menu history={history} close={close} logout={logout} />
    </div>
  );
  return (
    <ThemeProvider theme={theme}>
      <SwipeableDrawer
        disableSwipeToOpen={true}
        anchor="left"
        open={open}
        onClose={() => close()}
      >
        {sideList()}
      </SwipeableDrawer>
    </ThemeProvider>
  );
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    userData: state.auth.user_data
  };
};

export default connect(mapStateToProps, { logoutUser, getuserdata })(
  withStyles(makeStyles)(SideDrawer)
);
