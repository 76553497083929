import axios from "axios";
import {
  GET_APPLICATIONS,
  GET_APPLICATION,
  GET_APPLICATION_ERROR,
  APPLICATIONS_LOADING,
  ADD_APPLICATION,
  CHANGE_APPLICATION_STATUS,
  APPLICATION_STATUS_ERROR,
  APPLICATION_STATUS_LOADING,
  GET_TEAM_MATES,
  GET_TEAM_MATES_ERROR,
  GET_PARTICIPANT_APPLICATION,
  GET_PARTICIPANT_APPLICATION_ERROR,
  APPLICATION_FORMDATA,
  APPLICATION_FORMDATA_ERROR,
  APPLICATIONFORM_STATUS_LOADING
} from "./types";
import { showSuccess, showError } from "./ActionsHelper";

export const getApplications = (search, id, eventid, status) => dispatch => {
  dispatch(setApplicationsLoading());
  let url = "";

  if (search) {
    url = `/api/application?q=${search}`;
  } else if (id) {
    url = `/api/application?companyid=${id}`;
  } else if (eventid) {
    url = `/api/application?eventid=${eventid}`;
  } else {
    url = `/api/application`;
  }
  if (status && status !== "all") {
    url = `${url}&status=${status}`;
  }

  axios
    .get(url)
    .then(res => {
      dispatch({
        type: GET_APPLICATIONS,
        payload: res.data,
        status: status || "all"
      });
    })
    .catch(err => {
      dispatch({
        type: GET_APPLICATION_ERROR,
        payload: err.response && err.response.data
      });
    });
};
export const getApplication = id => dispatch => {
  dispatch(setApplicationsLoading());
  axios
    .get(`/api/application/v2/${id}`)
    .then(res => {
      dispatch({
        type: GET_APPLICATION,
        payload: res
      });
    })
    .catch(err =>
      dispatch({
        type: GET_APPLICATION_ERROR,
        payload: err.response && err.response.data
      })
    );
};

export const registerApplication = userData => dispatch => {
  axios
    .post("/api/application/apply", userData)
    .then(result => {
      dispatch({
        type: ADD_APPLICATION,
        payload: result.data
      });
    })
    .catch(err => {
      dispatch({ type: GET_APPLICATION_ERROR, payload: err.response.data });
    });
};
//APPLICATION STATUS UPDATE
export const updateApplicationStatus = (id, userData) => dispatch => {
  dispatch(setStatusLoading());
  axios
    .put(`/api/application/applicationchangestatus/${id}`, userData)
    .then(result => {
      dispatch({
        type: CHANGE_APPLICATION_STATUS,
        payload: result.data
      });
      dispatch(showSuccess("", "Status Updated Successfully"));
    })
    .catch(err => {
      dispatch({
        type: APPLICATION_STATUS_ERROR,
        payload: ((err || {}).response || {}).data || "Error unexpected"
      });
      dispatch(showError("", "Error updating status"));
    });
};
export const updateApplicationFormData = (
  id,
  userData,
  isUpdate
) => dispatch => {
  dispatch(setApplicationsformLoading());
  const text = isUpdate
    ? "Application updated successfully!"
    : "Application submitted successfully!";
  axios
    .put(`/api/application/v2/${id}`, userData)
    .then(result => {
      dispatch({
        type: APPLICATION_FORMDATA,
        payload: result.data
      });
      dispatch(showSuccess("", text));
    })
    .catch(err => {
      dispatch({
        type: APPLICATION_FORMDATA_ERROR,
        payload: ((err || {}).response || {}).data || "Error unexpected"
      });
      dispatch(showError("", "Error updating status"));
    });
};
export const updateApplication = (id, data) => dispatch => {
  dispatch(setApplicationsLoading());
  axios
    .put(`/api/application/${id}`, data)
    .then(res => {
      dispatch({
        type: ADD_APPLICATION,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_APPLICATION_ERROR,
        payload: err.response && err.response.data
      })
    );
};
//GET TEAM MATES OF ONE APPLICATION
export const getTeamMates = id => dispatch => {
  dispatch(setApplicationsLoading());
  axios
    .get(`/api/application/applicant/allapplicant?applicationid=${id}`)
    .then(res => {
      dispatch({
        type: GET_TEAM_MATES,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_TEAM_MATES_ERROR,
        payload: err.response && err.response.data
      })
    );
};

//GET PARTICIPANT APPLICATION
export const getParticipantApplication = id => dispatch => {
  dispatch(setApplicationsLoading());
  axios
    .get(`/api/application/mychallenges`)
    .then(res => {
      dispatch({
        type: GET_PARTICIPANT_APPLICATION,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_PARTICIPANT_APPLICATION_ERROR,
        payload: err.response && err.response.data
      })
    );
};

export const setApplicationsformLoading = () => {
  return {
    type: APPLICATIONFORM_STATUS_LOADING
  };
};

// Profile Loading
export const setApplicationsLoading = () => {
  return {
    type: APPLICATIONS_LOADING
  };
};
export const setStatusLoading = () => {
  return {
    type: APPLICATION_STATUS_LOADING
  };
};
