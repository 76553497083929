import { SET_CURRENT_USER, USER_DATA } from "./../Actions/types";
import isEmpty from "./../Utils/isEmpty";

const initialState = {
  isAuthenticated: false,
  user: {},
  hasRole: "",
  user_data: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
        hasRole: action.payload.role
      };

    case USER_DATA:
      return {
        ...state,
        user_data: action.payload
      };
    default:
      return state;
  }
}
