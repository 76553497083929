import {
  GET_ASSOCIATES,
  GET_ASSOCIATE,
  ASSOCIATES_LOADING,
  ADD_ASSOCIATE,
  ADD_ASSOCIATE_LOADING,
  ADD_ASSOCIATE_ERROR,
  GET_ASSOCIATES_SKILLS,
  GET_ASSOCIATES_SKILLS_LOADING,
  GET_ASSOCIATES_SKILLS_ERROR,
  GET_ALL_ASSOCIATE,
  ASSOCIATE_ERRORS,
  REMOVE_ASSOCIATE_COMPANY,
  REMOVE_ASSOCIATE_COMPANY_ERROR,
  REMOVE_ASSOCIATE_EVENT,
  REMOVE_ASSOCIATE_EVENT_ERROR
} from "../Actions/types";
const initialState = {
  associates: [],
  loading: false,
  addAssociate: { associateLoading: false },
  skillsList: [],
  all_associates: [],
  associate: [],
  removeAssociate: { loading: false },
  associateSkillsloading: false,

};
export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ASSOCIATES:
      return {
        ...state,
        loading: false,
        associates: action.payload && action.payload.data
      };
    case GET_ASSOCIATE:
      return {
        ...state,
        loading: false,
        associate: action.payload
      };
    case GET_ALL_ASSOCIATE:
      return {
        ...state,
        loading: false,
        all_associates: action.payload && action.payload
      };
    case ASSOCIATES_LOADING:
      return {
        ...state,
        loading: true
      };
    case ADD_ASSOCIATE:
      return {
        ...state,
        addAssociate: {
          success: true,
          loading: false,
          data: action.payload && action.payload.data
        }
      };
    case ADD_ASSOCIATE_LOADING:
      return {
        ...state,
        addAssociate: {
          loading: true
        }
      };
    case ADD_ASSOCIATE_ERROR:
      return {
        ...state,
        addAssociate: {
          success: false,
          loading: false
        }
      };
    case REMOVE_ASSOCIATE_COMPANY:
      return {
        ...state,
        removeAssociate: {
          success: true,
          loading: false,
          data: action.payload && action.payload.data
        }
      };
      case REMOVE_ASSOCIATE_EVENT:
        return {
          ...state,
          removeAssociate: {
            success: true,
            loading: false,
            data: action.payload && action.payload.data
          }
        };
    case REMOVE_ASSOCIATE_COMPANY_ERROR:
      return {
        ...state,
        addAssociate: {
          loading: false
        }
      };
      case REMOVE_ASSOCIATE_EVENT_ERROR:
      return {
        ...state,
        addAssociate: {
          loading: false
        }
      };
    case GET_ASSOCIATES_SKILLS:
      return {
        ...state,
        loading: false,
        skillsList: action.payload.data
      };
    case GET_ASSOCIATES_SKILLS_ERROR:
      return {
        ...state,
        loading: false,
        associateError: { errors: false }
      };
    case GET_ASSOCIATES_SKILLS_LOADING:
      return {
        ...state,
        associateSkillsloading: true
      };
    case ASSOCIATE_ERRORS:
      return {
        ...state,
        success: false,
        loading: false
      };
    default:
      return state;
  }
}
