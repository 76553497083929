import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { TableCell } from "@material-ui/core";

const StyledTableCell = withStyles(() => ({
  body: {
    textTransform: "capitalize",
    borderBottom: "none",
    padding: "8px 10px 8px 8px",
    fontWeight: "normal",
    fontSize: "14px",
    textAlign: "left"
  }
}))(TableCell);
const getStatusColor = status => {
  //   console.log("status****", status);
  switch (status) {
    case "registered":
      return "#05c46b";
    case "onboarded":
      return "#05c46b";
    case "shortlisted":
      return "#8854d0";
    case "applied":
      return "#F6F6F9";
    case "rejected":
      return "#f53b57";
    case "backout":
      return "#485460";
    case "attended":
      return "#ffa801";
    case "participated":
      return "#4d7cfe";
    case "won":
      return "#05c46b";
    case "disqualified":
      return "#f53b57";
    case "on_hold":
      return "#ff6f00";
    case "rewoked":
      return "#485460";
    default:
      return "#626262";
  }
};
const StatusCell = props => {
  return (
    <StyledTableCell
      style={{
        // width: props.width || "10%",
        textAlign: "left",
        padding: "10px 16px",
        textTransform: props.lower && "lowercase",
        cursor: props.cursor && "pointer",
        color: getStatusColor(props.status)
      }}
      onClick={props.onClick}
    >
      {props.children}
    </StyledTableCell>
  );
};

export default StatusCell;
