import React, { useState, useEffect } from "react";
import { Paper, withStyles, Grid, Button } from "@material-ui/core";
import axios from "axios";
import { InputField } from "../../../Components/Common/Form";
import { ThemeProvider } from "@material-ui/styles";
import memberProfile from "../../../Assets/User.png";
import * as Yup from "yup";
import { UploadFieldNew } from "../../../Components/Common/Form";
import TextareaField from "../../../Components/Common/Form/TextareaField";
import { randomId } from "../../../Utils/StringFunctions";
import { Formik } from "formik";
import { Form } from "react-formik-ui";
import CustomButton from "../../../Components/Common/Button";
import CommonStyles from "../../../Theme/commonStyles";

const styles = theme => ({
  root: {
    width: "100%",
    boxShadow: "none",
    backgroundColor: "white",
    padding: "5%"
  },
  imgGrid: {
    justifyContent: "center"
    // padding: "0px 0px 30px 0px"
  },
  comment_section: {
    border: "1px solid #efefef",
    textAlign: "right",
    position: "relative",
    height: "80px",
    margin: "20px 0px 0px 0px",
    borderRadius: "10px"
  },

  input_field: CommonStyles.input_field,
  formFooter: CommonStyles.formFooter,
  cancelButton: CommonStyles.cancelButton
});

const phoneRegExp = /[1-9]{1}[0-9]{9}/;
class MbPersonalDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      contact_details: "",
      location: "",
      bio: " ",
      linkedin: " ",
      facebook: " ",
      github: " ",
      bannerUrl: `profile/${randomId(10)}/picture`,
      userData: {},
      user: {}
    };
  }
  async componentDidMount() {
    const userId = localStorage.getItem("user_id");
    const query = { user_id: userId };
    const user_profile = await axios.get(`/api/v2/userprofile`, {
      params: {
        filter: JSON.stringify(query)
      }
    });

    const user_me = await axios.get(`/api/user/me`);
    this.setState({
      user: user_me && user_me.data,
      userData: user_profile && user_profile.data
    });
  }
  submitPersonalInfo = async values => {
    const { userData } = this.state;
    let _id;

    if (userData && userData.length > 0) {
      _id = userData && userData[0]._id;
    }
    const userId = localStorage.getItem("user_id");
    values.isSubmit = true;
    var apiCall;
    if (userData.length <= 0) {
      values.user_id = userId;
      apiCall = await axios.post("/api/v2/userprofile", values);
    } else {
      apiCall = await axios.put(`/api/v2/userprofile/${_id}`, values);
    }
    if (apiCall && apiCall.status === 200) {
      this.props.viewProfileHandler(0, true);
    }
  };
  handleClose = () => {
    const { userData } = this.state;
    userData &&
      userData[0] &&
      userData[0].isSubmit &&
      this.props.viewProfileHandler(0);
  };
  PersonalSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Required"),

    contact_number: Yup.string()
      .matches(phoneRegExp, "Please enter valid number.")
      .max(10, "")
      .required("This field is required.")
  });
  onResetBannerUrl = () => {
    this.setState({
      bannerUrl: `profile/${randomId(10)}/picture`
    });
  };
  getInitialValues = () => {
    let user;
    if (this.state.userData.length <= 0)
      user = this.state.user && this.state.user.data;
    else user = this.state.userData[0];

    return {
      photo: (user && user.photo) || "",
      first_name: (user && user.first_name) || "",
      last_name: (user && user.last_name) || "",
      email: (user && user.email) || "",
      contact_number: (user && user.contact_number) || "",
      location: (user && user.location) || "",
      bio: (user && user.bio) || "",
      linkedin: (user && user.linkedin) || "",
      facebook: (user && user.facebook) || "",
      github: (user && user.github) || ""
    };
  };
  resetBannerUrl = () => {
    this.setState({ bannerUrl: `profile/${randomId(10)}/picture` });
  };

  render() {
    const { classes } = this.props;
    const { bannerUrl } = this.state;
    const profile_pic = memberProfile;

    return (
      <ThemeProvider>
        <Paper className={classes.root}>
          <Formik
            initialValues={this.getInitialValues()}
            validationSchema={this.PersonalSchema}
            enableReinitialize
            onSubmit={values => this.submitPersonalInfo(values)}
          >
            {({
              values,
              errors,
              touched,
              setFieldTouched,
              setFieldValue,
              handleSubmit
            }) => (
              <div style={{ paddingTop: "none" }}>
                <Form
                  onSubmit={handleSubmit}
                  className={classes.form}
                  encType="multipart/form-data"
                  autoComplete="off"
                >
                  <Grid container spacing={1} className={classes.imgGrid}>
                    <UploadFieldNew
                      label="Upload Photo"
                      name="photo"
                      id="photo"
                      onChange={setFieldValue}
                      value={values.photo}
                      folder={bannerUrl}
                      onResetBannerUrl={this.resetBannerUrl}
                      isCircle
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <InputField
                      name="first_name"
                      placeholder="First Name"
                      disabled
                      noMargin
                    />
                    <InputField
                      name="last_name"
                      placeholder="Last Name"
                      disabled
                      noMarginBottom
                    />
                    <InputField
                      name="contact_number"
                      placeholder="Contact Number"
                      noMarginBottom
                    />
                    <InputField
                      name="email"
                      placeholder="Email ID"
                      disabled
                      noMarginBottom
                    />
                    <InputField
                      name="location"
                      placeholder="Location"
                      noMarginBottom
                    />
                    <div className={classes.comment_section}>
                      <TextareaField
                        name="bio"
                        className={classes.input_field}
                        placeholder="Bio"
                      />
                    </div>
                    <InputField
                      name="linkedin"
                      placeholder="LinkedIn"
                      noMarginBottom
                    />
                    <InputField
                      name="facebook"
                      placeholder="Facebook"
                      noMarginBottom
                    />
                    <InputField
                      name="github"
                      placeholder="Github"
                      noMarginBottom
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    className={classes.formFooter}
                  >
                    <Button
                      className={classes.cancelButton}
                      type="cancel"
                      variant="contained"
                      onClick={this.handleClose}
                    >
                      CANCEL
                    </Button>
                    <CustomButton
                      size="yes"
                      type="submit"
                      label="Save"
                    ></CustomButton>
                    {/* <Button
                    className={classes.submitButton}
                    type='submit'
                    variant='contained'
                  >
                    SAVE
                  </Button> */}
                  </Grid>
                </Form>
              </div>
            )}
          </Formik>
        </Paper>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(MbPersonalDetail);
