/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import * as Yup from "yup";
import { Formik } from "formik";
import { Form } from "react-formik-ui";
import axios from "axios";
// import { InputField } from "../../Components/Common/Form";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import IconButton from "@material-ui/core/IconButton";
// import TeamFormStep2Drawer from "./TeamFormStep2Drawer";
// import TeamUploadField from "../../Components/Common/Form/TeamUploadField";
// import CloseIcon from "@material-ui/icons/Close";
import LinearProgress from "@material-ui/core/LinearProgress";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Dropzone from "react-dropzone-uploader";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import UploadIcon from "../../Assets/teamUploadIcon.PNG";

const useStyles = makeStyles({
  list: {
    width: "100%",
    backgroundColor: "#fff",
    padding: "16px"
    // borderTopLeftRadius: "50px"
  },
  fullList: {
    width: "auto"
  },
  paper: {
    top: "48px"
  },
  modalTitle: {
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "28px",
    textAlign: "center",
    color: "#222222",
    display: "block",
    position: "relative",
    // top: "-39px",
    width: "85%",
    alignSelf: "center",
    marginRight: "7.5%"
  },
  modalForm: {
    maxWidth: "100%"
    // borderRadius: "15px"
  },
  submitButton: {
    background: "#327C93",
    borderRadius: "5px",
    padding: "10px 30px",
    color: "#ffffff",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)",
    "&:hover": {
      background: "#327C93",
      color: "#ffffff"
    }
  },
  cancelButton: {
    background: "#efefef",
    borderRadius: "5px",
    padding: "10px 30px",
    color: "#327C93",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)",
    marginRight: "26px",
    "&:hover": {
      background: "#efefef",
      color: "#327C93"
    }
  },
  modalFooter: {
    margin: "30px 0px 14px 0px"
  },
  signup: {
    color: "#327C93",
    textDecoration: "none"
  },
  backIcon: {
    height: "1.5em",
    position: "relative",
    // left: "-8px",
    width: "1.5em"
  },
  imageContainer: {
    display: "flex",
    width: "100%",
    maxWidth: "100%",
    justifyContent: "center"
  },
  mainDrawer: {
    borderTopLeftRadius: "50px"
  },
  preview: {
    alignSelf: "flex-start",
    fontFamily: "Helvetica",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    padding: "14px 10px",
    alignItems: "center"
    // marginBottom:"10px",
  },
  previewImage: {
    height: "100px",
    width: "150px",
    // marginBottom:"10px",
    borderRadius: "15px"
  },
  uploadBannerText: {
    textAlign: "center",
    fontSize: "18px",
    color: "#ababab",
    marginTop: "0px"
  },
  inputBanner: {
    display: "none"
  },
  iconButton: {
    width: "15%",
    alignItems: "center",
    color: "#000"
  },
  icon: {
    display: "flex",
    width: "100%"
  },
  textfield: {
    width: "100%"
  },
  inputContainer: {
    textAlign: "center",
    borderWidth: "1px",
    padding: "30px",
    borderStyle: "solid",
    borderColor: "#efefef",
    borderRadius: "10px",
    width: "100%",
    marginBottom: "20px",
    marginTop: "30px"
  }
});
const theme = createMuiTheme({
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: "#F9F9F9"
      }
    },
    MuiTypography: {
      body1: {
        color: "#ABABAB",
        fontSize: "12px"
      }
    }
  }
});

const loginParticipantSchema = Yup.object().shape({
  teamname: Yup.string().required("Team name Required")
});

function TeamFormStep1Drawer(props) {
  const {
    selectedimage,
    setselectedimage,
    teamname,
    setteamname,
    toggleTeamStep2Drawer,
    toggleTeamStep1Drawer
  } = props;

  const classes = useStyles();
  // const [selectedimage, setselectedimage] = useState();
  // const [teamname, setteamname] = useState("");
  // const [openTeamSecondModal, setopenTeamSecondModal] = useState(false);

  // const toggleTeamStep2Drawer = event => {
  //   if (
  //     event &&
  //     event.type === "keydown" &&
  //     (event.key === "Tab" || event.key === "Shift")
  //   ) {
  //     return;
  //   }
  //   setopenTeamSecondModal(!openTeamSecondModal);
  // };

  const getFilesFromEvent = e => {
    return new Promise(resolve => {
      getDroppedOrSelectedFiles(e).then(chosenFiles => {
        resolve(chosenFiles.map(f => f.fileObject));
      });
    });
  };

  const getUploadParams = async ({ file, meta: { name } }) => {
    const url = { fileKey: `team/banner/${name}` };

    const response = await axios.post("/api/s3/presigned_url", url);

    const res = { method: "PUT", url: response.data.url, body: file };
    setselectedimage(res.url.replace(/(.+?\?).*/, "$1").slice(0, -1));
    return res;
  };

  const settingTeamname = name => {
    setteamname(name.target.value);
  };

  const Preview = ({ meta }) => {
    const { percent, previewUrl } = meta;
    return (
      <span className={classes.preview}>
        <img src={previewUrl} className={classes.previewImage} alt="" />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="50%"
        >
          <Box width="100%" mr={1}>
            <LinearProgress variant="buffer" value={percent} />
          </Box>
          <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary">{`${Math.round(
              percent
            )}%`}</Typography>
          </Box>
        </Box>
      </span>
    );
  };

  const InputComponent = ({ accept, onFiles, files, getFilesFromEvent }) => {
    return (
      <>
        {files.length <= 0 && (
          <label className={classes.inputContainer}>
            <img src={UploadIcon} height="100px" width="150px" alt="img" />
            <p className={classes.uploadBannerText}>Upload Team Banner</p>
            <input
              className={classes.inputBanner}
              type="file"
              accept={accept}
              multiple
              onChange={e => {
                getFilesFromEvent(e).then(chosenFiles => {
                  onFiles(chosenFiles);
                });
              }}
            />
          </label>
        )}
      </>
    );
  };

  const Layout = ({ previews, submitButton, dropzoneProps, files, input }) => {
    return (
      <div {...dropzoneProps}>
        {/* {files.length === 0 && <span style={{ cursor: "unset" }}></span>} */}

        {previews}
        {input}
      </div>
    );
  };

  const sideList = () => (
    <div className={classes.list} padding="yes" role="presentation">
      <Formik
        initialValues={{
          teamname: "",
          teamlogo: ""
        }}
        validationSchema={loginParticipantSchema}
        onSubmit={values => {
          //   this.props.applyToChallenge(values, this.props.history);
        }}
      >
        {({ values, setFieldValue, setFieldTouched, errors, touched }) => (
          <Form
            className={classes.form}
            // onSubmit={onSubmit}

            encType="multipart/form-data"
            autoComplete="off"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} className={classes.modalForm}>
                <div className={classes.icon}>
                  <IconButton
                    // color="primary"
                    aria-label="upload picture"
                    onClick={() => props.toggleTeamStep1Drawer()}
                    className={classes.iconButton}
                  >
                    <ChevronLeftIcon
                      className={classes.backIcon}
                      color="#000"
                    />
                  </IconButton>
                  <Typography
                    variant="subtitle1"
                    className={classes.modalTitle}
                  >
                    Fill all the Details
                  </Typography>
                </div>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  container
                  alignItems="center"
                  justify="center"
                >
                  <div className={classes.imageContainer}>
                    <Dropzone
                      getUploadParams={getUploadParams}
                      maxFiles={1}
                      styles={{
                        dropzone: {
                          minHeight: 100,
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center"
                        }
                      }}
                      accept="image/*"
                      PreviewComponent={Preview}
                      InputComponent={InputComponent}
                      LayoutComponent={Layout}
                      getFilesFromEvent={getFilesFromEvent}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Give your team a Name"
                    className={classes.textfield}
                    onChange={text => settingTeamname(text)}
                    name="teamname"
                    value={teamname}
                    inputProps={{
                      maxLength: 50,
                      style: {
                        padding: "10px 10px"
                      }
                    }}
                  />
                </Grid>
                <Grid
                  container
                  item
                  display="flex"
                  justify="center"
                  alignItems="center"
                  direction="row"
                  className={classes.modalFooter}
                >
                  <Button
                    className={classes.cancelButton}
                    type="cancel"
                    variant="contained"
                    onClick={() => {
                      toggleTeamStep1Drawer();
                      setteamname("");
                    }}
                  >
                    CANCEL
                  </Button>
                  <Button
                    className={classes.submitButton}
                    type="submit"
                    variant="contained"
                    onClick={() => {
                      toggleTeamStep2Drawer();
                      toggleTeamStep1Drawer();
                    }}
                    disabled={!teamname || !selectedimage}
                  >
                    NEXT
                  </Button>
                  {/* <TeamFormStep2Drawer
                    teamName={teamname}
                    selectedImage={selectedimage}
                    eventData={props.eventData}
                    openTeamSecondModal={openTeamSecondModal}
                    toggleTeamStep2Drawer={() => toggleTeamStep2Drawer()}
                    toggleTeamStep1Drawer={() => props.toggleTeamStep1Drawer()}
                  /> */}
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
  return (
    <ThemeProvider theme={theme}>
      <SwipeableDrawer
        anchor="bottom"
        disableSwipeToOpen={true}
        open={props.openTeamModal}
        className={classes.mainDrawer}
        onClose={() => {
          props.toggleTeamStep1Drawer(false);
          setteamname("");
        }}
        onOpen={() => {
          props.toggleTeamStep1Drawer(true);
        }}
        PaperProps={{
          style: { borderTopLeftRadius: "20px", borderTopRightRadius: "20px" }
        }}
      >
        {sideList()}
      </SwipeableDrawer>
    </ThemeProvider>
  );
}
export default TeamFormStep1Drawer;
