import React from "react";
import axios from "axios";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import { Lock } from "react-feather";
import Paper from "@material-ui/core/Paper";
import { ThemeProvider } from "@material-ui/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "./../../Actions/authAction";
import TextFieldGroup from "./../../Components/Common/TextFieldGroup";
import { createMuiTheme } from "@material-ui/core/styles";
import LoginPopup from "../../Screens/Landing/LoginParticipantModal";

const styles = theme => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 450,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing.unit * 5,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: "10px",
    textTransform: "initial",
    backgroundColor: "#327C93"
  },
  loginButton: {
    color: "#327C93",
    background: "#efefef",
    "&:hover": {
      backgroundColor: "#efefef"
    },
    textTransform: "initial"
  },
  backButton: {
    color: "#327C93",
    background: "#efefef",
    "&:hover": {
      backgroundColor: "#efefef"
    },
    textTransform: "initial",
    marginTop: "10px"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  textFieldHelper: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: 0
  },
  errorShow: {
    color: "red"
  },
  multilineColor: {
    height: "50px",
    padding: "0px 10px",
    marginBottom: "-7px"
    // backgroundColor: "#f6f6f9"
  }
});

const theme = createMuiTheme({
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: "#F9F9F9"
      }
    },
    MuiTypography: {
      body1: {
        color: "#000000",
        fontSize: "12px"
      },
      h5: {
        fontWeight: "500"
      }
    }
  }
});

class Forgotpasswordpage extends React.Component {
  state = {
    // email: "",
    errors: {},
    isLoginOpen: false,
    success: false,
    error: false
  };

  componentDidMount() {
    // let { auth } = this.props;
    // if (auth.isAuthenticated) {
    //   this.redirectLogin(auth.hasRole);
    // }
  }

  // redirectLogin(role) {
  //   let appId =
  //     (this.props.history.location.state &&
  //       this.props.history.location.state.appID) ||
  //     null;

  //   if (appId) {
  //     this.props.history.push(`/add-application/${appId}`);
  //   } else {
  //     if (role === "candidate") {
  //       this.props.history.push("/");
  //     } else {
  //       this.props.history.push("/dashboard");
  //     }
  //   }
  // }

  onChange = e => {
    // this.setState({
    //   [e.target.name]: e.target.value
    // });
    this.props.setloginEmail(e.target.value);
  };
  onSubmit = async e => {
    e.preventDefault();
    const validate = this.validateEmail(this.props.loginEmail);
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // return re.test(String(email).toLowerCase());
    try {
      if (validate) {
        let data = {
          email: this.props.loginEmail
        };

        const response = await axios.post("/api/forgot", data);
        console.log("response", response);

        if (response.data.success === true) {
          this.setState({ success: true });
        }
      } else {
        if (this.props.loginEmail === "") {
          this.setState({ errors: { email: "Required" } });
          this.setState({ error: false });
        } else if (re.test(String(this.props.loginEmail).toLowerCase())) {
          this.setState({ errors: { email: "Enter Valid Email" } });
          this.setState({ error: false });
        }
      }
    } catch (err) {
      this.setState({ errors: {} });
      this.setState({ error: true });
    }
  };

  handleClose = () => {
    this.setState({ isLoginOpen: false });
  };

  validateEmail(email) {
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  render() {
    const { errors } = this.state;
    const {
      classes,
      history,
      toggleForgotModel,
      forgotModel,
      loginEmail
    } = this.props;
    const { success, error } = this.state;
    // console.log(this.props.loginEmail, "Peopppppppp");
    return (
      <ThemeProvider theme={theme}>
        <SwipeableDrawer
          disableSwipeToOpen={true}
          anchor="bottom"
          className={classes.mainDrawer}
          open={forgotModel}
          onClose={() => toggleForgotModel()}
        >
          <div>
            {success ? (
              <Paper className={classes.paper}>
                <Typography component="h1" variant="h5" gutterBottom>
                  We just emailed you a link
                </Typography>
                <Typography component="h2" variant="body1">
                  Please check your email and click the secure link. If you
                  don’t see our email, check your spam folder.
                </Typography>
                {/* <Typography
                  component="h2"
                  variant="body1"
                  onClick={() => {
                    this.props.toggleDrawer();
                    toggleForgotModel();
                  }}
                > */}
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.backButton}
                  onClick={() => {
                    this.props.toggleDrawer();
                    toggleForgotModel();
                  }}
                >
                  Back to Login
                </Button>
                {/* </Typography> */}
              </Paper>
            ) : (
              <main className={classes.main}>
                <CssBaseline />
                <Paper className={classes.paper}>
                  <Avatar className={classes.avatar}>
                    <Lock color="black" />
                  </Avatar>
                  <Typography component="h1" variant="h5" gutterBottom>
                    First, let's find your account{" "}
                  </Typography>
                  <Typography component="h2" variant="body1">
                    Enter your email address and we will send you a link to
                    reset your password.
                  </Typography>
                  <form className={classes.form} onSubmit={this.onSubmit}>
                    <FormControl required fullWidth>
                      <TextFieldGroup
                        name="email"
                        errors={errors}
                        label="Email Address"
                        id="email"
                        onChange={this.onChange}
                        type="input"
                        value={loginEmail}
                      />
                    </FormControl>
                    {error && (
                      <span className={classes.errorShow}>User not found</span>
                    )}
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      Send Reset Password Link
                    </Button>

                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.loginButton}
                      onClick={() => {
                        this.props.toggleDrawer();
                        toggleForgotModel();
                      }}
                    >
                      Back to Login
                    </Button>
                  </form>
                </Paper>
                <LoginPopup
                  close={this.handleClose}
                  data={this.state.isLoginOpen}
                  history={history}
                />
              </main>
            )}
          </div>
        </SwipeableDrawer>
      </ThemeProvider>
    );
  }
}

Forgotpasswordpage.propType = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps, { loginUser })(
  withStyles(styles)(Forgotpasswordpage)
);
