import React, { Component } from "react";
import Dropzone from "react-dropzone-uploader";
import axios from "axios";
import UploadIcon from "../../../Assets/UploadIcon.png";
import withStyles from "@material-ui/core/styles/withStyles";
import { ClearRounded } from "@material-ui/icons";
import { Grid, Fab } from "@material-ui/core";
import { ErrorMessage } from "formik";
import { getDroppedOrSelectedFiles } from "html5-file-selector";

const styles = theme => ({
  container: {
    // color: "red"
  },
  imageInput: {
    paddingTop: "16px"
  },
  spacingLogo: {
    padding: theme.spacing(2),
    borderRadius: "50%",
    background: "whiteSmoke",
    // width: "47px",
    // height: "48px",
    borderLeft: props => (props.required ? "2px solid #F3A7A7" : "")
  },
  form_control: {
    display: "none",
    marginTop: "10px",
    marginBottom: "10px",
    border: "none"
  },
  uploadLogo: { padding: theme.spacing(2), cursor: "pointer" },
  previewImage: { height: "100px", width: "150px", borderRadius: "5px" },
  cirPreview: { height: "100px", width: "100px", borderRadius: "100%" },
  dropzone: {
    dropzone: {
      minHeight: 100,
      justifyContent: "center",
      alignItems: "center"
    }
  },
  error: {
    color: "#f53b57",
    fontSize: "0.75rem",
    fontWeight: "400",
    padding: "9px 0px 0px 8px",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ],
    marginTop: "-5px",
    textAlaign: "left",
    lineHeight: "1em"
  },
  fab_icon: {
    width: "29px",
    height: "29px",
    position: "relative",
    top: "-100px",
    left: "-16px",
    minHeight: "17px"
  },
  fab_icon1: {
    width: "20px",
    height: "20px",
    position: "relative",
    top: "-3px",
    left: "-44px",
    minHeight: "17px"
  },
  delete_icon: {
    width: "15px",
    height: "15px"
  },
  delete_icon1: {
    width: "19px",
    height: "19px",
    top: "30px",
    left: "-16px",
    minHeight: "17px"
  },
  inputField: { display: "none", cursor: "pointer" },
  innerDiv: { cursor: "unset" },
  label: { cursor: "pointer" }
});

class UploadFieldNew extends Component {
  onChangeStatus = fileWithMeta => {
    if (fileWithMeta.meta.status === "done") {
      this.props.onChange(this.props.name, this.imageUrlPath);
    }
  };

  getUploadParams = async ({ file, meta: { name } }) => {
    let url = {};
    // const url = { fileKey: `${this.props.folder}` };
    if (this.props.fileName) {
      url = { fileKey: `${this.props.folder}/${name}` };
    } else {
      url = { fileKey: `${this.props.folder}` };
    }

    const response = await axios.post("/api/s3/presigned_url", url);
    const res = { method: "PUT", url: response.data.url, body: file };
    const imageUrl = new URL(response.data.url);
    // console.log("imageUrl", imageUrl);
    this.imageUrlPath = `${imageUrl.origin}${imageUrl.pathname}`;
    // console.log("upload params : imageUrlPath", imageUrlPath, res);
    return res;
  };

  Layout = ({ previews, submitButton, dropzoneProps, files, input }) => {
    const { classes } = this.props;

    return (
      <div {...dropzoneProps}>
        {files.length === 0 && <span className={classes.innerDiv}></span>}

        {previews}
        {input}
        {files.length > 0 && submitButton}
      </div>
    );
  };

  InputComponent = ({ accept, onFiles, files, getFilesFromEvent }) => {
    //   const text = files.length > 0 ? "Add  Images" : "Choose Images";
    const { classes } = this.props;
    const clear = this.props.isclear || false;
    return (
      <>
        {files.length <= 0 && this.props.value ? (
          <span>
            <img
              // src={this.props && this.props.value ? this.props.value : previewUrl}
              src={this.props.value}
              className={
                this.props.isCircle ? classes.cirPreview : classes.previewImage
              }
              alt={this.props.label}
            />
            {!clear && (
              <Fab
                className={
                  this.props.isCircle ? classes.fab_icon1 : classes.fab_icon
                }
                color="primary"
                aria-label="Add"
              >
                <ClearRounded
                  className={classes.delete_icon}
                  onClick={() => {
                    files.map(f => f.remove());
                    this.props.onResetBannerUrl();
                    this.props.onChange(this.props.name, undefined);
                  }}
                />
              </Fab>
            )}
          </span>
        ) : (
          <>
            {files.length <= 0 && (
              <div className={classes.imageInput}>
                <Grid container alignItems="center" direction="row">
                  <Grid className={classes.spacingLogo}>
                    <label>
                      {/* {type === "club" ? (
                  <img
                    src={UploadImage}
                    alt=" icon"
                    className={classes.uploadImage}
                  />
                ) : (
                )} */}
                      <img
                        src={UploadIcon}
                        alt=" icon"
                        className={classes.label}
                      />
                      <input
                        className={classes.inputField}
                        type="file"
                        id={this.props.id}
                        accept={accept}
                        multiple
                        onChange={e => {
                          getFilesFromEvent(e).then(chosenFiles => {
                            onFiles(chosenFiles);
                          });
                        }}
                      />
                    </label>
                  </Grid>
                  <Grid className={classes.uploadLogo}>
                    <label htmlFor={this.props.id} className={classes.label}>
                      {this.props.label}
                    </label>
                  </Grid>
                </Grid>
              </div>
            )}
          </>
        )}
        <div className={classes.error}>
          <ErrorMessage name={this.props.name} />
        </div>
      </>
    );
  };

  getFilesFromEvent = e => {
    return new Promise(resolve => {
      getDroppedOrSelectedFiles(e).then(chosenFiles => {
        resolve(chosenFiles.map(f => f.fileObject));
      });
    });
  };

  Preview = ({ meta, files }) => {
    // const { previewUrl } = meta;
    const { classes } = this.props;
    return (
      <span>
        <img
          // src={this.props && this.props.value ? this.props.value : previewUrl}
          src={this.props.value}
          className={
            this.props.isCircle ? classes.cirPreview : classes.previewImage
          }
          alt={this.props.label}
        />
        <Fab
          className={this.props.isCircle ? classes.fab_icon1 : classes.fab_icon}
          color="primary"
          aria-label="Add"
        >
          <ClearRounded
            className={classes.delete_icon}
            onClick={() => {
              files.map(f => f.remove());
              this.props.onResetBannerUrl();
              this.props.onChange(this.props.name, undefined);
            }}
          />
        </Fab>
      </span>
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Dropzone
          getUploadParams={this.getUploadParams}
          maxFiles={1}
          classNames={classes.dropzone}
          PreviewComponent={this.Preview}
          accept="image/*"
          LayoutComponent={this.Layout}
          InputComponent={this.InputComponent}
          inputWithFilesContent={null}
          canCancel={true}
          getFilesFromEvent={this.getFilesFromEvent}
          onChangeStatus={this.onChangeStatus}
        />
      </div>
    );
  }
}
export default withStyles(styles)(UploadFieldNew);
