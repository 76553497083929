import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// import { NavLink } from "react-router-dom";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
const theme = createMuiTheme({
  overrides: {
    MuiMenu: {
      paper: {
        top: "7% !important"
      }
    }
  }
});

export class RenderMenu extends React.Component {
  menuRender = () => {
    const role = localStorage.getItem("role");

    if (role === "candidate")
      return (
        <div>
          <MenuItem>My Challenges</MenuItem>
          <MenuItem> My Profile</MenuItem>
          <MenuItem>Settings</MenuItem>
        </div>
      );
  };
  render() {
    let { anchorEl, isMenuOpen } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={isMenuOpen}
          onClose={this.props.handleMenuClose}
        >
          {/* TODO not in forst version */}
          {/* <MenuItem
            onClick={this.props.handleMenuClose}
            component={NavLink}
            to="/add-profile"
          >
            Profile
          </MenuItem> */}
          {this.menuRender()}

          <MenuItem onClick={this.props.logout}>Logout</MenuItem>
        </Menu>
      </ThemeProvider>
    );
  }
}
