import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { TableCell } from "@material-ui/core";

const StyledTableCell = withStyles(() => ({
  body: {
    textTransform: "capitalize",
    borderBottom: "none",
    padding: "8px 10px 8px 8px",
    fontWeight: "normal",
    fontSize: "14px",
    textAlign: "left",
    color: "#626262"
  }
}))(TableCell);

const Cell = props => {
  return (
    <StyledTableCell
      style={{
        // width: props.width || "10%",
        textAlign: "left",
        padding: "10px 16px",
        textTransform: props.lower && "lowercase",
        cursor: props.cursor && "pointer",
        color: props.color && "#2959e0"
      }}
      onClick={props.onClick}
      colSpan={props.colSpan ? props.colSpan : ""}
    >
      {props.children}
    </StyledTableCell>
  );
};

export default Cell;
