import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { Paper, Button } from "@material-ui/core";
import myProfile from "../../Assets/Profileimage.png";
import { Edit2 } from "react-feather";
import LinkedinA from "../../Assets/icons/Linkedin.svg";
import LinkedinG from "../../Assets/icons/linkedin_grey.svg";
import FacebookA from "../../Assets/icons/facebook_a.svg";
import FacebookG from "../../Assets/icons/facebook_grey.svg";
import GithubA from "../../Assets/icons/Github.svg";
import GithubG from "../../Assets/icons/github_grey.svg";
import MbViewTechnicalSkills from "./MbViewTechnicalSkills";
import CommonStyles from "../../Theme/commonStyles";

const $quoteSym = " ";

const styles = () => ({
  root: {
    // width: `calc(100% - ${drawerWidth}px - 80px)`,
    // width: "94%",
    overflowY: "auto",
    padding: "14px 15px",
    paddingTop: "10%",
    position: "relative",
    minHeight: "100vh",
    zIndex: 1,
    background: "#ffffff"
  },
  personalDetail: {
    background: "#FFFFFF"
  },
  profilePhoto: {
    width: "124px",
    height: "124px",
    margin: "0 auto"
  },
  profile: {
    borderRadius: "50%",
    width: "100%",
    height: "100%"
  },
  userName: {
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "33px",
    color: "#000000",
    textAlign: "center",
    margin: "12px 0px 4px",
    textTransform: "capitalize"
  },
  subTitle: {
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "19px",
    color: "#000000",
    textAlign: "center",
    margin: "0px 0px 10px",
    textTransform: "capitalize"
  },
  socialIcon: CommonStyles.socialIcon,
  socialData: {
    display: "flex",
    justifyContent: "center",
    borderBottom: "1px solid #efefef",
    background: "#ffffff",
    padding: "8px 0px 18px"
  },
  titleLeft: {
    paddingBottom: "5px",
    position: "relative",
    fontSize: "18px",
    lineHeight: "32px",
    marginBottom: "0px",
    fontWeight: 500,
    "&:after": {
      top: "30px",
      left: "4px",
      width: "24px",
      borderBottom: "3px solid #4CABD0",
      content: `'${$quoteSym}'`,
      position: "absolute"
    }
  },
  bioContent: {
    fontSize: "14px",
    lineHeight: "24px",
    color: "#222222",
    paddingBottom: "20px"
  },
  editButton: {
    background: "transparent",
    border: "none",
    color: "#CDCDCD",
    position: "relative",
    right: "-5px"
  },
  editProfile: {
    position: "relative",
    top: "-28px",
    right: "-84px",
    background: "#fff",
    borderRadius: "50%",
    border: "none",
    color: "#4CABD0",
    padding: "2px 6px",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)"
  },
  mainHeader: CommonStyles.mainHeader,
  contactButton: {
    background: "#38A743",
    borderRadius: "5px",
    textTransform: "lowercase",
    color: "#ffffff",
    marginRight: "5px"
  },
  mailButton: {
    background: "#4CABD0",
    borderRadius: "5px",
    textTransform: "lowercase",
    color: "#ffffff",
    marginLeft: "5px"
  },
  buttonSection: {
    display: "flex",
    justifyContent: "space-around",
    paddingBottom: "20px",
    borderBottom: "1px solid #efefef",
    marginBottom: "16px"
  }
});

function MbViewProfile(props) {
  const { classes, userData } = props;

  const editPersonal = () => {
    props.viewProfileHandler(0);
  };
  return (
    <React.Fragment>
      <Paper className={classes.root}>
        <div className={classes.personalDetail}>
          <div className={classes.profilePhoto}>
            <img
              src={userData && userData.photo ? userData.photo : myProfile}
              className={classes.profile}
              alt="My Profile"
            />
          </div>
          <p className={classes.userName}>
            {userData && userData.first_name ? userData.first_name : "-"}{" "}
            {userData && userData.last_name ? userData.last_name : "-"}
          </p>
          <p className={classes.subTitle}>
            {userData && userData.location ? userData.location : "-"}
          </p>

          <div className={classes.socialData}>
            <img
              src={userData && userData.github ? GithubA : GithubG}
              alt=""
              className={classes.socialIcon}
            />
            <img
              src={userData && userData.linkedin ? LinkedinA : LinkedinG}
              alt=""
              className={classes.socialIcon}
            />
            <img
              src={userData && userData.facebook ? FacebookA : FacebookG}
              alt=""
              className={classes.socialIcon}
            />
          </div>
          <div className={classes.bioDetail}>
            <div className={classes.mainHeader}>
              <p className={classes.titleLeft}>Bio</p>
              <button className={classes.editButton} onClick={editPersonal}>
                <Edit2 />
              </button>
            </div>
            <p className={classes.bioContent}>
              {userData && userData.bio ? userData.bio : "-"}
            </p>
          </div>
        </div>
        <div className={classes.buttonSection}>
          {userData && userData.contact_number && (
            <Button
              variant="contained"
              className={classes.contactButton}
              size="medium"
            >
              {userData && userData.contact_number}
            </Button>
          )}
          {userData && userData.email && (
            <Button
              variant="contained"
              className={classes.mailButton}
              size="medium"
            >
              {userData && userData.email}
            </Button>
          )}
        </div>
        <div>
          <MbViewTechnicalSkills
            userData={userData}
            viewProfileHandler={props.viewProfileHandler}
          />
        </div>
      </Paper>
    </React.Fragment>
  );
}

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {})(withStyles(styles)(MbViewProfile));
