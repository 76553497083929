/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import * as Yup from "yup";
import { Formik } from "formik";
import { Form } from "react-formik-ui";
import TextField from "@material-ui/core/TextField";
// import { InputField } from "../../Components/Common/Form";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CloseIcon from "@material-ui/icons/Close";
import _map from "lodash/map";
// import { addISOWeekYears } from "date-fns";
const useStyles = makeStyles({
  list: {
    width: "100%",
    // width: "100%",
    backgroundColor: "#fff",
    // backgroundColor: "#F9F9F9",
    padding: "16px"
  },
  fullList: {
    width: "auto"
  },
  paper: {
    top: "48px"
  },
  modalTitle: {
    // height:"200px",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "28px",
    textAlign: "center",
    color: "#222222",
    display: "block",
    position: "relative",
    // top: "-39px"
    width: "85%",
    alignSelf: "center",
    marginRight: "7.5%"
  },
  modalForm: {
    maxWidth: "100%"
  },
  submitButton: {
    background: "#327C93",
    borderRadius: "5px",
    padding: "10px 30px",
    color: "#ffffff",
    marginLeft: "5px",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)",
    "&:hover": {
      background: "#327C93",
      color: "#ffffff"
    }
  },

  addButton: {
    background: "#327C93",
    width: "10%",
    borderRadius: "5px",
    padding: "8px 2px",
    color: "#ffffff",
    marginLeft: "5px",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)",
    "&:hover": {
      background: "#327C93",
      color: "#ffffff"
    }
  },
  cancelButton: {
    background: "#efefef",
    borderRadius: "5px",
    padding: "10px 30px",
    color: "#327C93",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)",
    marginRight: "26px",
    "&:hover": {
      background: "#efefef",
      color: "#327C93"
    }
  },
  modalFooter: {
    margin: "30px 0px 14px 0px"
  },
  signup: {
    color: "#327C93",
    textDecoration: "none"
  },
  backIcon: {
    height: "1.5em",
    position: "relative",
    // left: "-8px",
    width: "1.5em"
  },
  emailid: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "19px",
    color: "#222222",
    borderRadius: "50px",
    padding: "7px 22px 10px 20px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.05)",
    display: "inline-block",
    fontFamily: "Open Sans"
  },
  closeicon: {
    position: "relative",
    top: "0px",
    left: "10px",
    color: "#ABABAB"
  },
  inputmodel: {
    marginTop: "50px",
    marginBottom: "70px"
  },
  icon: {
    display: "flex",
    width: "100%"
  },
  addTeammate: {
    display: "flex",
    width: "100%",
    textAlign: "center"
  },
  iconButton: {
    width: "15%",
    alignItems: "center",
    color: "#000"
  },
  emailButton: {
    // borderRadius: "20px",
    // alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    padding: "0px",
    paddingLeft: "0px",
    paddingRight: "0px",
    margin: "0px"
  },
  errorShow: {
    color: "red",
    textAlign: "center"
  }
});
const theme = createMuiTheme({
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: "#F9F9F9"
      }
    },
    MuiOutlinedInput: {
      input: {
        padding: "13px 18px"
      }
    },
    MuiTypography: {
      body1: {
        color: "#ABABAB",
        fontSize: "12px"
      }
    }
  }
});

const TeamParticipantSchema = Yup.object().shape({
  email: Yup.string().required("Required")
});

function TeamFormStep2Drawer(props) {
  const {
    teamname,
    selectedimage,
    eventData,
    setselectedimage,
    setteamname,
    participatedLength,
    applicationid,
    isEditable,
    invitedMember
  } = props;

  const classes = useStyles();
  const [teammates, setteammates] = useState([]);
  const [email, setemail] = useState("");
  const [sameEmail, setsameEmail] = useState(false);
  const [maximummemberError, setmaximummemberError] = useState(false);
  const [duplicateEmail, setduplicateEmail] = useState(false);
  const [iseditable, setisEditable] = useState(isEditable ? isEditable : false);
  const Invited =
    invitedMember &&
    invitedMember.map(u => {
      return u.email;
    });
  const handleClose = () => {
    props.toggleTeamStep2Drawer();
    setteammates([]);
    // props.toggleTeamStep1Drawer();
  };

  const handleInputChange = value => {
    setemail(value.target.value);
  };

  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const emailvalidation = re.test(email);

  const handleSubmit = async () => {
    let invitations = teammates;
    let logo = selectedimage; // added for teating purpose coz image not upload
    let event = eventData._id;
    let application_id = applicationid;

    const team_name = teamname;

    let values = { event, logo, team_name, invitations };
    if (iseditable) {
      let values = { event, application_id, invitations };
      const url = `/api/application//add/teammates`;
      await axios
        .put(url, values)
        .then(data => {
          handleClose();
          setselectedimage();
          setteamname("");
        })
        .catch(error => {
          console.log(error, "error while adding team");
        });
    } else {
      await axios
        .post("/api/application", values)
        .then(data => {
          handleClose();
          setselectedimage();
          setteamname("");
        })
        .catch(error => {
          console.log(error, "error while adding team");
        });
    }
  };

  const sideList = () => (
    <div className={classes.list} padding="yes" role="presentation">
      <Formik
        initialValues={{
          email: "",
          password: ""
        }}
        validationSchema={TeamParticipantSchema}
        onSubmit={values => {
          //   this.props.applyToChallenge(values, this.props.history);
        }}
      >
        {({ values, setFieldValue, setFieldTouched, errors, touched }) => (
          <Form
            className={classes.form}
            // onSubmit={onSubmit}
            encType="multipart/form-data"
            autoComplete="off"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} className={classes.modalForm}>
                <div className={classes.icon}>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    onClick={() => props.toggleTeamStep2Drawer()}
                    className={classes.iconButton}
                  >
                    <ChevronLeftIcon className={classes.backIcon} />
                  </IconButton>
                  <Typography
                    variant="subtitle1"
                    className={classes.modalTitle}
                  >
                    Invite Team Member
                  </Typography>
                </div>
                <Grid
                  className={
                    teammates && teammates.length <= 0 && classes.inputmodel
                  }
                >
                  <Grid item xs={12} sm={12} classes={classes.addTeammate}>
                    <TextField
                      name="mail"
                      placeholder="Team member’s Email ID"
                      variant="outlined"
                      id="outlined-basic"
                      onChange={value => {
                        handleInputChange(value);
                        setduplicateEmail(false);
                        setsameEmail(false);
                      }}
                      style={{ width: "75%" }}
                      inputProps={{
                        maxLength: 50,
                        style: {
                          padding: "10px 10px"
                        }
                      }}
                      // className={classes.textfield}
                      value={email}
                    />

                    <Button
                      className={classes.addButton}
                      variant="contained"
                      // itemProp={styles}
                      onClick={() => {
                        const demolength = participatedLength
                          ? participatedLength + teammates.length <
                            eventData.team_max
                          : teammates.length < eventData.team_max - 1;

                        if (demolength) {
                          let newArray = [...teammates];
                          let data = newArray.map(u => {
                            return u.email;
                          });
                          if (localStorage.getItem("email") !== email) {
                            let duplicate =
                              data.includes(email) ||
                              (Invited && Invited.includes(email));

                            if (!duplicate) {
                              let obj = { email: email, status: "waiting" };
                              email && newArray.push(obj);
                              setteammates(newArray);
                              setemail("");
                            } else {
                              setduplicateEmail(true);
                            }
                          } else {
                            setsameEmail(true);
                          }
                        } else setmaximummemberError(true);
                      }}
                      disabled={!email || !emailvalidation}
                    >
                      Add
                    </Button>
                  </Grid>
                  <div>
                    {teammates &&
                      teammates.map((u, i) => {
                        return (
                          <p className={classes.emailid} key={i}>
                            {u.email}

                            {/* <CloseIcon className={classes.closeicon} /> */}
                            <Button
                              color="primary"
                              className={classes.emailButton}
                              onClick={() => {
                                let newArray = [...teammates];
                                const data = newArray.filter(
                                  x => x.email !== u.email
                                );
                                setteammates(data);
                                setmaximummemberError(false);
                              }}
                            >
                              <CloseIcon className={classes.closeicon} />
                            </Button>
                          </p>
                        );
                      })}
                  </div>
                </Grid>
                {maximummemberError && (
                  <p className={classes.errorShow}>
                    Maximum{" "}
                    {participatedLength
                      ? eventData.team_max - participatedLength
                      : eventData.team_max - 1}{" "}
                    team members allowed...
                  </p>
                )}
                {sameEmail && (
                  <p className={classes.errorShow}>
                    User is already registered in the system
                  </p>
                )}
                {duplicateEmail && (
                  <p className={classes.errorShow}>
                    User is already part of this team
                  </p>
                )}

                <Grid
                  container
                  item
                  display="flex"
                  justify="center"
                  alignItems="center"
                  direction="row"
                  className={classes.modalFooter}
                >
                  <Button
                    className={classes.cancelButton}
                    type="cancel"
                    variant="contained"
                    onClick={handleClose}
                  >
                    CANCEL
                  </Button>
                  <Button
                    className={classes.submitButton}
                    type="submit"
                    variant="contained"
                    onClick={() => {
                      handleSubmit();
                    }}
                    disabled={teammates.length === 0}
                  >
                    SUBMIT
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
  return (
    <ThemeProvider theme={theme}>
      <SwipeableDrawer
        disableSwipeToOpen={true}
        anchor="bottom"
        open={props.openTeamSecondModal}
        // open={true}
        className={classes.mainDrawer}
        onClose={() => {
          props.toggleTeamStep2Drawer(false);
        }}
        onOpen={() => {
          props.toggleTeamStep2Drawer(true);
        }}
        PaperProps={{
          style: { borderTopLeftRadius: "20px", borderTopRightRadius: "20px" }
        }}
      >
        {sideList()}
      </SwipeableDrawer>
    </ThemeProvider>
  );
}

const mapStateToProps = state => {
  return {
    errors: state.errors
  };
};

export default connect(mapStateToProps, {})(TeamFormStep2Drawer);
