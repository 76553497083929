import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import nodata from "../../Assets/nodata.png";

const styles = () => {
  return {
    image: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "center"
    },
    maintext: {
      color: "lightgrey"
    },
    headerSpacing: {
      marginTop: "10%",
      marginBottom: "3%"
    },
    text: {
      fontSize: "24px",
      color: "#000000",
      fontWeight: "600"
    },
    button: {
      background: "#211B4E",
      borderRadius: "5px",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "16px",
      color: "#FCFDFE",
      textTransform: "none",
      padding: "8px 20px",
      "&:hover": {
        color: "#FCFDFE",
        background: "#211B4E"
      }
    },
    buttonLayout: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "5%"
    },
    innerContainer: {
      display: "flex",
      justifyContent: "center"
    }
  };
};

const NoDataFound = props => {
  const { classes, image, text } = props;
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.headerSpacing}
    >
      <Grid item justify="center">
        <Grid item sm={12} lg={12} xs={12} className={classes.innerContainer}>
          <img src={image || nodata} alt="nodata" width="60%" />
          <Typography className={classes.text}>{text}</Typography>
          <Grid className={classes.buttonLayout}>
            {/* <Button color="primary" className={classes.button}>
              {buttonText}
            </Button> */}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(NoDataFound);
