import {
  GET_PARTNERS,
  PARTNERS_LOADING,
  ADD_PARTNER_ERROR,
  ADD_PARTNER_LOADING,
  ADD_PARTNER,
  GET_PARTNER,
  GET_ALL_PARTNER,
  PARTNER_ERROR,
  REMOVE_PARTNER_COMPANY,
  REMOVE_PARTNER_COMPANY_ERROR,
  REMOVE_PARTNER_EVENT,
  REMOVE_PARTNER_EVENT_ERROR
} from "../Actions/types";
const initialState = {
  partners: [],
  loading: false,
  addPartner: { partnerLoading: false },
  partner: { loading: false },
  all_partners: [],
  removePartner: { loading: false }
};
export default function(state = initialState, action) {
  switch (action.type) {
    case GET_PARTNERS:
      return {
        ...state,
        loading: false,
        partners: action.payload && action.payload.data
      };
    case GET_PARTNER:
      return {
        ...state,
        loading: false,
        partner: action.payload && action.payload.data
      };
    case GET_ALL_PARTNER:
      return {
        ...state,
        loading: false,
        all_partners: action.payload && action.payload.data
      };
    case PARTNERS_LOADING:
      return {
        ...state,
        loading: true
      };
    case ADD_PARTNER:
      return {
        ...state,
        addPartner: {
          success: true,
          loading: false,
          data: action.payload && action.payload.data
        }
      };
    case REMOVE_PARTNER_COMPANY:
      return {
        ...state,
        removePartner: {
          success: true,
          loading: false,
          data: action.payload && action.payload.data
        }
      };
    case REMOVE_PARTNER_EVENT:
      return {
        ...state,
        removePartner: {
          success: true,
          loading: false,
          data: action.payload && action.payload.data
        }
      };
    case ADD_PARTNER_LOADING:
      return {
        ...state,
        addPartner: {
          loading: true
        }
      };
    case REMOVE_PARTNER_COMPANY_ERROR:
      return {
        ...state,
        addPartner: {
          loading: true
        }
      };
    case REMOVE_PARTNER_EVENT_ERROR:
      return {
        ...state,
        addPartner: {
          loading: true
        }
      };
    case ADD_PARTNER_ERROR:
      return {
        ...state,
        addPartner: {
          success: false,
          loading: false
        }
      };
    case PARTNER_ERROR:
      return {
        ...state,
        success: false,
        loading: false,
        data: action.payload && action.payload.data
      };
    default:
      return state;
  }
}
