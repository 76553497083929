import React, { useState, useEffect } from "react";
import { Paper, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import CheckIcon from "@material-ui/icons/Check";
import CancelIcon from "@material-ui/icons/Close";
import FilterListIcon from "@material-ui/icons/FilterList";
import { getParticipantApplication } from "../../Actions/applicationActions";
import { spacecapitalize } from "../../Utils/StringFunctions";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import NoDataFound from "../../Components/Common/NoDataFound";
import _get from "lodash/get";
import _concate from "lodash/concat";
import _uniqBy from "lodash/uniqBy";
const styles = () => ({
  root: {
    paddingTop: "18px",
    paddingBottom: "15px",
    overflow: "scroll",
    height: "91vh"
  },
  mychallenges_main: {
    padding: "10px 24px"
  },
  mychallenges_inner: {
    padding: "10px",
    background: "#FFFFFF",
    boxShadow: " 0px 0px 30px rgba(0, 0, 0, 0.05)",
    borderRadius: "5px",
    width: "100%",
    marginBottom: "10px"
  },
  challenges_name: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "25px",
    color: "#222222",
    marginBottom: "0px",
    width: "80%"
  },
  doneIcon: {
    color: "#11A711",
    position: "relative",
    top: "5px",
    left: "18px",
    width: "15%"
  },
  cancelIcon: {
    color: "#F21634",
    position: "relative",
    top: "5px",
    left: "18px",
    width: "15%"
  },
  filterIcon: {
    color: "#FF6F00",
    position: "relative",
    top: "5px",
    left: "18px",
    width: "15%"
  },
  challenges_date: {
    fontSize: "14px",
    lineHeight: "19px",
    color: " #626262",
    marginTop: "4px"
  },
  team_detail: {
    display: "flex",
    justifyContent: "space-between"
  },
  company_name: {
    marginBottom: "0px",
    textAlign: "center",
    fontSize: "14px"
  },
  inner_title: {
    marginTop: "2px",
    color: "#ABABAB",
    fontSize: "12px"
  },
  closeIcon: {
    color: "#F21634",
    width: "0.85em",
    position: "relative",
    top: "5px",
    left: "18px"
  },
  noDataContainer: {
    textAlign: "center",
    marginTop: "100px",
    marginBottom: "200px"
  },
  eventNameContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  name: { textTransform: "capitalize" },
  status: {
    backgroundColor: "#fff",
    textAlign: "right",
    textTransform: "capitalize",
    fontSize: "12px"
  },
  button: {
    padding: "4px 10px",
    color: "#fff",
    backgroundColor: "#211b4e",
    borderwidth: "0",
    borderRadius: "5px"
  },
  formbutton: {
    display: "flex",
    justifyContent: "center"
  },
  emailContainer: { wordBreak: "break-all" }
});

function ApplicationForm(props) {
  const { classes } = props;
  const [participantApplication, setparticipantApplication] = useState([]);
  const [AddTeammate, setAddTeammate] = useState(false);

  useEffect(() => {
    props.getParticipantApplication();
  }, []);

  useEffect(() => {
    setparticipantApplication(props.application);
  }, [props.application]);

  const status = type => {
    switch (type) {
      case "registered":
        return <FilterListIcon className={classes.filterIcon} />;
      case "on_hold":
        return <FilterListIcon className={classes.filterIcon} />;
      case "rejected":
        return <CancelIcon className={classes.cancelIcon} />;
      case "disqualified":
        return <CancelIcon className={classes.cancelIcon} />;
      case "shortlisted":
        return <CheckIcon className={classes.doneIcon} />;
      case "applied":
        return <CheckIcon className={classes.doneIcon} />;
      case "won":
        return <CheckIcon className={classes.doneIcon} />;
      default:
        return <FilterListIcon className={classes.filterIcon} />;
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.main}>
        <Paper className={classes.root}>
          {participantApplication.length <= 0 && (
            <div className={classes.noDataContainer}>
              <NoDataFound />
            </div>
          )}
          {participantApplication.length > 0 && (
            <div className={classes.mychallenges_main}>
              {participantApplication &&
                participantApplication.map(u => {
                  // for member count
                  const totalmembercount = _uniqBy(
                    _concate(
                      u.application.active_members,
                      u.application.invitations
                    ),
                    "email"
                  ).length;
                  return (
                    <div className={classes.mychallenges_inner} key={u._id}>
                      <div className={classes.eventNameContainer}>
                        <p className={classes.challenges_name}>
                          {spacecapitalize(_get(u, "event.event_name", ""))}
                        </p>
                        <Tooltip
                          title={spacecapitalize(
                            u.application && u.application.status
                          )}
                          aria-label="add"
                        >
                          {status(u.application && u.application.status)}
                        </Tooltip>
                      </div>
                      <p className={classes.challenges_date}>
                        {moment(u.event && u.application.createdAt).format(
                          "DD/MM/YYYY"
                        )}
                      </p>
                      <div className={classes.team_detail}>
                        <div className={classes.team_content}>
                          <p className={classes.company_name}>
                            {spacecapitalize(
                              _get(u, "event.company.company_name", "")
                            )}
                          </p>
                          <p className={classes.inner_title}>Company Name</p>
                        </div>
                        <div className={classes.team_content}>
                          <p className={classes.company_name}>
                            {spacecapitalize(
                              _get(u, "application.team_name", "")
                            )}
                          </p>
                          <p className={classes.inner_title}>Team Name</p>
                        </div>
                        <div className={classes.team_content}>
                          <p className={classes.company_name}>
                            {totalmembercount}
                          </p>
                          <p className={classes.inner_title}>Members</p>
                        </div>
                      </div>
                      <div className={classes.formbutton}>
                        <button
                          className={classes.button}
                          onClick={() =>
                            props.history.push(
                              `/participant/my-application/${u.event._id}`
                            )
                          }
                        >
                          {_get(u, "application.isfillform", false)
                            ? "View Details"
                            : "Add Details"}
                        </button>
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
        </Paper>
      </main>
    </React.Fragment>
  );
}
const mapStateToProps = state => {
  return {
    errors: state.errors,
    application: state.applications.participantAllApplication || {}
  };
};

export default connect(mapStateToProps, { getParticipantApplication })(
  withStyles(styles)(ApplicationForm)
);
