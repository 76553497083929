import { GET_ERRORS, SET_CURRENT_USER, USER_DATA } from "./types";
import axios from "axios";
import setAuthToken from "./../Utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { showSuccess } from "./ActionsHelper";
import { transformErrorsFromApi } from "./TransformError";
// Register
export const registerUser = (
  userData,
  setErrors,
  setSubmitting,
  props
) => dispatch => {
  return new Promise(resolve => {
    axios
      .post("/api/signup", userData)
      .then(data => {
        resolve(data);
        dispatch(showSuccess("User"));
        props.handleClose();
        props.isLoginPopupHandle();
      })
      .catch(err => {
        const error = err.response;

        // dispatch({ type: GET_ERRORS, payload: err.response});
        // dispatch(showError("There was some error while login."));
        if (setSubmitting) {
          setSubmitting(false);
        }
        if (error !== undefined && setErrors) {
          setErrors(transformErrorsFromApi(error));
        }
      });
  });
};

// Login User - Get user token
export const loginUser = (userData, setErrors, setSubmitting) => dispatch => {
  return new Promise(resolve => {
    axios
      .post("/api/signin", userData)
      .then(result => {
        // SAVE to local storage
        const { token, role } = result.data.data;
        resolve(result);
        // Set token to local storage
        localStorage.setItem("jwtToken", token);
        localStorage.setItem("role", role);
        localStorage.setItem("email", userData.email);
        // Set Token to Auth Header
        setAuthToken(token);
        // Decode token to get user data
        const decoded = jwt_decode(token);
        localStorage.setItem("user_id", decoded.id);
        console.log(decoded, "decoded data is");
        // localStorage.setItem("role", token);

        // Set Current User
        dispatch(setCurrentUser(decoded));
      })
      .catch(err => {
        const error = err.response;
        dispatch({ type: GET_ERRORS, payload: err.response });
        // dispatch(showError("There was some error while login."));
        if (setSubmitting) {
          setSubmitting(false);
        }
        if (setErrors) {
          setErrors(transformErrorsFromApi(error));
        }
      });
  });
};

export const getuserdata = () => dispatch => {
  axios.get("/api/user/me").then(res => {
    dispatch({
      type: USER_DATA,
      payload: res.data.data
    });
  });
};

// Set loggedin users
export const setCurrentUser = decoded => {
  console.log(decoded, "deconded from current");
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

// Log user out
export const logoutUser = () => dispatch => {
  // remove token from local storage
  localStorage.clear();
  // remove Auth header
  setAuthToken(false);
  // Set current user to {} which will also set isAuthenticated to false
  dispatch(setCurrentUser({}));
};
