import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import {
  withStyles,
  Paper,
  createMuiTheme,
  ThemeProvider
} from "@material-ui/core";
import ProfileTabs from "../MyProfile/ProfileTabs";
import { getParticipantApplication } from "../../../Actions/applicationActions";
import ViewMyProfile from "./VIewMyProfile";

const styles = () => ({
  root: {
    // width: "100%",
    overflowY: "auto",
    padding: "14px 40px",
    paddingTop: "5%",
    position: "relative",
    minHeight: "100vh",
    zIndex: 1,
    // background: "#fafafa",
    boxShadow: "none",
  }
});

const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      body: {
        color: "#000000",
        fontSize: "14px",
        lineHeight: "19px"
      },
      head: {
        fontSize: "12px",
        lineHeight: "16px",
        color: "#626262"
      }
    },
    MuiSvgIcon: {
      root: {
        position: "relative",
        top: "10px",
        rigth: "-10px"
      }
    }
  },
  MuiPaper: {
    elevation1: {
      boxShadow: "none"
    }
  }
});

function Profile(props) {
  const { classes } = props;
  const [userData, setUserData] = useState();
  const [viewProfile, setViewProfile] = useState(false);
  const [value, setValue] = useState(0);

  useEffect(() => {
    callUserApi();
  }, []);

  

  useEffect(() => {
    userData &&
      userData[0] &&
      userData[0].isSubmit === true &&
      setViewProfile(!viewProfile);
  }, [userData]);

  const viewProfileHandler = (digit, data) => {
    if (data) {
      callUserApi();
    } else {
      setViewProfile(!viewProfile);
    }
    setValue(digit);
  };

  const callUserApi = async () => {
    const userId = localStorage.getItem("user_id");
    const query = { user_id: userId };
    await axios
      .get(`/api/v2/userprofile`, {
        params: {
          filter: JSON.stringify(query)
        }
      })
      .then(response => {
        setUserData(response.data);
      })
      .catch(error => {
        console.log("error--->>>", error);
      });     
  };

  return (
    <ThemeProvider theme={theme}>
      <Paper className={classes.root} style={{backgroundColor:viewProfile===true?"#fafafa":"#fff"}}>
        {viewProfile === true ? (
          <ViewMyProfile
            userData={userData && userData[0]}
            history={props.history}
            viewProfileHandler={viewProfileHandler}
          />
        ) : (
          <ProfileTabs value={value} viewProfileHandler={viewProfileHandler} />
        )}
      </Paper>
    </ThemeProvider>
  );
}

Profile.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    participantAllApplication:
      state.applications.participantAllApplication || {}
  };
};

export default connect(mapStateToProps, {
  getParticipantApplication
})(withStyles(styles)(Profile));
