/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from "react";
import { Paper, Grid, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import memberProfile from "../../Assets/User.png";
import classNames from "classnames";
import AddIcon from "@material-ui/icons/Add";
import axios from "axios";
import { capitalize } from "../../Utils/StringFunctions";
import NoDataFound from "../../Components/Common/NoDataFound";

const styles = () => ({
  root: {
    paddingTop: "18px",
    paddingBottom: "15px",
    overflow: "scroll"
  },
  myteam_main: {
    padding: "10px 24px"
  },
  layout: {
    marginTop: "19px !important",
    padding: "20px 25px"
  },
  main_title: {
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "27px",
    color: "#222222",
    textAlign: "center",
    marginBottom: "0px"
  },

  member_name: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#222222",
    marginBottom: "0px"
  },
  team_detail: {
    display: "flex",
    justifyContent: "space-between"
  },
  company_name: {
    marginBottom: "0px"
  },
  mailid: {
    marginTop: "2px",
    color: "#ababab",
    fontSize: "12px"
  },
  member_image: {
    width: "80px",
    height: "80px"
  },
  teamcard: {
    textAlign: "center",
    background: "#FFFFFF",
    boxShadow: " 0px 0px 20px rgba(0, 0, 0, 0.05)",
    borderRadius: "10px",
    margin: "16px 0px 12px 0px"
  },
  acepted_status: {
    color: "#009246",
    fontSize: "12px"
  },
  rejected_status: {
    color: "#F44336",
    fontSize: "12px"
  },
  invited_status: {
    color: "#407BFF",
    fontSize: "12px"
  },
  active_member: {
    border: "5px solid #B9E5F3",
    borderRadius: "50px",
    padding: "4px",
    width: "88px",
    height: "88px"
  },
  add_icon: {
    color: "#EFEFEF",
    width: "3em",
    height: "3em"
  },
  addteamcard: {
    height: "200px",
    border: "5px solid #efefef",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
    margin: "10px 0px 8px 12px"
  }
});

function MyTeam(props) {
  const { classes } = props;
  const [myTeanList, setmyTeanList] = useState([]);

  const userId = localStorage.getItem("user_id");

  useEffect(() => {
    let data = userId;
    axios
      .get(
        `/api/application/v2/participants?filter={"user":${JSON.stringify(
          data
        )}}`
      )
      .then(data => {
        let application = data.data;
        let appID = "";

        application.map(option => {
          return (appID = option.application);
        });
        axios
          .get(
            `/api/application/v2/teams?filter={"_id":${JSON.stringify(appID)}}`
          )
          .then(data => {
            setmyTeanList(data.data);
          })
          .catch(error => {
            // console.log(error, "Error");
          });
      })
      .catch(error => {});
  }, []);

  return (
    <React.Fragment>
      <CssBaseline />
      <main>
        <Paper className={classes.root}>
          {myTeanList.length <= 0 && (
            <div
              style={{
                textAlign: "center",
                marginTop: "100px",
                marginBottom: "200px"
              }}
            >
              <NoDataFound />
            </div>
          )}
          {myTeanList.length > 0 && (
            <div className={classes.myteam_main}>
              {/* <h1 className={classes.main_title}>My Team</h1> */}

              {myTeanList &&
                myTeanList.map((element, id) => {
                  return (
                    <div
                      className={classNames(classes.layout, classes.cardGrid)}
                    >
                      <Grid container spacing={2}>
                        <Grid container spacing={2}>
                          {element &&
                            element.active_members &&
                            element.active_members.map((option, id) => {
                              return (
                                <Grid
                                  item
                                  lg={4}
                                  xs={6}
                                  sm={5}
                                  className={classes.teamcard}
                                >
                                  <img
                                    src={memberProfile}
                                    alt="img"
                                    className={classNames(
                                      classes.member_image,
                                      classes.active_member
                                    )}
                                  />
                                  <p className={classes.member_name}>
                                    {capitalize(option.first_name).concat(
                                      " ",
                                      option && capitalize(option.last_name)
                                    )}
                                  </p>
                                  <p className={classes.mailid}>
                                    {option.email}
                                  </p>
                                  <p className={classes.acepted_status}>
                                    Accepted
                                  </p>
                                </Grid>
                              );
                            })}
                        </Grid>
                      </Grid>
                    </div>
                  );
                })}

              <Grid item lg={4} xs={6} sm={5} className={classes.addteamcard}>
                <AddIcon className={classes.add_icon} />
              </Grid>
            </div>
          )}
        </Paper>
      </main>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {
    errors: state.errors
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(MyTeam));
