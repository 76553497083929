/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-else-return */
/* eslint-disable no-unused-expressions */
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { Dialog, DialogContent, Grid, Typography } from "@material-ui/core";
import modal_img from "./../../Assets/participant_login_modal_img.svg";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import { InputField } from "../../Components/Common/Form";
// import EmailIcon from "@material-ui/icons/Email";
import LoginUserForm from "../Login/LoginUserForm";
import { push } from "connected-react-router";

// import GitHubIcon from "@material-ui/icons/GitHub";

const theme = createMuiTheme({
  overrides: {
    MuiDialog: {
      // Name of the component ⚛️ / style sheet
      paperWidthSm: {
        maxWidth: 860,
        borderRadius: "10px"
      }
    },
    // MuiIconButton: {
    //   colorPrimary: {
    //     color: "#327c93 !important"
    //   }
    // },
    MuiTypography: {
      body1: {
        color: "#ABABAB",
        fontSize: "12px"
      }
    }
  }
});
const styles = () => ({
  registermodal: {
    width: 800,
    padding: "34px 24px",
    maxWidth: 800,
    overflowY: "auto",
    overflowX: "hidden"
  },
  member_modal: {
    minWidth: "400px"
  },
  main: {
    padding: "24px"
  },
  modalImage: {
    width: "100%"
  },

  UserDetail: {
    position: "relative",
    bottom: "54px",
    left: "57px"
  },
  coworkerSection: {
    borderBottom: "1px solid #efefef",
    height: "74%"
  },
  modalTitle: {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#222",
    padding: "0% 4% 0% 0%",
    textTransform: "capitalize",
    lineHeight: "28px"
  },

  modalInnerCont: {
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "18px",
    color: "#626262",
    margin: "12px 1px  3px 3px"
  },
  forgotLink: {
    color: "#327C93",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "21px",
    textDecoration: "none"
  },
  modalForm: {
    maxWidth: "100%",
    flexBasis: "58%"
  },
  submitButton: {
    background: "#327C93",
    borderRadius: "5px",
    padding: "10px 30px",
    color: "#ffffff",

    "&:hover": {
      background: "#327C93",
      color: "#ffffff"
    }
  },
  cancelButton: {
    background: "#efefef",
    borderRadius: "5px",
    padding: "10px 30px",
    color: "#327C93",
    marginRight: "16px",
    "&:hover": {
      background: "#efefef",
      color: "#327C93"
    }
  },
  gmailbutton: {
    background: "#D44638",
    padding: " 6px 8px",
    textTransform: "capitalize",
    "&:hover": {
      background: "#D44638"
    }
  },
  githubbutton: {
    background: "#222222",
    padding: " 6px 8px",
    textTransform: "capitalize",
    "&:hover": {
      background: "#222222"
    }
  },
  signinButton: {
    margin: "16px 0px 18px 0px"
  },
  modalFooter: {
    margin: "32px 0px 18px 0px"
  },
  modalMainImage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
});

function LoginParticipantModal(props) {
  const { classes, history, closeLoginPopup } = props;

  const handleClose = () => {
    props.close();
  };
  return (
    <>
      <ThemeProvider theme={theme}>
        <Dialog
          open={props.data}
          onClose={props.close}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent className={classes.registermodal}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={5}
                sm={5}
                lg={5}
                className={classes.modalMainImage}
              >
                <img src={modal_img} alt="img" className={classes.modalImage} />
              </Grid>
              <Grid item xs={6} sm={6} className={classes.modalForm}>
                <Typography variant="subtitle1" className={classes.modalTitle}>
                  Login to HaaS
                </Typography>
                <p className={classes.modalInnerCont}>
                  Turpis vehicula eget felis amet, bibendum
                  <br /> et tristique et porttitor.
                </p>
                <LoginUserForm
                  checkStatus={props.checkStatus}
                  handleClose={handleClose}
                  history={history}
                  temp={props.temp}
                  isSignupPopupHandle={props.isSignupPopupHandle}
                  closeLoginPopup={closeLoginPopup}
                />
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    </>
  );
}

const mapStateToProps = state => {
  return {
    errors: state.errors
  };
};

export default connect(mapStateToProps, { push })(
  withStyles(styles)(LoginParticipantModal)
);
