import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
import { push } from "connected-react-router";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import { IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { logoutUser } from "../../Actions/authAction";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// import memberProfile from "../../Assets/User.png";
import devxlogo from "../../Assets/svgs/devx_logo.svg";
import { AccountCircle } from "@material-ui/icons";
import { NavLink } from "react-router-dom";
const styles = () => ({
  appBar: {
    // position: "relative"
    marginBottom: "20px"
  },
  toolbarTitle: {
    flex: 1
  },

  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9,
    backgroundSize: "30%"
  },
  mainToolbar: {
    display: "flex",
    justifyContent: "space-between",
    background: "#fff"
  },
  active_member: {
    border: "2px solid #327C93",
    borderRadius: "50px",
    padding: "4px",
    width: "64px",
    height: "64px"
  },
  buttonStyle: { marginLeft: "10px" },
  logoutStyle: { textDecoration: "none" },
  mainLink: {
    textDecoration: "none",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "18px",
    color: "#222222",
    marginRight: "20px",
    background: "transparent",
    textTransform: "capitalize",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none"
    }
  },
  profileIcon: {
    // color: "#fff",
    borderRadius: "50px",
    height: "30px",
    width: "40px "
  }
});

const theme = createMuiTheme({
  overrides: {
    MuiMenu: {
      paper: {
        top: "7% !important"
      }
    }
  }
});

function CandidateTopNavBar(props) {
  const { classes, isLoginPopupHandle, isSignupPopupHandle } = props;
  const [anchorEl, setAnchorEl] = useState(false);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(false);
  };
  const logout = () => {
    props.logoutUser();
    handleCloseMenu();
  };

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="fixed" color="default" className={classes.appBar}>
        {(!props.auth.isAuthenticated ||
          (props &&
            props.auth &&
            props.auth.hasRole !== "admin" &&
            props &&
            props.auth &&
            props.auth.hasRole !== "company")) && (
          <>
            <Toolbar className={classes.mainToolbar}>
              <div className={classes.logoSection}>
                <NavLink to="/challenges">
                  <img src={devxlogo} alt="" className={classes.devxlogo} />
                </NavLink>
              </div>
              <div>
                {!props.auth.isAuthenticated ? (
                  <div>
                    <Button
                      variant="contained"
                      className={classes.mainLink}
                      onClick={() => isSignupPopupHandle()}
                    >
                      Signup
                    </Button>
                    <Button
                      variant="contained"
                      className={classes.mainLink}
                      onClick={() => {
                        isLoginPopupHandle();
                      }}
                    >
                      Login
                    </Button>
                  </div>
                ) : (
                  <>
                    {/* <Button
                      className={classes.logoSection}
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                      <img
                        src={memberProfile}
                        alt="img"
                        className={classes.active_member}
                      />
                    </Button> */}
                    <IconButton
                      color="default"
                      // className={classes.logoSection}
                      onClick={handleClick}
                    >
                      {/* <img
                        src={user.profile_pic}
                        alt=""
                        width="100%"
                        height="200px"
                        className={classes.profileIcon}
                      /> */}
                      <AccountCircle className={classes.profileIcon} />
                    </IconButton>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={anchorEl}
                      onClose={handleCloseMenu}
                    >
                      <MenuItem
                        onClick={() => {
                          props.history.push("/participant/my-application");
                          handleCloseMenu();
                        }}
                      >
                        My challenges
                      </MenuItem>

                      <MenuItem
                        onClick={() => {
                          props.history.push("/participant/profile");
                          handleCloseMenu();
                        }}
                      >
                        Profile
                      </MenuItem>
                      {/* <MenuItem onClick={handleCloseMenu}>Settings</MenuItem> */}
                      {/* <Link to="" className={classes.logoutStyle}> */}
                      <MenuItem
                        component={NavLink}
                        to="/challenges"
                        onClick={logout}
                      >
                        Logout
                      </MenuItem>
                      {/* </Link> */}
                    </Menu>
                  </>
                )}
              </div>
            </Toolbar>
          </>
        )}
      </AppBar>
    </ThemeProvider>
  );
}

CandidateTopNavBar.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => {
  return {
    auth: state.auth,
    profile: state.profile,
    router: state.router
  };
};
export default connect(mapStateToProps, { logoutUser, push })(
  withStyles(styles)(CandidateTopNavBar)
);
