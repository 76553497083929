const commonStyles = {
  root: {
    width: "100%",
    position: "relative",
    boxShadow: "none"
  },
  formBox: {
    borderRadius: 10,
    border: "1px solid #EFEFEF",
    padding: 10,
    marginTop: 10
  },
  workHeader: {
    margin: "0 0 0 20px",
    alignSelf: "center"
  },
  closeIcon: {
    justifyContent: "flex-end",
    textAlign: "right",
    cursor: "pointer"
  },
  inputSection: {
    width: "100%"
  },
  label: {
    fontSize: 14,
    color: "#626262"
  },
  comment_section: {
    border: "1px solid #efefef",
    textAlign: "right",
    position: "relative",
    height: "80px",
    margin: "10px 0px 0px 0px",
    borderRadius: "10px"
  },
  input_field: {
    border: "1px solid #f5f5f5",
    height: "100%",
    position: "relative",
    width: "100%",
    borderRadius: "5px",
    fontFamily:
      "'-apple-system,BlinkMacSystemFont','Segoe UI','Roboto','Helvetica Neue','Arial,sans-serif','Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'",
    color: "black",
    fontSize: "14px",
    resize: "none",
    padding: "5px 0px 0px 10px"
  },
  cancelButton: {
    width: "113.33px",
    height: "46px",
    backgroundColor: "#F1F1F1",
    color: "#4CABD0",
    "&:hover": {
      backgroundColor: "#E2E2E2"
    }
  },
  submitButton: {
    width: "113.33px",
    height: "46px",
    marginRight: "20px"
  },
  addExpView: {
    marginTop: "10px",
    display: "flex",
    justifyContent: "flex-start",
    cursor: "pointer"
  },
  addExpViewMb: {
    marginTop: "10px",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer"
  },
  txtAddExp: {
    color: "#4CABD0",
    fontFamily: "Open Sans",
    fontSize: "12px",
    alignSelf: "center",
    cursor: "pointer"
  },
  formFooter: {
    marginTop: 30
  },
  icon: {
    margin: "0px 20px 0px 0px"
  },
  tag: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "19px",
    color: "#222222",
    borderRadius: "50px",
    backgroundColor: "#E7F8FF",
    padding: "5px 0px 5px 10px",
    display: "inline-block",
    fontFamily: "Open Sans",
    alignItems: "center",
    marginRight: 5
  },
  closeTag: {
    color: "#AFD8E8",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    top: 0
  },
  closeTagView: {
    justifyContent: "center",
    textAlign: "center",
    padding: "0px",
    paddingLeft: "0px",
    paddingRight: "0px",
    margin: "0px",
    minWidth: "38px"
  },

  // view profile
  skillsSection: {
    display: "flex",
    flexWrap: "wrap"
  },
  skillsData: {
    background: "#E7F8FF",
    borderRadius: "20px",
    padding: "5px 12px",
    margin: "8px 6px",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    textAlign: "center",
    color: "#222222"
  },
  subTitle: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    color: "#4CABD0",
    margin: "4px 6px"
  },
  content: {
    fontSize: "16px",
    lineHeight: "21.79px",
    color: "#222",
    margin: "4px 6px"
  },
  editButton: {
    background: "transparent",
    border: "none",
    color: "#CDCDCD",
    cursor: "pointer"
  },
  mainHeader: {
    display: "flex",
    justifyContent: "space-between"
  },
  workView: {
    marginBottom: "20px"
  },
  socialIcon: {
    borderRadius: "50%",
    width: "29px",
    marginRight: "18px",
    cursor: "pointer"
  }
};
export default commonStyles;
