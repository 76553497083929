import React from "react";
import axios from "axios";
import { withStyles, Grid, Button, ThemeProvider } from "@material-ui/core";
import { InputField, UploadFieldNew } from "../../../Components/Common/Form";
import TextareaField from "../../../Components/Common/Form/TextareaField";
import { Formik } from "formik";
import { Form } from "react-formik-ui";
import CustomButton from "../../../Components/Common/Button";
import { randomId } from "../../../Utils/StringFunctions";
import * as Yup from "yup";
import CommonStyles from "../../../Theme/commonStyles";

const styles = theme => ({
  root: {
    width: "auto",
    backgroundColor: "#fff",
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    boxShadow: "none",

    [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
      marginLeft: "auto",
      marginRight: "auto"
    }
  },

  uploadContainer: {
    justifyContent: "center",
    marginTop: "10px",
    display: "flex"
  },
  inputSection: CommonStyles.inputSection,
  bio: { marginTop: 10 },
  comment_section: CommonStyles.comment_section,
  input_field: CommonStyles.input_field,
  cancelButton: CommonStyles.cancelButton,
  submitButton: CommonStyles.submitButton,
  label: {
    fontSize: 14,
    color: "#626262"
  }
});

const phoneRegExp = /[1-9]{1}[0-9]{9}/;
class PersonalDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      contact_number: "",
      location: "",
      bio: " ",
      linkedin: " ",
      facebook: " ",
      github: " ",
      bannerUrl: `profile/${randomId(10)}/picture`,
      userData: {},
      user: {}
    };
  }
  async componentDidMount() {
    const userId = localStorage.getItem("user_id");
    const query = { user_id: userId };
    const user_profile = await axios.get(`/api/v2/userprofile`, {
      params: {
        filter: JSON.stringify(query)
      }
    });

    const user_me = await axios.get(`/api/user/me`);
    this.setState({
      user: user_me && user_me.data,
      userData: user_profile && user_profile.data
    });
  }

  submitPersonalInfo = async values => {
    const { userData } = this.state;
    let _id;
    if (userData && userData.length > 0) {
      _id = userData && userData[0]._id;
    }
    const userId = localStorage.getItem("user_id");
    values.isSubmit = true;
    var apiCall;
    if (userData.length <= 0) {
      values.user_id = userId;
      apiCall = await axios.post("/api/v2/userprofile", values);
    } else {
      apiCall = await axios.put(`/api/v2/userprofile/${_id}`, values);
    }
    if (apiCall && apiCall.status === 200) {
      this.props.viewProfileHandler(0, true);
    }
  };
  handleClose = () => {
    const { userData } = this.state;
    userData &&
      userData[0] &&
      userData[0].isSubmit &&
      this.props.viewProfileHandler(0);
  };
  PersonalSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Required"),

    contact_number: Yup.string()
      .matches(phoneRegExp, "Please enter valid number.")
      .max(10, "")
      .required("This field is required.")
  });
  onResetBannerUrl = () => {
    this.setState({
      bannerUrl: `profile/${randomId(10)}/picture`
    });
  };
  getInitialValues = () => {
    let user;
    if (this.state.userData.length <= 0)
      user = this.state.user && this.state.user.data;
    else user = this.state.userData[0];

    return {
      photo: (user && user.photo) || "",
      first_name: (user && user.first_name) || "",
      last_name: (user && user.last_name) || "",
      email: (user && user.email) || "",
      contact_number: (user && user.contact_number) || "",
      location: (user && user.location) || "",
      bio: (user && user.bio) || "",
      linkedin: (user && user.linkedin) || "",
      facebook: (user && user.facebook) || "",
      github: (user && user.github) || ""
    };
  };
  resetBannerUrl = () => {
    this.setState({ bannerUrl: `profile/${randomId(10)}/picture` });
  };
  render() {
    const { classes } = this.props;
    const { bannerUrl } = this.state;

    return (
      <ThemeProvider>
        <main className={classes.root}>
          <Formik
            initialValues={this.getInitialValues()}
            validationSchema={this.PersonalSchema}
            enableReinitialize
            onSubmit={values => this.submitPersonalInfo(values)}
          >
            {({
              values,
              errors,
              touched,
              setFieldTouched,
              setFieldValue,
              handleSubmit
            }) => (
              <Form
                onSubmit={handleSubmit}
                encType="multipart/form-data"
                autoComplete="off"
              >
                <div>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.uploadContainer}
                    >
                      <UploadFieldNew
                        label="Upload Photo"
                        id="photo"
                        onChange={setFieldValue}
                        onResetBannerUrl={this.onResetBannerUrl}
                        folder={bannerUrl}
                        name="photo"
                        value={values.photo}
                        isCircle
                      />
                    </Grid>

                    <Grid item lg={6} sm={6} className={classes.inputSection}>
                      <InputField
                        name="first_name"
                        label="First Name"
                        placeholder="Enter First Name"
                        disabled
                      />
                    </Grid>
                    <Grid item lg={6} sm={6} className={classes.inputSection}>
                      <InputField
                        name="last_name"
                        label="Last Name"
                        placeholder="Enter Last Name"
                        disabled
                      />
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs sm className={classes.inputSection}>
                        <InputField
                          name="contact_number"
                          label="Contact Detail"
                          placeholder="Enter Contact"
                        />
                      </Grid>
                      <Grid item xs sm className={classes.inputSection}>
                        <InputField
                          name="email"
                          label="Email Id"
                          placeholder="Enter Email Id"
                          disabled
                        />
                      </Grid>
                      <Grid item xs sm className={classes.inputSection}>
                        <InputField
                          name="location"
                          label="Location"
                          placeholder="Enter Location"
                        />
                      </Grid>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <label className={classes.label}>Bio</label>
                      <div className={classes.comment_section}>
                        <TextareaField
                          name="bio"
                          className={classes.input_field}
                          placeholder="Enter about yourself"
                        />
                      </div>
                    </Grid>
                    <Grid container spacing={2} className={classes.columnGrid}>
                      <Grid item xs sm className={classes.inputSection}>
                        <InputField
                          name="linkedin"
                          label="Linkedin"
                          placeholder="Enter linkedin"
                        />
                      </Grid>
                      <Grid item xs sm className={classes.inputSection}>
                        <InputField
                          name="facebook"
                          label="Facebook"
                          placeholder="Enter facebook"
                        />
                      </Grid>
                      <Grid item xs sm className={classes.inputSection}>
                        <InputField
                          name="github"
                          label="Github"
                          placeholder="Enter github"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </div>

                <Grid
                  item
                  container
                  xs={12}
                  sm={12}
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                  className={classes.formFooter}
                >
                  <Button
                    onClick={this.handleClose}
                    className={classes.cancelButton}
                  >
                    Cancel
                  </Button>
                  <CustomButton
                    type="submit"
                    label="Submit"
                    className={classes.submitButton}
                  ></CustomButton>
                </Grid>
              </Form>
            )}
          </Formik>
        </main>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(PersonalDetail);
