const capitalize = s => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};
export { capitalize };

// const spacecapitalize = s => {
//   if (typeof s !== "string") return "";
//   let string = s.replace(/_/g, " ");
//   let splitStr = string.toLowerCase().split(" ");
//   // eslint-disable-next-line no-plusplus
//   for (let i = 0; i < splitStr.length; i++) {
//     splitStr[i] =
//       splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
//   }
//   return splitStr.join(" ");
//   // return string.charAt(0).toUpperCase() + string.substr(1).toLowerCase();
// };
const spacecapitalize = s => {
  if (typeof s !== "string") return "";
  let string = s.replace(/_/g, " ");
  return string
    .split(" ")
    .map(w => w.substring(0, 1).toUpperCase() + w.substring(1))
    .join(" ");
};

function randomId(length) {
  return Math.round(
    Math.pow(36, length + 1) - Math.random() * Math.pow(36, length)
  )
    .toString(36)
    .slice(1);
}

export { spacecapitalize, randomId };
