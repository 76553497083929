import React from "react";
import axios from "axios";
import _map from "lodash/map";
import {
  withStyles,
  Grid,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
  InputAdornment,
  Button,
  Paper
} from "@material-ui/core";
import { InputField, DatePickerField } from "../../../Components/Common/Form";
import TextareaField from "../../../Components/Common/Form/TextareaField";
import { Formik, FieldArray } from "formik";
import { Form } from "react-formik-ui";
import CustomButton from "../../../Components/Common/Button";
import AddExperience from "../../../Assets/svgs/challenges/addExperience.svg";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import CommonStyles from "../../../Theme/commonStyles";
const styles = theme => ({
  root: {
    width: "100%",
    position: "relative",
    boxShadow: "none",
    height: "calc(100vh - 200px)",
    paddingBottom: "30px !important"
  },
  formBox: CommonStyles.formBox,
  workHeader: CommonStyles.workHeader,
  closeIcon: CommonStyles.closeIcon,
  inputSection: CommonStyles.inputSection,
  cancelButton: CommonStyles.cancelButton,
  submitButton: CommonStyles.submitButton,
  label: CommonStyles.label,
  comment_section: CommonStyles.comment_section,
  input_field: CommonStyles.input_field,
  formFooter: { marginTop: "30px", position: "absolute", bottom: 0 },
  addExpView: CommonStyles.addExpView,
  txtAddExp: CommonStyles.txtAddExp,
  icon: CommonStyles.icon
});
const theme = createMuiTheme({
  overrides: {
    MuiExpansionPanelDetails: {
      root: {
        padding: "8px",
        border: "none"
      }
    },
    MuiExpansionPanel: {
      rounded: {
        border: "none",
        borderBottom: "1px solid #EFEFEF",
        borderRadius: "0px"
      }
    },
    MuiExpansionPanelSummary: {
      root: {
        padding: "0 8px 0 8px"
      }
    },
    MuiSvgIcon: {
      root: { top: "0px", margin: "0px 10px 0px 10px" }
    }
  }
});

class Projects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      proj_name: "",
      proj_url: "",
      proj_desc: "",
      expanded: 0,
      userData: {}
    };
  }

  componentDidMount() {
    const userId = localStorage.getItem("user_id");
    const query = { user_id: userId };
    axios
      .get(`/api/v2/userprofile`, {
        params: {
          filter: JSON.stringify(query)
        }
      })
      .then(response => {
        this.setState({ userData: response.data });
      })
      .catch(error => {
        console.log("error,,,,,,", error);
      });
  }
  onSubmit = async values => {
    const user = this.state.userData[0];
    const _id = user && user._id;
    let projects = user.projects || [];

    projects = values.projects;

    const apiCall = await axios.put(`/api/v2/userprofile/${_id}`, {
      projects,
      isSubmit: true
    });
    // after click submit, open first view
    if (apiCall && apiCall.status === 200) {
      this.props.viewProfileHandler(0, true);
      if (projects.length > 1) this.setState({ expanded: 0 });
      else this.setState({ expanded: false });
    }
  };
  handleClose = () => {
    const { userData } = this.state;
    userData &&
      userData[0] &&
      userData[0].isSubmit &&
      this.props.viewProfileHandler(0);
  };
  handleChange = panel => (event, isExpanded) => {
    this.setState({ expanded: isExpanded ? panel : 0 });
  };

  newPush = push => {
    push({});

    var projects =
      this.state.userData[0] &&
      this.state.userData[0].projects &&
      this.state.userData[0].projects.length;
    this.setState({ expanded: projects });
  };
  render() {
    const { classes } = this.props;
    const { proj_name, proj_url, proj_desc, expanded, userData } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <Paper className={classes.root}>
          <Formik
            initialValues={{
              proj_name: "",
              proj_url: "",
              proj_desc: "",
              projects: (userData && userData[0] && userData[0].projects) || []
            }}
            enableReinitialize
            onSubmit={values => this.onSubmit(values)}
          >
            {({ values, handleSubmit }) => (
              <Form
                onSubmit={handleSubmit}
                className={classes.form}
                encType="multipart/form-data"
                autoComplete="off"
              >
                <div>
                  <FieldArray
                    name="projects"
                    render={({ insert, remove, push }) => (
                      <div>
                        {_map(values.projects, (v, i) => (
                          <Grid className={classes.formBox}>
                            <ExpansionPanel
                              expanded={expanded === i}
                              onChange={this.handleChange(i)}
                            >
                              <Grid
                                container
                                spacing={2}
                                justify="space-between"
                              >
                                <label className={classes.workHeader}>
                                  Project {i + 1}
                                </label>
                                <ExpansionPanelSummary
                                  expandIcon={<ExpandMoreIcon />}
                                >
                                  <label
                                    className={classes.closeIcon}
                                    onClick={() => remove(i)}
                                  >
                                    <DeleteIcon />
                                  </label>
                                </ExpansionPanelSummary>
                              </Grid>
                              <ExpansionPanelDetails>
                                <Grid container spacing={2}>
                                  <Grid
                                    item
                                    lg={6}
                                    sm={6}
                                    className={classes.inputSection}
                                  >
                                    <InputField
                                      name={`projects.${i}.proj_name`}
                                      label="Project Name"
                                      placeholder="Enter Project Name"
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    lg={6}
                                    sm={6}
                                    className={classes.inputSection}
                                  >
                                    <InputField
                                      name={`projects.${i}.proj_url`}
                                      label="Project Url"
                                      placeholder="Enter Project Url"
                                    />
                                  </Grid>
                                  <Grid item lg={12} xs={12}>
                                    <label className={classes.label}>
                                      Description
                                    </label>
                                    <div className={classes.comment_section}>
                                      <TextareaField
                                        name={`projects.${i}.proj_desc`}
                                        className={classes.input_field}
                                        placeholder="Enter Project Description"
                                      />
                                    </div>
                                  </Grid>
                                </Grid>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </Grid>
                        ))}
                        <div>
                          <Grid
                            item
                            className={classes.addExpView}
                            onClick={() => this.newPush(push)}
                          >
                            <img
                              src={AddExperience}
                              alt="AddProjects"
                              className={classes.icon}
                            />
                            <label className={classes.txtAddExp}>
                              Add Project
                            </label>
                          </Grid>
                        </div>
                      </div>
                    )}
                  />
                </div>

                <Grid
                  item
                  container
                  xs={12}
                  sm={12}
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                  className={classes.formFooter}
                >
                  <Button
                    onClick={this.handleClose}
                    className={classes.cancelButton}
                  >
                    Cancel
                  </Button>
                  <CustomButton
                    type="submit"
                    label="Submit"
                    className={classes.submitButton}
                  ></CustomButton>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(Projects);
