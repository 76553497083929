import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { Paper } from "@material-ui/core";
import { Edit2 } from "react-feather";
import moment from "moment";
import CommonStyles from "../../Theme/commonStyles";
import { spacecapitalize } from "../../Utils/StringFunctions";

const $quoteSym = " ";

const styles = () => ({
  root: {
    background: "#ffffff"
  },
  educationDetail: {
    marginBottom: "16px",
    background: "#ffffff",
    borderBottom: "1px solid #efefef",
    paddingBottom: "20px"
  },
  mainTitle: {
    fontSize: "18px",
    fontWeight: "500",
    lineHeight: "28px",
    color: "#222222",
    paddingBottom: "5px",
    position: "relative",
    marginBottom: "14px",
    "&:after": {
      top: "30px",
      left: "4px",
      width: "24px",
      borderBottom: "3px solid #4CABD0",
      content: `'${$quoteSym}'`,
      position: "absolute"
    }
  },
  skillsSection: CommonStyles.skillsSection,
  skillsData: CommonStyles.skillsData,
  subTitle: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "21px",
    color: "#4CABD0",
    margin: "4px 6px"
  },
  content: {
    color: " #222",
    margin: "4px 6px",
    fontSize: "14px",
    lineHeight: "21.79px"
  },
  experienceData: {
    background: "#ffffff",
    padding: "6px 0px 20px",
    borderBottom: "1px solid #efefef"
  },
  otherSection: {
    marginBottom: "16px",
    background: "#ffffff",
    borderBottom: "1px solid #efefef",
    paddingBottom: "20px"
  },
  WorkExperienceSec: {
    background: "#ffffff",
    marginBottom: "16px"
  },
  editButton: CommonStyles.editButton,
  mainHeader: CommonStyles.mainHeader,
  contentDetail: {
    marginBottom: "12px"
  },
  workView: CommonStyles.workView
});

function MbViewTechnicalSkills(props) {
  const { classes, userData } = props;

  const editPersonal = value => {
    props.viewProfileHandler(value);
  };
  return (
    <React.Fragment>
      <Paper className={classes.root}>
        <div className={classes.educationDetail}>
          <div className={classes.mainHeader}>
            <p className={classes.mainTitle}>Technical Skills</p>
            <button
              className={classes.editButton}
              onClick={() => editPersonal(1)}
            >
              <Edit2 />
            </button>
          </div>

          <div className={classes.skillsSection}>
            {userData &&
            userData.technical_skill &&
            userData.technical_skill.length > 0 ? (
              userData.technical_skill &&
              userData.technical_skill.map((u, i) => {
                return (
                  <p className={classes.skillsData} key={i}>
                    {u && u.label}
                  </p>
                );
              })
            ) : (
              <p className={classes.skillsData}>-</p>
            )}
          </div>
        </div>
        <div className={classes.WorkExperienceSec}>
          <div className={classes.mainHeader}>
            <p className={classes.mainTitle}>Work Experience</p>
            <button
              className={classes.editButton}
              onClick={() => editPersonal(2)}
            >
              <Edit2 />
            </button>
          </div>
          <div className={classes.experienceData}>
            {userData &&
            userData.work_experience &&
            userData.work_experience.length > 0 ? (
              userData.work_experience &&
              userData.work_experience.map((u, i) => {
                return (
                  <div className={classes.workView} key={i}>
                    <p className={classes.subTitle}>
                      {u && u.job_title ? spacecapitalize(u.job_title) : "-"}
                    </p>
                    <p className={classes.content}>
                      {u && u.company_name
                        ? spacecapitalize(u.company_name)
                        : "-"}
                      {" | "}
                      {u && u.location ? spacecapitalize(u.location) : "-"}
                    </p>
                    <p className={classes.content}>
                      {/* Jul 2018 to Present (2 years 3 months) */}
                      {u && u.from_time
                        ? moment(u && u.from_time).format("MMM YYYY")
                        : "-"}
                      {" to "}
                      {u && u.to_time
                        ? moment(u && u.to_time).format("MMM YYYY")
                        : "-"}{" "}
                      {moment(u && u.to_time).diff(
                        moment(u && u.from_time),
                        "month"
                      )}
                    </p>
                    <p className={classes.content}>
                      {u && u.description ? u.description : "-"}
                    </p>
                  </div>
                );
              })
            ) : (
              <p className={classes.workView}>-</p>
            )}
          </div>
        </div>

        <div className={classes.otherSection}>
          <div className={classes.mainHeader}>
            <p className={classes.mainTitle}>Projects</p>
            <button
              className={classes.editButton}
              onClick={() => editPersonal(3)}
            >
              <Edit2 />
            </button>
          </div>
          {userData && userData.projects && userData.projects.length > 0 ? (
            userData.projects &&
            userData.projects.map((u, i) => {
              return (
                <div className={classes.workView} key={i}>
                  <p className={classes.subTitle}>
                    {spacecapitalize(u && u.proj_name)}
                  </p>
                  <p className={classes.content}>{u && u.proj_url}</p>
                  <p className={classes.content}>{u && u.proj_desc}</p>
                </div>
              );
            })
          ) : (
            <p className={classes.workView}>-</p>
          )}
        </div>
        <div className={classes.otherSection}>
          <div className={classes.mainHeader}>
            <p className={classes.mainTitle}>Educational Details</p>
            <button
              className={classes.editButton}
              onClick={() => editPersonal(4)}
            >
              <Edit2 />
            </button>
          </div>
          {userData &&
          userData.education_details &&
          userData.education_details.length > 0 ? (
            userData.education_details &&
            userData.education_details.map((u, i) => {
              return (
                <div className={classes.workView} key={i}>
                  <p className={classes.subTitle}>
                    {spacecapitalize(u && u.degree)}
                  </p>
                  <p className={classes.content}>
                    {spacecapitalize(u && u.collegeName)}{" "}
                  </p>
                  <p className={classes.content}>{u && u.completion} </p>
                </div>
              );
            })
          ) : (
            <p className={classes.workView}>-</p>
          )}
        </div>
        <div className={classes.otherSection}>
          <div className={classes.mainHeader}>
            <p className={classes.mainTitle}>Achievements</p>
            <button
              className={classes.editButton}
              onClick={() => editPersonal(5)}
            >
              <Edit2 />
            </button>
          </div>

          {userData &&
          userData.achievements &&
          userData.achievements.length > 0 ? (
            userData.achievements &&
            userData.achievements.map((u, i) => {
              return (
                <div className={classes.workView} key={i}>
                  <p className={classes.subTitle}>
                    {spacecapitalize(u && u.title)}
                  </p>
                  <p className={classes.content}>{u && u.description}</p>
                </div>
              );
            })
          ) : (
            <p className={classes.workView}>-</p>
          )}
        </div>
      </Paper>
    </React.Fragment>
  );
}

const mapStateToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles)(MbViewTechnicalSkills));
