import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import NoDataFound from "../../Components/Common/NoDataFound";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import JSONSchemaForm from "@rjsf/material-ui";
import {
  TextareaWidget,
  TextWidget,
  fileWidget
} from "./ApplicationFomComponents";
import { Button, Paper } from "@material-ui/core";
import {
  getParticipantApplication,
  updateApplicationFormData
} from "../../Actions/applicationActions";
import _get from "lodash/get";
const drawerWidth = 240;

const styles = theme => ({
  root: {
    width: `calc(100% - ${drawerWidth}px - 80px)`,
    width: "40%",
    padding: "14px 40px",
    position: "relative",
    paddingTop: "5%",
    left: "60px",
    margin: "auto",
    boxShadow: "none"
  },
  table: {
    minWidth: 600
  },
  maineader: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "19px",
    color: "#222222",
    margin: "42px 20px 12px 0px"
  },
  tableBody: {
    border: "1px solid #EFEFEF",
    borderRadius: "0px 10px"
  },
  form: {
    textAlign: "rignt",
    padding: "20px",
    boxShadow: "0 0 20px 0 rgba(0,0,0,0.05)",
    borderRadius: "20px",
    background: "#FFF"
  },
  button: {
    display: "flex",
    justifyContent: "flex-end"
  },
  nodataPaper: {
    marginTop: "10%",
    position: "relative",
    margin: "auto",
    boxShadow: "none",
    paddingBottom: "3%"
  }
});

function ApplicationTable(props) {
  const { classes } = props;
  const [applicationFormSchema, setApplicationFormSchema] = useState({});
  const [applicationFormUiSchema, setApplicationFormUiSchema] = useState({});
  const [participantAllApplication, setparticipantAllApplication] = useState(
    []
  );
  const application = participantAllApplication.filter(u => {
    return u.application.event === props.match.params.id;
  });
  const application_id = _get(application[0], `application._id`);
  const getEventData = async () => {
    const url = `/api/v2/events/${props.match.params.id}`;
    const eventResponse = await axios.get(url);
    setApplicationFormSchema(eventResponse.data.applicationFormSchema);
    setApplicationFormUiSchema(eventResponse.data.applicationFormUiSchema);
  };
  //SET APPLICATIONS
  useEffect(() => {
    props.getParticipantApplication();
    getEventData();
    // formdata(url);
  }, []);

  useEffect(() => {
    setparticipantAllApplication(props.participantAllApplication);
  }, [props.participantAllApplication]);
  const myWidgets = {
    textarea: TextareaWidget,
    TextWidget: TextWidget,
    FileWidget: fileWidget
  };
  const formdata = _get(application[0], `application.applicationform.formdata`);
  const isUpdate = formdata === undefined ? false : true;
  const dataSubmit = async ({ formData }) => {
    const data = {
      formdata: formData
    };
    const values = {
      applicationform: data,
      isfillform: true
    };
    props.updateApplicationFormData(application_id, values, isUpdate);
    props.getParticipantApplication();
  };

  return (
    <React.Fragment>
      {applicationFormSchema === undefined ? (
        <Paper className={classes.nodataPaper}>
          <NoDataFound />
        </Paper>
      ) : (
        <Paper className={classes.root}>
          <div className={classes.form}>
            <JSONSchemaForm
              schema={applicationFormSchema}
              uiSchema={applicationFormUiSchema}
              widgets={myWidgets}
              showErrorList={false}
              formData={formdata}
              // liveValidate
              onSubmit={dataSubmit}
            >
              <div className={classes.button}>
                <Button type="submit" variant="contained" color="primary">
                  {_get(application[0], `application.isfillform`, false)
                    ? "Update"
                    : "Submit"}
                </Button>
              </div>
            </JSONSchemaForm>
          </div>
        </Paper>
      )}
    </React.Fragment>
  );
}
const mapStateToProps = state => {
  return {
    participantAllApplication:
      state.applications.participantAllApplication || {}
  };
};

export default connect(mapStateToProps, {
  getParticipantApplication,
  updateApplicationFormData
})(withStyles(styles)(ApplicationTable));
