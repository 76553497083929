import React from "react";
import { Paper, Tabs, Tab, Typography, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import DOMPurify from "dompurify";

import _get from "lodash/get";

const styles = () => ({
  root: {
    paddingTop: "18px",
    paddingBottom: "15px",
    overflow: "scroll",
    backgroundColor: "#FCFDFE"
  },
  noCoworker: {
    padding: "24px 24px 11px",
    color: "lightgrey"
  },
  layout: {
    marginTop: "19px !important",
    padding: "20px 25px"
  },
  paper: {
    backgroundColor: "#fcfdfe"
  },
  tabText: {
    textTransform: "capitalize"
  },
  eventOverview: {
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "28px",
    textAlign: "center",
    color: "#222222"
  }
});
const AntTabs = withStyles({
  indicator: {
    color: "#f5f5f5",
    backgroundColor: " #f5f5f5",
    top: "38px"
  }
})(Tabs);

const AntTab = withStyles(() => ({
  selected: {
    color: " #327C93 !important",
    background: "#F5F5F5",
    borderRadius: "50px",
    marginLeft: "10px"
  }
}))(props => <Tab disableRipple {...props} />);

function TabContainer(props) {
  return (
    <Typography
      component="div"
      style={{ padding: "0px 14px", marginTop: " 14px" }}
    >
      {props.children}
    </Typography>
  );
}

function ViewaEventTabs(props) {
  const { classes, data } = props;
  const [value, setValue] = React.useState(0);
  // console.log("data && data[0].html_file", data && data[0].html_file);
  function handleChange(event, newValue) {
    setValue(newValue);
  }
  const currentTabHTMLPage = _get(data, `${value}.htmlContent`, "");
  const sanitizer = DOMPurify.sanitize;

  return (
    <Paper className={classes.root}>
      {data && data.length > 0 ? (
        <>
          <AntTabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
            // key={i}
          >
            {data.map((u, i) => {
              return (
                <AntTab
                  className={classes.tabText}
                  label={u.name}
                  key={i}
                  onClick={() => {
                    // sethtmlString(u.html_file);
                    setValue(i);
                  }}
                />
              );
            })}
          </AntTabs>
        </>
      ) : (
        <>
          <AntTab className={classes.tabText} label="No Pages" />
        </>
      )}
      <TabContainer>
        <div
          dangerouslySetInnerHTML={{ __html: sanitizer(currentTabHTMLPage) }}
        />
      </TabContainer>
    </Paper>
  );
}

const mapStateToProps = state => {
  return {
    errors: state.errors
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(ViewaEventTabs));
