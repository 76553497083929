import React, { Component, Suspense } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import jwt_decode from "jwt-decode";
import setAuthToken from "./Utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./Actions/authAction";
import { getCities } from "./Actions/CityActions";

import { clearCurrentProfile } from "./Actions/profileAction";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import theme from "./Theme";
import ToastProvider from "./ToastManager";
import PrivateRoute from "./Components/Common/PrivateRoute";
import Participant from "./Components/Common/ParticipantPrivateRoute";
import Axios from "axios";
import { ConnectedRouter } from "connected-react-router";
import store, { history } from "./store";
import checkPermission from "./Utils/CheckPermissions";
import getRole from "./Utils/CheckPermissions";
import CandidateTopNavBar from "./Screens/Landing/CandidateTopNavBar";
import UserAgent from "./Utils/userAgent";
import Spinner from "./Components/Common/Spinner";
// const store = configureStore(/* provide initial state if any */);
import LoginEventDrawer from "./Screens/MobileScreen/LoginEventDrawer";
// const Mychallenges = React.lazy(() =>
//   import("./Screens/MobileScreen/Mychallenges")
// );
const Teaminvitation = React.lazy(() => import("./Components/TeamInvitation"));
// const MyTeam = React.lazy(() => import("./Screens/MobileScreen/MyTeam"));
// const LoginPopup = React.lazy(() =>
//   import("../../haas-web/src/Screens/Landing/LoginParticipantModal")
// );
const LoginPopupHtml = React.lazy(() =>
  import("../../haas-web/src/Screens/Landing/LoginParticipantModalHtml1")
);

// const SignupPopup = React.lazy(() =>
//   import("../../haas-web/src/Screens/Landing/SignupParticipantModal")
// );
const SignupPopupHtml = React.lazy(() =>
  import("../../haas-web/src/Screens/Landing/SignupParticipantModalHtml")
);

const ViewaEventNotParticipated = React.lazy(() =>
  import("./Screens/EventPage/ViewaEventNotParticipated")
);
const MobileChallenges = React.lazy(() =>
  import("./Screens/MobileScreen/MobileChallengesPage")
);

const AllChallegesMobile = React.lazy(() =>
  import("./Screens/MobileScreen/AllChallenges")
);
const NewEventForm = React.lazy(() =>
  import("./Screens/NewEventForm/NewEventForm")
);
const ViewaEvent = React.lazy(() => import("./Screens/EventPage/ViewaEvent"));
// interceptor();
let Theme = createMuiTheme(theme);

const Landing = React.lazy(() => import("./Screens/Landing"));

const ChallengesPage = React.lazy(() =>
  import("./Screens/ChallengesPage/ChallengesPage")
);
const CompaniesPage = React.lazy(() => import("./Screens/CompaniesPage"));
const AdminEvents = React.lazy(() => import("./Screens/EventPage/AdminEvents"));
const EventPage = React.lazy(() => import("./Screens/EventPage"));
const AdminApplications = React.lazy(() =>
  import("./Screens/Applications/AdminApplications")
);
const ViewApplication = React.lazy(() =>
  import("./Screens/Applications/ViewApplication")
);
const AdminAssociates = React.lazy(() =>
  import("./Screens/Associates/AdminAssociates")
);
const AdminApplicants = React.lazy(() =>
  import("././Screens/Applicants/AdminApplicants")
);
const AdminPartners = React.lazy(() =>
  import("./Screens/Partners/AdminPartners")
);
const ImageLibrary = React.lazy(() =>
  import("./Screens/ImageLibrary/ImageLibrary")
);
const PartnerForm = React.lazy(() => import("./Screens/Partners/PartnerForm"));
const AssociateForm = React.lazy(() =>
  import("./Screens/Associates/AssociateForm")
);
const ViewaApplicants = React.lazy(() =>
  import("./Screens/Applicants/ViewaApplicants")
);
const AdminTemplates = React.lazy(() =>
  import("./Screens/Templates/AdminTemplates")
);
const PageDesigner = React.lazy(() =>
  import("./Components/PageDesigner/PageDesignerWrapper")
);
const EventMeta = React.lazy(() => import("./Screens/EventPage/EventMeta"));
const CompanyPage = React.lazy(() =>
  import("./Screens/CompanyPage/CompanyPage")
);
const ViewaPartner = React.lazy(() =>
  import("./Screens/Partners/ViewaPartner")
);
const ViewaAssociates = React.lazy(() =>
  import("./Screens/Associates/ViewaAssociates")
);
const ViewChallenge = React.lazy(() =>
  import("./Screens/ChallengesPage/ViewaChallenge")
);

const ComponentDemo = React.lazy(() => import("./Screens/ComponentDemo"));
const Resetpassword = React.lazy(() => import("./Screens/Login/Resetpassword"));
// const Resetpasswordmobile = React.lazy(() =>
//   import("./Screens/MobileScreen/Resetpasswordmobile")
// );
const CompanyForm = React.lazy(() => import("./Screens/CompanyForm"));
const ApplicationForm = React.lazy(() => import("./Screens/ApplicationForm"));
const Dashboard = React.lazy(() => import("./Screens/Dashboard"));
const ProfileForm = React.lazy(() => import("./Screens/ProfileForm"));
const role = getRole;

const ForgetPassword = React.lazy(() =>
  import("./Screens/Login/Forgotpassword")
);

const styles = () => ({});

// Check for token and set current user
if (localStorage.jwtToken) {
  setAuthToken(localStorage.jwtToken);
  const decoded = jwt_decode(localStorage.jwtToken);
  store.dispatch(setCurrentUser(decoded));
  store.dispatch(getCities());
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());
    store.dispatch(clearCurrentProfile());
    window.location.href = "/";
  }
}

const Challenges = ({ mt }) => {
  if (mt) {
    return <AllChallegesMobile history={history} />;
  } else {
    return <ChallengesPage history={history} />;
  }
};

const Resetpasswordcomponent = () => {
  return <Resetpassword history={history} />;
};

class App extends Component {
  state = {
    mobileOpen: false,
    isLoginOpen: false,
    isSignupOpen: false,
    loading: false,
    flag: false
  };

  componentDidMount() {
    const githubParam = history.location.search;
    const code = githubParam.replace("?code=", "");
    const access_code = history.location.hash;
    const token = access_code
      .replace("#access_token=", "")
      .replace(
        "token_type=Bearer&expires_in=3599&scope=email%20profile%20https://www.googleapis.com/auth/userinfo.profile%20https://www.googleapis.com/auth/userinfo.email%20openid&authuser=0&prompt=none",
        ""
      );

    if (code) {
      this.setState({ loading: true });
      Axios.post("/api/oauth/github", {
        type: "github",
        code: code
      }).then(res => {
        this.setState({ loading: false });
        window.location.href = "/challenges";
        const response = res.data.data;
        this.setState({ jwtToken: response });
        localStorage.setItem("jwtToken", this.state.jwtToken.token);
        const decoded = jwt_decode(localStorage.jwtToken);
        localStorage.setItem("role", decoded.role);
        localStorage.setItem("user_id", decoded.id);
        // this.setState({loading:false})
      });
    }

    if (token) {
      this.setState({ loading: true });
      Axios.post("/api/oauth/google", {
        type: "google",
        access_token: token
      }).then(res => {
        this.setState({ loading: false });
        window.location.href = "/challenges";
        const response = res.data.data;
        this.setState({ jwtToken: response });
        console.log("in token", response);
        localStorage.setItem("jwtToken", this.state.jwtToken.token);
        const decoded = jwt_decode(localStorage.jwtToken);
        localStorage.setItem("role", decoded.role);
        localStorage.setItem("user_id", decoded.id);
        // this.setState({loading:false})
      });
    }
  }

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  isLoginPopupHandle = data => {
    this.setState({ isLoginOpen: true });
  };
  isSignupPopupHandle = data => {
    this.setState({ isSignupOpen: true });
  };

  handleClose = () => {
    this.setState({ isLoginOpen: false, isSignupOpen: false });
  };

  closeLoginPopup = () => {
    this.setState({ isLoginOpen: false });
  };

  render() {
    const mt = UserAgent();
    const state = store.getState();
    if (this.state.loading) {
      console.log("load");
      return (
        <ToastProvider>
          <div>
            <Spinner />
          </div>
        </ToastProvider>
      );
    }
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <MuiThemeProvider theme={Theme}>
          <Provider store={store}>
            <ConnectedRouter history={history}>
              <ToastProvider>
                <Suspense
                  fallback={
                    <div>
                      <Spinner />
                    </div>
                  }
                >
                  {mt ? (
                    <LoginEventDrawer
                      openModal={this.state.isLoginOpen}
                      toggleDrawer={this.handleClose}
                      toggleTeamStep1Drawer={this.handleClose}
                      // openTeamModal={this.handleClose}
                    />
                  ) : (
                    <>
                      <LoginPopupHtml
                        close={this.handleClose}
                        data={this.state.isLoginOpen}
                        temp={false}
                        signupModalClose={this.handleClose}
                        isSignupPopupHandle={this.isSignupPopupHandle}
                        history={history}
                        closeLoginPopup={this.closeLoginPopup}
                      />
                      <SignupPopupHtml
                        signupModalClose={this.handleClose}
                        signupModalOpen={this.state.isSignupOpen}
                        isLoginPopupHandle={this.isLoginPopupHandle}
                        history={history}
                      />
                    </>
                  )}
                  {/* {state && state.auth && !state.auth.isAuthenticated && ( */}

                  {!mt && (
                    <CandidateTopNavBar
                      history={history}
                      isAuthenticated={state.auth.isAuthenticated}
                      isLoginOpen={this.state.isLoginOpen}
                      isSignupOpen={this.state.isSignupOpen}
                      isLoginPopupHandle={this.isLoginPopupHandle}
                      isSignupPopupHandle={this.isSignupPopupHandle}
                    />
                  )}

                  {/* )} */}
                  <Switch>
                    <Route exact path="/" component={Landing} />
                    <Route
                      exact
                      path="/pagedesigner"
                      component={PageDesigner}
                    />

                    <Route
                      exact
                      path="/pagedesigner/:type/:id"
                      component={PageDesigner}
                    />
                    <Route exact path="/forget" component={ForgetPassword} />
                    <Route
                      exact
                      path="/reset/:id"
                      component={Resetpasswordcomponent}
                    />
                    <Route
                      path="/challenges"
                      render={() => <Challenges mt={mt} />}
                    />
                    <Route
                      path="/challenges/:id"
                      component={MobileChallenges}
                    />
                    <Route
                      exact
                      path="/mychallenges/team-invitation/:status/:eventid/:applicationid/:email"
                      component={Teaminvitation}
                      // render={() => <Challenges mt={mt} />}
                    />
                    <PrivateRoute
                      path="/event/:id"
                      component={ViewaEvent}
                      title="View Event"
                    />
                    <Route exact path="/event-page" component={EventPage} />
                    <PrivateRoute
                      exact
                      path="/dashboard"
                      component={Dashboard}
                      title="Dashboard"
                    />
                    <PrivateRoute
                      exact
                      path="/events"
                      component={AdminEvents}
                      title="Events"
                    />
                    <PrivateRoute
                      exact
                      path="/demo"
                      component={ComponentDemo}
                    />
                    <PrivateRoute
                      exact
                      path="/events/add"
                      component={NewEventForm}
                      title="Add Event"
                    />
                    <PrivateRoute
                      exact
                      path="/events/edit/:id"
                      component={NewEventForm}
                      title="Edit Event"
                    />
                    <PrivateRoute
                      exact
                      path="/company"
                      component={CompaniesPage}
                      title="Companies"
                    />
                    <PrivateRoute
                      exact
                      path="/applicants"
                      component={AdminApplicants}
                      title="Applicants"
                    />
                    <PrivateRoute
                      exact
                      path="/partners"
                      component={AdminPartners}
                      title="Partners"
                    />
                    {checkPermission("COMPANY_VIEW") && (
                      <PrivateRoute
                        exact
                        path="/imagelibrary"
                        component={ImageLibrary}
                        title="Image Library"
                      />
                    )}
                    <PrivateRoute
                      exact
                      path="/partners/add"
                      component={PartnerForm}
                      title="Add Partner"
                    />
                    <PrivateRoute
                      exact
                      path="/partner/edit/:id"
                      component={PartnerForm}
                      title="Edit Partner"
                    />
                    <PrivateRoute
                      path="/partners/:id"
                      component={ViewaPartner}
                      title="View Partner"
                    />
                    <PrivateRoute
                      exact
                      path="/companies/:id"
                      component={CompanyPage}
                      title="View Company"
                    />
                    <PrivateRoute
                      exact
                      path="/company/add"
                      component={CompanyForm}
                      title="Add Company"
                    />
                    <PrivateRoute
                      path="/company/:id"
                      component={CompanyForm}
                      title="Edit Company"
                    />
                    <PrivateRoute
                      path="/applications"
                      component={AdminApplications}
                      title="Applications"
                    />
                    <PrivateRoute
                      path="/application/:id"
                      component={ViewApplication}
                      title=" View Application"
                    />
                    <Route
                      path="/add-application/:id"
                      component={ApplicationForm}
                      title="Edit Application"
                    />
                    <PrivateRoute
                      exact
                      path="/associates"
                      component={AdminAssociates}
                      title="Associates"
                    />
                    <PrivateRoute
                      exact
                      path="/associates/add"
                      component={AssociateForm}
                      title="Add Associate"
                    />
                    <PrivateRoute
                      exact
                      path="/associate/edit/:id"
                      component={AssociateForm}
                      title="Edit Associate"
                    />
                    <PrivateRoute
                      path="/associates/:id"
                      component={ViewaAssociates}
                      title="View Associate"
                    />
                    <PrivateRoute
                      path="/associate/:id"
                      component={AssociateForm}
                      title="Edit Associate"
                    />
                    <PrivateRoute
                      path="/applicants/:id"
                      component={ViewaApplicants}
                      title="View a Applicants"
                    />
                    {(role === "admin" || "company") && (
                      <PrivateRoute
                        path="/templates"
                        component={AdminTemplates}
                        title="Templates"
                      />
                    )}

                    <PrivateRoute
                      path="/event-detail"
                      component={ViewaEventNotParticipated}
                      title="View Event"
                    />

                    <PrivateRoute
                      path="/templates"
                      component={AdminTemplates}
                      title="Templates"
                    />
                    <PrivateRoute
                      path="/event-meta"
                      component={EventMeta}
                      title="Event Meta"
                    />
                    <Route
                      path="/challenge/:id"
                      component={ViewChallenge}
                      // title="Event Meta"
                    />

                    {(role === "admin" || "company") && (
                      <PrivateRoute
                        path="/templates"
                        component={AdminTemplates}
                        title="Templates"
                      />
                    )}

                    <Route exact path="/add-profile" component={ProfileForm} />
                    {/* Participated User Route   */}

                    {/* Not Participated in Event User : event detail Path */}

                    <Route path="/participant" component={Participant} />

                    {/* MOBILE ROUTE */}
                    <Route
                      path="/event-challenges"
                      component={MobileChallenges}
                    />
                  </Switch>
                </Suspense>
              </ToastProvider>
            </ConnectedRouter>
          </Provider>
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    );
  }
}

export default withStyles(styles, { withTheme: true })(App);
