import React, { useState, useEffect } from "react";
import { withStyles, Button } from "@material-ui/core";
import { connect } from "react-redux";
import {
  TextareaWidget,
  TextWidget,
  fileWidget
} from "../EventPage/ApplicationFomComponents";
import {
  getParticipantApplication,
  updateApplicationFormData
} from "../../Actions/applicationActions";
import NoDataFound from "../../Components/Common/NoDataFound";
import axios from "axios";
import JSONSchemaForm from "@rjsf/material-ui";
import Paper from "@material-ui/core/Paper";
import PubSub from "../../Utils/pubsup";
import { spacecapitalize } from "../../Utils/StringFunctions";
import _get from "lodash/get";
const styles = () => ({
  root: {
    paddingBottom: "15px",
    overflow: "scroll"
  },
  form: {
    textAlign: "rignt",
    padding: "20px"
  },
  noDataContainer: {
    textAlign: "center",
    marginTop: "100px",
    marginBottom: "200px"
  },
  button: {
    display: "flex",
    justifyContent: "flex-end"
  },
  cancelButton: {
    marginRight: "20px",
    backgroundColor: "#F1F1F1",
    color: "#4D7CFE",
    "&:hover": {
      backgroundColor: "#E2E2E2"
    }
  }
});

function ApplicationFormByEvent(props) {
  const { classes } = props;
  const [applicationFormSchema, setApplicationFormSchema] = useState({});
  const [applicationFormUiSchema, setApplicationFormUiSchema] = useState({});
  const [participantAllApplication, setparticipantAllApplication] = useState(
    []
  );
  const getdata = async () => {
    const url = `/api/application/mychallenges`;
    await axios.get(url).then(res => {
      setparticipantAllApplication(res.data.data);
    });
  };
  const application =
    participantAllApplication &&
    participantAllApplication.filter(u => {
      return u.application.event === props.match.params.id;
    });
  const application_id = application && _get(application[0], `application._id`);

  const getEventData = async () => {
    const url = `/api/v2/events/${props.match.params.id}`;
    const eventResponse = await axios.get(url);
    PubSub.send(
      "onNavBarTitleChange",
      spacecapitalize(_get(eventResponse, "data.event_name"))
    );
    setApplicationFormSchema(eventResponse.data.applicationFormSchema);
    setApplicationFormUiSchema(eventResponse.data.applicationFormUiSchema);
  };
  useEffect(() => {
    props.getParticipantApplication();
    getEventData();
    getdata();
  }, []);

  useEffect(() => {
    setparticipantAllApplication(props.participantAllApplication);
  }, [props.participantAllApplication]);
  const myWidgets = {
    textarea: TextareaWidget,
    TextWidget: TextWidget,
    FileWidget: fileWidget
  };
  const formdata =
    application && _get(application[0], `application.applicationform.formdata`);
  const isUpdate = formdata === undefined ? false : true;
  const dataSubmit = async ({ formData }) => {
    const data = {
      formdata: formData
    };
    const values = {
      applicationform: data,
      isfillform: true
    };
    props.updateApplicationFormData(application_id, values, isUpdate);
    getdata();
  };

  return (
    <Paper className={classes.root}>
      {applicationFormSchema === undefined ? (
        <div className={classes.noDataContainer}>
          <NoDataFound />
        </div>
      ) : (
        <div className={classes.form}>
          <JSONSchemaForm
            schema={applicationFormSchema}
            uiSchema={applicationFormUiSchema}
            widgets={myWidgets}
            showErrorList={false}
            // liveValidate
            formData={formdata}
            onSubmit={dataSubmit}
          >
            <div className={classes.button}>
              <Button
                className={classes.cancelButton}
                onClick={() => {
                  props.history.push("/participant/my-application");
                }}
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                {application &&
                _get(application[0], `application.isfillform`, false)
                  ? "Update"
                  : "Submit"}
              </Button>
            </div>
          </JSONSchemaForm>
        </div>
      )}
    </Paper>
  );
}
const mapStateToProps = state => {
  return {
    errors: state.errors,
    application: state.applications.participantAllApplication || {}
  };
};

export default connect(mapStateToProps, {
  getParticipantApplication,
  updateApplicationFormData
})(withStyles(styles)(ApplicationFormByEvent));
