import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import { Table } from "@material-ui/core/";
import TableBody from "@material-ui/core/TableBody";
import Cell from "../../Components/Common/Table/TableCell";
import StatusCell from "../../Components/Common/Table/StatusCell";
import Header from "../../Components/Common/Table/TableHeader";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { getParticipantApplication } from "../../Actions/applicationActions";
import { spacecapitalize } from "../../Utils/StringFunctions";
import moment from "moment";
import NoDataFound from "../../Components/Common/NoDataFound";
import _concate from "lodash/concat";
import _uniqBy from "lodash/uniqBy";
// const drawerWidth = 240;

const styles = theme => ({
  root: {
    // width: `calc(100% - ${drawerWidth}px - 80px)`,
    // width: "94%",
    // marginTop: theme.spacing.unit * 3,
    overflowY: "auto",
    padding: "14px 40px",
    paddingTop: "10%",
    position: "relative",
    minHeight: "74vh",
    zIndex: 1
    // left: "240px"
  },
  table: {
    minWidth: 600
  },
  maineader: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "19px",
    color: "#222222",
    margin: "42px 20px 12px 0px"
  },
  tableBody: {
    border: "1px solid #EFEFEF",
    borderRadius: "0px 10px"
  },
  cursor_point: {
    cursor: "pointer"
  },
  nodataContainer: { textAlign: "center", marginTop: "-75px" }
});

// let id = 0;
// function createData(name, teamname, member, application_date, status, date) {
//   id += 1;
//   return { id, name, teamname, member, application_date, status, date };
// }

const theme = createMuiTheme({
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow: "none"
      }
    },
    MuiTableCell: {
      body: {
        color: "#000000",
        fontSize: "14px",
        lineHeight: "19px"
      },
      head: {
        fontSize: "12px",
        lineHeight: "16px",
        color: "#626262"
      }
    },
    MuiSvgIcon: {
      root: {
        position: "relative",
        top: "10px",
        rigth: "-10px"
      }
    }
  }
});

function ApplicationTable(props) {
  const { classes } = props;
  const [participantAllApplication, setparticipantAllApplication] = useState(
    []
  );

  //SET APPLICATIONS
  useEffect(() => {
    props.getParticipantApplication();
  }, []);

  useEffect(() => {
    setparticipantAllApplication(props.participantAllApplication);
  }, [props.participantAllApplication]);

  const handleClick = id => {
    props.history.push(`/participant/overview/${id}`);
  };

  return (
    <ThemeProvider theme={theme}>
      <Paper className={classes.root}>
        {participantAllApplication.length > 0 && (
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <Header>Challenge Name</Header>
                <Header align="left">Company Name</Header>
                <Header align="left">Team name</Header>
                <Header align="left">Members</Header>
                <Header align="left">Application Date</Header>
                <Header align="left">Status</Header>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {participantAllApplication.map(row => (
                <TableRow key={row._id}>
                  <Cell
                    component="th"
                    scope="row"
                    cursor
                    color
                    onClick={() => handleClick(row.event && row.event._id)}
                  >
                    {spacecapitalize(row.event && row.event.event_name)}
                  </Cell>

                  <Cell align="left">
                    {spacecapitalize(
                      row &&
                        row.event &&
                        row.event.company &&
                        row.event.company.company_name
                    )}
                  </Cell>
                  <Cell align="left">
                    {spacecapitalize(
                      row.application && row.application.team_name
                    )}
                  </Cell>
                  <Cell align="left">
                    {
                      _uniqBy(
                        _concate(
                          row.application.active_members,
                          row.application.invitations
                        ),
                        "email"
                      ).length
                    }
                  </Cell>
                  <Cell align="left">
                    {moment(row.event && row.application.createdAt).format(
                      "DD/MM/YYYY"
                    )}
                  </Cell>
                  <StatusCell
                    align="left"
                    status={row.application && row.application.status}
                  >
                    {spacecapitalize(row.application && row.application.status)}
                  </StatusCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        {participantAllApplication.length <= 0 && (
          <div className={classes.nodataContainer}>
            <NoDataFound />
          </div>
        )}
      </Paper>
    </ThemeProvider>
  );
}

ApplicationTable.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    participantAllApplication:
      state.applications.participantAllApplication || {}
  };
};

export default connect(mapStateToProps, {
  getParticipantApplication
})(withStyles(styles)(ApplicationTable));
