/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import stackImage from "./../../../Assets/stack-image.PNG";
import { ChevronRight } from "react-feather";
const styles = () => ({
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    border: "1px solid #efefef",
    borderRadius: "5px",
    background: "#FCFDFE",
    position: "relative",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
    "&:hover": {
      transform: "scale(1.02)",
      boxShadow:
        "rgba(34, 36, 38, 0.08) 0px 2px 4px 6px, rgba(34, 36, 38, 0.15) 0px 2px 10px 0px",
      transition: "all 0.5s ease 0s"
    }
  },
  cardHeaderCont: {
    borderRadius: "5px",
    background: "#fff",
    marginBottom: 10,
    padding: "0px 0px 10px !important"
  },
  cardFooter: {
    display: "flex"
  },
  cardImage: {},
  image: {
    height: "auto"
  },

  stackName: {
    fontWeight: "600",
    fontSize: "16px",
    color: "#000000",
    marginBottom: "12px",
    textTransform: "capitalize",
    lineHeight: "22px"
  },
  viewmoreButton: {
    textDecoration: "none",
    color: " #1F627C",
    fontSize: "14px",
    lineHeight: "19px"
  },
  cardTopCont: {
    textAlign: "left",
    padding: "4px 15px 0px"
  },
  cardInnerCont: {
    margin: "0px",
    fontSize: "14px",
    lineHeight: "19px",
    color: "#626262"
  },
  leftIcon: {
    color: "#1F627C",
    position: "relative",
    top: "7px",
    width: "22px",
    height: "22px"
  }
});

function StackCard(props) {
  const { classes } = props;
  return (
    <Card className={classes.card}>
      <Paper className={classes.containerPaper}>
        <CardContent className={classes.cardHeaderCont}>
          <div className={classes.profileImage}>
            <div className={classes.image}>
              <img alt="logo" src={stackImage} className={classes.cardImage} />
            </div>
          </div>
          <div className={classes.cardTopCont}>
            <Typography
              component="h2"
              variant="h4"
              className={classes.stackName}
            >
              NodeJS Angular Web Application
            </Typography>
            <p className={classes.cardInnerCont}>
              Che4z Mainframe Basic Stack is an all-in-one extension pack for
              developers working with z/OS applications, suitable.
            </p>
          </div>
        </CardContent>
      </Paper>
      {props.create === "yes" ? (
        <CardActions className={classes.cardFooter}>
          <a href="" className={classes.viewmoreButton}>
            Create Stack <ChevronRight className={classes.leftIcon} />
          </a>
        </CardActions>
      ) : (
        ""
      )}
    </Card>
  );
}
export default withStyles(styles)(StackCard);
