import React from "react";
import spinner from "./spinner.gif";
import CircularProgress from "@material-ui/core/CircularProgress";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = () => ({
  imageSpinner :{
    width: "50px",
    margin: "auto",
    display: "block",
    marginTop: "20%"
  },
  circularLoader :{
    color: "rgba(0, 0, 0, 0.87)"
  },
  loader:{
    display: "flex",
    justifyContent: "center",
    marginTop: "20%"
  }
})
const Spinner = (props) =>{
  const { classes } = props
  return (
    <div>
      <img
        src={spinner}
        alt="Loading..."
        className={classes.imageSpinner}
      />
    </div>
  );
}
const CircularLoader = (props) => {
  const { classes } = props
  return (
  <div className={classes.loader}>
    <CircularProgress className={classes.circularLoader} />
  </div>
)};

export default(withStyles(styles)( Spinner,CircularLoader ));