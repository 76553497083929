// import { SET_CURRENT_USER } from "./../Actions/types";
import {
  GET_COMPANIES,
  GET_COMPANIES_LIST,
  GET_COMPANY,
  GET_COMPANY_USER,
  EDIT_COMPANY,
  COMPANY_LOADING,
  COMPANY_ERROR,
  GET_COMPANY_CONTACT,
  ADD_COMPANY,
  ADD_COMPANY_LOADING,
  ADD_COMPANY_ERROR,
  GET_ALL_COMPANIES,
  GET_ALL_COMPANIES_ERROR,
  ADD_ASSOCIATE_TO_COMPANY,
  ADD_ASSOCIATE_TO_COMPANY_ERROR,
  GET_ASSOCIATED_COMPANIES,
  GET_ASSOCIATED_PARTNERS,
  ADD_COMPANY_TO_PARTNER,
  ADD_COMPANY_TO_PARTNER_ERROR,
  ADD_COMPANY_CONTACT,
  ADD_COMPANY_CONTACT_ERROR
  // REMOVE_COMPANY_PARTNER,
  // REMOVE_COMPANY_PARTNER_ERROR
} from "./../Actions/types";
// import isEmpty from "./../Utils/isEmpty";

const initialState = {
  company: {},
  companies: [],
  company_by_user: null,
  loading: false,
  addCompany: { companyLoading: false },
  allCompanies: [],
  associatedCompanies: [],
  associatedPartners: [],
  addAssociatetoCompany: {},
  addCompanyContact: { companyLoading: false },
  companies_list: [],
  company_contact: [],
  removeCompany: { loading: false }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_COMPANIES:
      return {
        ...state,
        loading: false,
        companies: action.payload && action.payload.data
      };
    case GET_ASSOCIATED_COMPANIES:
      return {
        ...state,
        loading: false,
        associatedCompanies: action.payload && action.payload.data
      };
    case GET_ASSOCIATED_PARTNERS:
      return {
        ...state,
        loading: false,
        associatedPartners: action.payload && action.payload.data
      };
    case GET_COMPANIES_LIST:
      return {
        ...state,
        loading: false,
        companies_list: action.payload && action.payload.data
      };
    case GET_COMPANY:
      // console.log("reducer: ", action.payload&&action.payload);
      return {
        ...state,
        loading: false,
        company: action.payload && action.payload.data
      };
    case GET_COMPANY_USER:
      return {
        ...state,
        loading: false,
        company_by_user: action.payload && action.payload.data
      };
    case EDIT_COMPANY:
      return {
        ...state,
        loading: false,
        company: action.payload && action.payload.data
      };
    case GET_COMPANY_CONTACT:
      return {
        ...state,
        loading: false,
        company_contact: action.payload && action.payload.data
      };
    case COMPANY_LOADING:
      return {
        ...state,
        loading: true
      };
    case COMPANY_ERROR:
      return {
        ...state,
        loading: false,
        company: { success: false, data: action.payload && action.payload.data }
      };
    case ADD_COMPANY:
      return {
        ...state,
        addCompany: {
          success: true,
          loading: false,
          data: action.payload.data
        }
      };
    case ADD_COMPANY_LOADING:
      return {
        ...state,
        addCompany: {
          loading: true
        }
      };
    case ADD_COMPANY_ERROR:
      return {
        ...state,
        addCompany: {
          success: false,
          loading: false
        }
      };
    case ADD_COMPANY_CONTACT_ERROR:
      return {
        ...state,
        addCompanyContact: {
          success: false,
          loading: false
        }
      };
    case GET_ALL_COMPANIES:
      return {
        ...state,
        loading: false,
        allCompanies: action.payload && action.payload.data
      };
    case GET_ALL_COMPANIES_ERROR:
      return {
        ...state,
        loading: false,
        success: false
      };
    case ADD_ASSOCIATE_TO_COMPANY:
      return {
        ...state,
        addAssociatetoCompany: {
          success: true,
          loading: false
        }
      };
    case ADD_ASSOCIATE_TO_COMPANY_ERROR:
      return {
        ...state,
        addAssociatetoCompany: {
          success: false,
          loading: false
        }
      };
    case ADD_COMPANY_TO_PARTNER:
      return {
        ...state,
        loading: false,
        addCompanytoPartner: { success: true, loading: false }
      };
    case ADD_COMPANY_TO_PARTNER_ERROR:
      return {
        ...state,
        loading: false,
        addCompanytoPartner: { success: false, loading: false }
      };
    // case REMOVE_COMPANY_PARTNER:
    //   return {
    //     ...state,
    //     removeCompany: {
    //       success: true,
    //       loading: false,
    //       data: action.payload && action.payload.data
    //     }
    //   };
    // case REMOVE_COMPANY_PARTNER_ERROR:
    //   return {
    //     ...state,
    //     addCompany: {
    //       loading: true
    //     }
    //   };
    case ADD_COMPANY_CONTACT:
      return {
        ...state,
        addCompanyContact: {
          success: true,
          loading: false
        }
      };
    default:
      return state;
  }
}
