import React, { useState, useEffect } from "react";
import axios from "axios";
import MbProfileTabs from "./MbProfileTabs";
import { Paper, ThemeProvider } from "@material-ui/core";
import MbViewProfile from "./MbViewProfile";

function MbMyProfile(props) {
  const [userData, setUserData] = useState();
  const [viewProfile, setViewProfile] = useState(false);
  const [value, setValue] = useState(0);

  useEffect(() => {
    callUserApi();
  }, []);

  useEffect(() => {
    userData &&
      userData[0] &&
      userData[0].isSubmit === true &&
      setViewProfile(!viewProfile);
  }, [userData]);

  const viewProfileHandler = (digit, data) => {
    if (data) {
      callUserApi();
    } else {
      setViewProfile(!viewProfile);
    }
    setValue(digit);
  };

  const callUserApi = async () => {
    const userId = localStorage.getItem("user_id");
    const query = { user_id: userId };
    await axios
      .get(`/api/v2/userprofile`, {
        params: {
          filter: JSON.stringify(query)
        }
      })
      .then(response => {
        setUserData(response.data);
      })
      .catch(error => {
        console.log("error--->>>", error);
      });
  };

  return (
    <ThemeProvider>
      <Paper>
        {/* <MbProfileTabs /> */}
        {/* <ViewProfile /> */}
        {viewProfile === true ? (
          <MbViewProfile
            userData={userData && userData[0]}
            history={props.history}
            viewProfileHandler={viewProfileHandler}
          />
        ) : (
          <MbProfileTabs
            value={value}
            viewProfileHandler={viewProfileHandler}
          />
        )}
      </Paper>
    </ThemeProvider>
  );
}

export default MbMyProfile;
