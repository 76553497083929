import axios from "axios";

import _map from "lodash/map";

import {
  GET_ALL_CHALLENGES,
  CHALLENGES_LOADING,
  CHALLENGES_ERRORS,
  GET_SINGLE_CHALLENGE,
  APPLY_TO_CHALLENGE,
  GET_MY_CHALLENGES
} from "./types";
import { showSuccess, showError } from "./ActionsHelper";
export const getChallenges = () => dispatch => {
  dispatch(setChallengesLoading());

  axios
    .get(`/api/listevent`)
    .then(res => {
      dispatch({
        type: GET_ALL_CHALLENGES,
        payload: res.data
      });
    })
    .catch(() =>
      dispatch({
        type: CHALLENGES_ERRORS,
        payload: {}
      })
    );
};

async function loadPagesHtml(pages) {
  const all = _map(pages, async p => {
    const fileString = await axios({
      method: "GET",
      url: p.html_file,
      headers: undefined
    });
    return { name: p.name, htmlContent: fileString.data };
  });
  return await Promise.all(all);
}

export const getChallenge = id => dispatch => {
  dispatch(setChallengesLoading());
  // console.log(id, "::Iddd  ::");

  return axios
    .get(`/api/geteventdetails/${id}`)
    .then(async res => {
      const pages = await loadPagesHtml(res.data.data.pages);
      dispatch({
        type: GET_SINGLE_CHALLENGE,
        payload: { data: { ...res.data.data, pages } }
      });
    })
    .catch(() =>
      dispatch({
        type: CHALLENGES_ERRORS,
        payload: {}
      })
    );
};
export const applyToChallenge = (userData, history) => dispatch => {
  axios
    .post("/api/application/apply", userData)
    .then(result => {
      dispatch({
        type: APPLY_TO_CHALLENGE,
        payload: result.data
      });
      dispatch(showSuccess("", "You successfully applied to a challenge"));
      history.push({
        pathname: "/challenges"
      });
      history.push("/challenges");
    })
    .catch(() => {
      dispatch(showError("There was some error while applying to a challenge"));
    });
};

// AUTHORIZE FOR CANDIDATE ONLY
export const getMychallenges = () => dispatch => {
  dispatch(setChallengesLoading());

  axios
    .get(`/api/application/mychallenges`)
    .then(res => {
      dispatch({
        type: GET_MY_CHALLENGES,
        payload: res.data
      });
    })
    .catch(() =>
      dispatch({
        type: CHALLENGES_ERRORS,
        payload: {}
      })
    );
};

// Profile Loading
export const setChallengesLoading = () => {
  return {
    type: CHALLENGES_LOADING
  };
};
