import React, { useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { Grid, Paper } from "@material-ui/core";
import { Edit2, Edit3 } from "react-feather";
import moment from "moment";
import CommonStyles from "../../../Theme/commonStyles";
import { difference } from "lodash";
import { spacecapitalize } from "../../../Utils/StringFunctions";

const styles = () => ({
  root: {
    background: "#FAFAFA",
    boxShadow: "none"
    // padding: "12px 20px"
  },
  educationDetail: {
    marginBottom: "16px",
    background: "#ffffff",
    padding: "12px 20px"
  },
  mainTitle: {
    fontSize: "20px",
    fontWeight: "500",
    lineHeight: "28px",
    color: "#222222",
    margin: "13px 0px"
  },
  skillsSection: CommonStyles.skillsSection,
  skillsData: CommonStyles.skillsData,
  subTitle: CommonStyles.subTitle,
  content: CommonStyles.content,
  experienceData: {
    background: "#ffffff",
    padding: "6px 0px 10px"
  },
  otherSection: {
    marginBottom: "16px",
    background: "#ffffff",
    padding: "12px 20px"
  },
  WorkExperienceSec: {
    background: "#ffffff",
    padding: "12px 20px",
    marginBottom: "16px"
  },
  workView: CommonStyles.workView,
  editButton: CommonStyles.editButton,
  mainHeader: CommonStyles.mainHeader
});

function ViewMyTechnicalSkills(props) {
  const { classes, userData } = props;

  const editPersonal = value => {
    props.viewProfileHandler(value);
  };

  const calDifference = (date1, date2) => {
    const d1 = moment(date1).format("YYYY MM DD");
    const d2 = moment(date2).format("YYYY MM DD");

    var diff = Math.floor((Date.parse(d2) - Date.parse(d1)) / 86400000);
    // console.log("diff ", diff);
    var message, txtYear, txtMonth;
    if (diff >= 365) {
      const y = Math.floor(diff / 365);
      const mod = diff % 365;
      const month = Math.floor(mod / 30);

      if (y === 1) txtYear = " Year ";
      else txtYear = " Years ";

      if (month === 1) txtMonth = " Month ";
      else txtMonth = " Months ";

      if (mod === 0) message = y + txtYear;
      else message = y + txtYear + " and " + month + txtMonth;
    } else {
      const m = Math.floor(diff / 30);
      if (m === 1) txtMonth = " Month ";
      else txtMonth = " Months ";
      message = m + txtMonth;
    }
    return "(" + message + " )";
  };
  return (
    <React.Fragment>
      <Paper className={classes.root}>
        <div className={classes.educationDetail}>
          <div className={classes.mainHeader}>
            <p className={classes.mainTitle}>Technical Skills</p>
            {props.editData === "no" ? (
              ""
            ) : (
              <button
                className={classes.editButton}
                onClick={() => editPersonal(1)}
              >
                <Edit2 />
              </button>
            )}
          </div>

          <div className={classes.skillsSection}>
            {userData &&
            userData.technical_skill &&
            userData.technical_skill.length > 0 ? (
              userData.technical_skill &&
              userData.technical_skill.map((u, i) => {
                return (
                  <p className={classes.skillsData} key={i}>
                    {u && u.label}
                  </p>
                );
              })
            ) : (
              <p className={classes.skillsData}>-</p>
            )}
          </div>
        </div>
        <div className={classes.WorkExperienceSec}>
          <div className={classes.mainHeader}>
            <p className={classes.mainTitle}>Work Experience</p>
            {props.editData === "no" ? (
              ""
            ) : (
              <button
                className={classes.editButton}
                onClick={() => editPersonal(2)}
              >
                <Edit2 />
              </button>
            )}
          </div>
          <div className={classes.experienceData}>
            {userData &&
            userData.work_experience &&
            userData.work_experience.length > 0 ? (
              userData.work_experience &&
              userData.work_experience.map((u, i) => {
                return (
                  <div className={classes.workView} key={i}>
                    <p className={classes.subTitle}>
                      {u && u.job_title ? spacecapitalize(u.job_title) : "-"}
                    </p>
                    <p className={classes.content}>
                      {u && u.company_name
                        ? spacecapitalize(u.company_name)
                        : "-"}
                      {" | "}
                      {u && u.location ? spacecapitalize(u.location) : "-"}
                    </p>
                    <p className={classes.content}>
                      {/* Jul 2018 to Present (2 years 3 months) */}
                      {u && u.from_time
                        ? moment(u && u.from_time).format("MMM YYYY")
                        : "-"}
                      {" to "}
                      {u && u.to_time
                        ? moment(u && u.to_time).format("MMM YYYY")
                        : "-"}{" "}
                      {u &&
                        u.to_time &&
                        u.from_time &&
                        calDifference(u.from_time, u.to_time)}
                      {/* {moment(u && u.to_time).diff(
                        moment(u && u.from_time),
                        "month"
                      )} */}
                    </p>
                    <p className={classes.content}>
                      {u && u.description ? u.description : "-"}
                    </p>
                  </div>
                );
              })
            ) : (
              <p className={classes.workView}>-</p>
            )}
          </div>
        </div>

        <div className={classes.otherSection}>
          <div className={classes.mainHeader}>
            <p className={classes.mainTitle}>Projects</p>
            {props.editData === "no" ? (
              ""
            ) : (
              <button
                className={classes.editButton}
                onClick={() => editPersonal(3)}
              >
                <Edit2 />
              </button>
            )}
          </div>
          {userData && userData.projects && userData.projects.length > 0 ? (
            userData.projects &&
            userData.projects.map((u, i) => {
              return (
                <div className={classes.workView} key={i}>
                  <p className={classes.subTitle}>
                    {spacecapitalize(u && u.proj_name)}
                  </p>
                  <p className={classes.content}>{u && u.proj_url}</p>
                  <p className={classes.content}>{u && u.proj_desc}</p>
                </div>
              );
            })
          ) : (
            <p className={classes.workView}>-</p>
          )}
        </div>
        <div className={classes.otherSection}>
          <div className={classes.mainHeader}>
            <p className={classes.mainTitle}>Educational Details</p>
            {props.editData === "no" ? (
              ""
            ) : (
              <button
                className={classes.editButton}
                onClick={() => editPersonal(4)}
              >
                <Edit2 />
              </button>
            )}
          </div>
          {userData &&
          userData.education_details &&
          userData.education_details.length > 0 ? (
            userData.education_details &&
            userData.education_details.map((u, i) => {
              return (
                <div className={classes.workView} key={i}>
                  <p className={classes.subTitle}>
                    {spacecapitalize(u && u.degree)}
                  </p>
                  <p className={classes.content}>
                    {spacecapitalize(u && u.collegeName)}{" "}
                  </p>
                  <p className={classes.content}>{u && u.completion} </p>
                </div>
              );
            })
          ) : (
            <p className={classes.workView}>-</p>
          )}
        </div>
        <div className={classes.otherSection}>
          <div className={classes.mainHeader}>
            <p className={classes.mainTitle}>Achievements</p>
            {props.editData === "no" ? (
              ""
            ) : (
              <button
                className={classes.editButton}
                onClick={() => editPersonal(5)}
              >
                <Edit2 />
              </button>
            )}
          </div>
          {userData &&
          userData.achievements &&
          userData.achievements.length > 0 ? (
            userData.achievements &&
            userData.achievements.map((u, i) => {
              return (
                <div className={classes.workView} key={i}>
                  <p className={classes.subTitle}>
                    {spacecapitalize(u && u.title)}
                  </p>
                  <p className={classes.content}>{u && u.description}</p>
                </div>
              );
            })
          ) : (
            <p className={classes.workView}>-</p>
          )}
        </div>
      </Paper>
    </React.Fragment>
  );
}

const mapStateToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles)(ViewMyTechnicalSkills));
