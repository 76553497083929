import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import checkPermission from "../../Utils/CheckPermissions";
import getRole from "../../Utils/CheckPermissions";
import dashboardIconG from "../../Assets/dashboard_G.svg";
import companyIconG from "../../Assets/Companies_G.svg";
import eventIconG from "../../Assets/Events_G.svg";
import applicationIconG from "../../Assets/Application_G.svg";
import applicantIconG from "../../Assets/Applicants_G.svg";
import partnerIconG from "../../Assets/Partners_G.svg";
import associateIconG from "../../Assets/Associates_G.svg";
import imagelibraryIconG from "../../Assets/Image Gallery_G.svg";
import templateIconG from "../../Assets/Templates_G.svg";
import dashboardIconC from "../../Assets/dashboard_C.svg";
import companyIconC from "../../Assets/Companies_C.svg";
import eventIconC from "../../Assets/Events_C.svg";
import applicationIconC from "../../Assets/Application_C.svg";
import applicantIconC from "../../Assets/Applicants_C.svg";
import partnerIconC from "../../Assets/Partners_C.svg";
import associateIconC from "../../Assets/Associates_C.svg";
import imagelibraryIconC from "../../Assets/Image Gallery_C.svg";
import templateIconC from "../../Assets/Templates_C.svg";
// import Divider from "@material-ui/core/Divider";
// import Collapse from "@material-ui/core/Collapse";
// import List from "@material-ui/core/List";
// import IconExpandLess from "@material-ui/icons/ExpandLess";
// import IconExpandMore from "@material-ui/icons/ExpandMore";

const styles = theme => ({
  categoryHeader: {
    paddingTop: 16,
    paddingBottom: 16,
    color: "white"
  },
  item: {
    paddingTop: 8,
    paddingBottom: 8
  },
  itemCategory: {
    backgroundColor: "#211B4E",
    boxShadow: "0 -1px 0 #404854 inset",
    paddingBottom: 13
  },
  firebase: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily
  },
  itemActionable: {
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.08)"
    }
  },
  itemActiveItem: {
    color: "#575BDE",
    backgroundColor: "orange"
  },
  itemPrimary: {
    fontSize: theme.typography.fontSize,
    "&$dense": {
      fontSize: theme.typography.fontSize
    }
  },
  dense: {},
  divider: {
    marginTop: theme.spacing.unit * 2
  },
  iconImage: {
    width: "20px",
    margin: "0px 0px 0px 7px"
  },
  menuItem: {
    paddingLeft: "60px"
  },
  innerMenuText: {
    fontSize: "14px !important"
  }
});
const COMPANIES = "/company";
const DASHBOARD = "/dashboard";
const EVENTS = "/events";
const APPLICATIONS = "/applications";
const APPLICANTS = "/applicants";
const ASSOCIATES = "/associates";
const PARTNERS = "/partners";
const IMAGELIBRARY = "/imagelibrary";
const TEMPLATES = "/templates";
// const EVENTDETAIL = "/event-detail";
const role = getRole();
class NavigatorItems extends React.Component {
  state = {
    selectedIndex: this.props.history.location.pathname,
    open: false
  };

  activeRoute(routeName) {
    return this.props.history.location.pathname.indexOf(routeName) > -1
      ? true
      : false;
  }

  handleClick = () => {
    this.props.history.push("/event-detail");
    this.setState({
      open: !this.state.open
    });
  };
  render() {
    const { classes } = this.props;

    return (
      <div>
        <ListItem
          button
          onClick={() => {
            this.props.history.push("/dashboard");
          }}
          className={classNames(classes.item, classes.itemActionable)}
          selected={this.activeRoute(DASHBOARD)}
        >
          <ListItemIcon>
            <img
              src={
                this.activeRoute(DASHBOARD) ? dashboardIconC : dashboardIconG
              }
              alt=""
              className={classes.iconImage}
            />
          </ListItemIcon>
          <ListItemText
            primary="Dashboard"
            classes={{
              primary: classes.itemPrimary,
              textDense: classes.textDense
            }}
          />
        </ListItem>
        {checkPermission("COMPANY_VIEW") && (
          <ListItem
            button
            onClick={() => {
              this.props.history.push("/company");
            }}
            selected={this.activeRoute(COMPANIES)}
          >
            <ListItemIcon>
              <img
                src={this.activeRoute(COMPANIES) ? companyIconC : companyIconG}
                alt=""
                className={classes.iconImage}
              />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.itemPrimary,
                textDense: classes.textDense
              }}
              primary="Companies"
            />
          </ListItem>
        )}
        <ListItem
          button
          onClick={() => {
            this.props.history.push("/events");
          }}
          selected={this.activeRoute(EVENTS)}
        >
          <ListItemIcon>
            <img
              src={this.activeRoute(EVENTS) ? eventIconC : eventIconG}
              alt=""
              className={classes.iconImage}
            />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary,
              textDense: classes.textDense
            }}
            primary="Events"
          />
        </ListItem>

        <ListItem
          button
          onClick={() => {
            this.props.history.push("/applications");
          }}
          selected={this.activeRoute(APPLICATIONS)}
        >
          <ListItemIcon>
            <img
              src={
                this.activeRoute(APPLICATIONS)
                  ? applicationIconC
                  : applicationIconG
              }
              alt=""
              className={classes.iconImage}
            />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary,
              textDense: classes.textDense
            }}
            primary="Applications"
          />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            this.props.history.push("/applicants");
          }}
          selected={this.activeRoute(APPLICANTS)}
        >
          <ListItemIcon>
            <img
              src={
                this.activeRoute(APPLICANTS) ? applicantIconC : applicantIconG
              }
              alt=""
              className={classes.iconImage}
            />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary,
              textDense: classes.textDense
            }}
            primary="Applicants"
          />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            this.props.history.push("/partners");
          }}
          selected={this.activeRoute(PARTNERS)}
        >
          <ListItemIcon>
            <img
              src={this.activeRoute(PARTNERS) ? partnerIconC : partnerIconG}
              alt=""
              className={classes.iconImage}
            />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary,
              textDense: classes.textDense
            }}
            primary="Partners"
          />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            this.props.history.push("/associates");
          }}
          selected={this.activeRoute(ASSOCIATES)}
        >
          <ListItemIcon>
            <img
              src={
                this.activeRoute(ASSOCIATES) ? associateIconC : associateIconG
              }
              alt=""
              className={classes.iconImage}
            />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary,
              textDense: classes.textDense
            }}
            primary="Associates"
          />
        </ListItem>
        {checkPermission("COMPANY_VIEW") && (
          <ListItem
            button
            onClick={() => {
              this.props.history.push("/imagelibrary");
            }}
            selected={this.activeRoute(IMAGELIBRARY)}
          >
            <ListItemIcon>
              <img
                src={
                  this.activeRoute(IMAGELIBRARY)
                    ? imagelibraryIconC
                    : imagelibraryIconG
                }
                alt=""
                className={classes.iconImage}
              />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.itemPrimary,
                textDense: classes.textDense
              }}
              primary="Image Library"
              // primary="Associates"
            />
          </ListItem>
        )}
        {(role === "admin" || "company") && (
          <ListItem
            button
            onClick={() => {
              this.props.history.push("/templates");
            }}
            selected={this.activeRoute(TEMPLATES)}
          >
            <ListItemIcon>
              <img
                src={
                  this.activeRoute(TEMPLATES) ? templateIconC : templateIconG
                }
                alt=""
                className={classes.iconImage}
              />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.itemPrimary,
                textDense: classes.textDense
              }}
              primary="Templates"
            />
          </ListItem>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(NavigatorItems);
