import React, { useState } from "react";
import {
  Paper,
  Tabs,
  Tab,
  Typography,
  withStyles,
  createMuiTheme,
  ThemeProvider
} from "@material-ui/core";
import PersonalDetail from "./PersonalDetail";
import TechnicalSkill from "./TechnicalSkill";
import WorkExperience from "./WorkExperience";
import Projects from "./Projects";
import EducationalDetail from "./EducationalDetail";
import Achievements from "./Achievements";
import PdIconA from "../../../Assets/svgs/myProfile/personal_detailA.svg";
import PdIconD from "../../../Assets/svgs/myProfile/personal_detailD.svg";
import TechSkillA from "../../../Assets/svgs/myProfile/technical_skillA.svg";
import TechSkillD from "../../../Assets/svgs/myProfile/technical_skillD.svg";
import WorkExpA from "../../../Assets/svgs/myProfile/work_expA.svg";
import WorkExpD from "../../../Assets/svgs/myProfile/work_expD.svg";
import ProjectA from "../../../Assets/svgs/myProfile/projectA.svg";
import ProjectD from "../../../Assets/svgs/myProfile/projectD.svg";
import EduDetailA from "../../../Assets/svgs/myProfile/edu_detailA.svg";
import EduDetailD from "../../../Assets/svgs/myProfile/edu_detailD.svg";
import AchievementA from "../../../Assets/svgs/myProfile/achievementA.svg";
import AchievementD from "../../../Assets/svgs/myProfile/achievementD.svg";

const styles = () => ({
  root: {
    paddingTop: "5px",
    // paddingBottom: "5px",
    boxShadow: "none"
  },

  paper: {
    backgroundColor: "#fcfdfe"
  },
  imageIcon: {
    padding: "10px"
  }
});

const AntTabs = withStyles({
  indicator: {
    color: "#435bf2",
    backgroundColor: " #4682B4",
    display: "none"
  },
  fixed: {
    borderBottom: "1px solid #EFEFEF",
    height: "100%"
    // minHeight: "30px"
  }
})(Tabs);

const AntTab = withStyles(() => ({
  selected: {
    color: " #222222 !important",
    borderBottom: "2px solid #4CABD0"
  },
  root: {
    minWidth: "60px",
    maxWidth: "100%",
    minHeight: "22px",
    textTransform: "capitalize",
    color: "#ABABAB"
  }
}))(props => <Tab disableRipple {...props} />);

const theme = createMuiTheme({
  overrides: {
    MuiTab: {
      wrapper: {
        flexDirection: "row"
      }
    }
  }
});
function TabContainer(props) {
  return (
    <Typography
      component="div"
      style={{
        padding: "0px 14px",
        marginTop: " 14px",
        backgroundColor: "#fff"
      }}
    >
      {props.children}
    </Typography>
  );
}

function ProfileTabs(props) {
  const { classes } = props;
  // const [value, setValue] = React.useState(0);
  const [value, setValue] = useState(props.value || 0);
  // const [companyCoworkers, setCoworkers] = useState([]);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  return (
    <ThemeProvider theme={theme}>
      <Paper className={classes.root}>
        <AntTabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <AntTab
            icon={
              <img
                className={classes.imageIcon}
                src={value === 0 ? PdIconA : PdIconD}
                alt="personaldetail"
              />
            }
            label="Personal Details"
          />
          <AntTab
            icon={
              <img
                className={classes.imageIcon}
                src={value === 1 ? TechSkillA : TechSkillD}
                alt="technicalskill"
              />
            }
            label="Technical Skills"
          />
          <AntTab
            icon={
              <img
                className={classes.imageIcon}
                src={value === 2 ? WorkExpA : WorkExpD}
                alt="workexperience"
              />
            }
            label="Work Experience"
          />
          <AntTab
            icon={
              <img
                className={classes.imageIcon}
                src={value === 3 ? ProjectA : ProjectD}
                alt="projects"
              />
            }
            label="Projects"
          />
          <AntTab
            icon={
              <img
                className={classes.imageIcon}
                src={value === 4 ? EduDetailA : EduDetailD}
                alt="educationaldetail"
              />
            }
            label="Educational Details"
          />
          <AntTab
            icon={
              <img
                className={classes.imageIcon}
                src={value === 5 ? AchievementA : AchievementD}
                alt="achievements"
              />
            }
            label="Achievements"
          />
        </AntTabs>

        <TabContainer>
          {value === 0 && (
            <PersonalDetail viewProfileHandler={props.viewProfileHandler} />
          )}
          {value === 1 && (
            <TechnicalSkill viewProfileHandler={props.viewProfileHandler} />
          )}
          {value === 2 && (
            <WorkExperience viewProfileHandler={props.viewProfileHandler} />
          )}
          {value === 3 && (
            <Projects viewProfileHandler={props.viewProfileHandler} />
          )}
          {value === 4 && (
            <EducationalDetail viewProfileHandler={props.viewProfileHandler} />
          )}
          {value === 5 && (
            <Achievements viewProfileHandler={props.viewProfileHandler} />
          )}
        </TabContainer>
      </Paper>
    </ThemeProvider>
  );
}

// const mapStateToProps = state => {
//   return {
//     errors: state.errors,
//     companyCoworkers: state.coworkers.companyCoworkers
//   };
// };

export default withStyles(styles)(ProfileTabs);
