import {
  GET_APPLICANTS,
  APPLICANTS_LOADING,
  GET_APPLICANT,
  GET_APPLICANTS_ERROR,
  GET_MY_TEAM,
  GET_MY_APPLICATION
} from "../Actions/types";
const initialState = {
  applicants: [],
  loading: false,
  applicant: [],
  count: [],
  myTeam: [],
  myApplication: []
};
export default function(state = initialState, action) {
  switch (action.type) {
    case GET_APPLICANTS:
      // eslint-disable-next-line no-case-declarations
      const { applicant, count } = action.payload && action.payload.data;
      return {
        ...state,
        loading: false,
        applicants: action.payload && action.payload.data,
        ...(action.status === "all" &&
          action.payload.data &&
          count && {
            count: {
              ...count,
              all: applicant.length
            }
          })
      };

    case APPLICANTS_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_APPLICANT:
      return {
        ...state,
        loading: false,
        applicant: action.payload && action.payload.data
      };
    case GET_MY_TEAM:
      return {
        ...state,
        loading: false,
        myTeam: action.payload && action.payload.data
      };
    case GET_APPLICANTS_ERROR:
      return {
        ...state,
        loading: false,
        success: false
      };
    case GET_MY_APPLICATION:
      return {
        ...state,
        loading: false,
        myApplication: action.payload && action.payload.data
      };
    default:
      return state;
  }
}
