/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { Grid, Typography } from "@material-ui/core";
import * as Yup from "yup";
import { Formik } from "formik";
import { Form } from "react-formik-ui";
import { InputField } from "../../Components/Common/Form";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Checkbox from "@material-ui/core/Checkbox";
// import EmailIcon from "@material-ui/icons/Email";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { FormikTextField } from "formik-material-fields";
import { Button, InputAdornment, IconButton } from "@material-ui/core";
// import SignupEventDrawer from "./SignupEventDrawer";
import { loginUser, getuserdata } from "../../Actions/authAction";
import googleOAuthCredentials from "../../Utils/googleOauthCredentials";
import gmail from "../../Assets/gmail.png";
import github from "../../Assets/github.png";
// import Forgotpasswordmobile from "./Forgotpasswordmobile";
// import Resetpasswordmobile from './Resetpasswordmobile'
import axios from "axios";

const useStyles = makeStyles({
  list: {
    width: "100%",
    backgroundColor: "#fff",
    padding: "16px"
  },
  fullList: {
    width: "auto"
  },
  paper: {
    top: "48px"
  },
  modalTitle: {
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "28px",
    textAlign: "center",
    color: "#222222",
    display: "block",
    position: "relative",
    // top: "-39px"
    width: "85%",
    alignSelf: "center",
    marginRight: "7.5%"
  },

  modalForm: {
    maxWidth: "100%"
  },
  submitButton: {
    background: "#4CABD0",
    borderRadius: "5px",
    padding: "10px 30px",
    color: "#ffffff",
    width: "100%",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
    "&:hover": {
      background: "#327C93",
      color: "#ffffff"
    }
  },
  githubbutton: {
    padding: " 6px 8px",
    textTransform: "capitalize",
    background: "#FFFFFF",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.08)",
    marginLeft: "10px"
  },
  signinButton: {
    margin: "16px 0px 18px 0px"
  },
  modalFooter: {
    margin: "16px 0px 18px 0px"
  },
  loginWithText: {
    fontSize: "12px",
    lineHeight: "21px",
    color: "#222222",
    display: "inline-block",
    position: "relative",
    top: "-23px",
    background: "#fff",
    padding: "0px 6px"
  },
  border: {
    textAlign: "center",
    borderTop: "1px solid #D5EDF4",
    height: 0,
    margin: "34px 6px"
  },
  signupLink: {
    fontSize: "14px",
    lineHeight: "16px",
    textAlign: "center",
    color: "#222222",
    marginTop: "10px"
  },
  signup: {
    color: "#4CABD0",
    textDecoration: "none"
  },
  backIcon: {
    height: "1.5em",
    position: "relative",
    // left: "-8px",
    width: "1.5em"
  },
  icon: {
    display: "flex",
    width: "100%"
  },
  iconButton: {
    width: "15%",
    alignItems: "center",
    color: "#000"
  },
  loginIcon: {
    marginTop: "10px",
    marginBottom: "10px"
  },
  forgotLink: {
    color: "#327C93",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "21px",
    textDecoration: "none",
    marginTop: "8px"
  }
});
const theme = createMuiTheme({
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: "#F9F9F9"
      }
    },
    MuiTypography: {
      body1: {
        color: "#ABABAB",
        fontSize: "12px"
      }
    },
    MuiInput: {
      root: {
        marginTop: "20px",
        // width:"150%",
        paddingLeft: "10px !important",
        fontSize: "14px",
        borderRadius: "5px",
        border: "1px solid #EDE8EF",
        height: "40px"
      }
    }
  }
});

const loginParticipantSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Required"),
  password: Yup.string().required("Required")
});

function LoginEventDrawer(props) {
  const classes = useStyles();
  const [ShowPass, setShowPass] = useState(true);
  // const [forgotModel, setforgotModal] = useState(false);

  // const [forgotModel, setforgotModal] = useState(false);

  // console.log(forgotModel, "forgot model");
  const handleSubmit = (values, setErrors, setSubmitting) => {
    props.loginUser(values, setErrors, setSubmitting).then(async result => {
      if (result && result.data.success) {
        const userData = await axios.get("/api/user/me");
        const user = userData.data.data;
        localStorage.setItem("first_name", user.first_name);
        localStorage.setItem("last_name", user.last_name);
        localStorage.setItem("email", user.email);
        props.toggleDrawer();
        props.checkStatus();
        props.toggleTeamStep1Drawer();
      }
    });
  };
  // useEffect(()=>{
  //   props.checkStatus()
  // });
  const toggleDrawerSignup = event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    props.closeLoginDrawer();
    props.setopenSignupModal(true);
  };

  // const toggleForgotModel = () => {
  //   // console.log(forgotModel, "forgot model");

  //   // console.log("toggleForgotModel");
  //   setforgotModal(!forgotModel);
  //   // props.closeLoginDrawer();

  //   // props.toggleDrawer();
  // };

  const sideList = () => (
    <div className={classes.list} padding="yes" role="presentation">
      <Formik
        initialValues={{
          email: "",
          password: ""
        }}
        validationSchema={loginParticipantSchema}
        onSubmit={(values, { setErrors, setSubmitting }) => {
          handleSubmit(values, setErrors, setSubmitting);
        }}
      >
        {() => (
          <Form
            className={classes.form}
            // onSubmit={onSubmit}
            encType="multipart/form-data"
            autoComplete="off"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} s m={12} className={classes.modalForm}>
                <div className={classes.icon}>
                  <IconButton
                    // color="primary"
                    aria-label="upload picture"
                    onClick={() => props.toggleDrawer()}
                    className={classes.iconButton}
                  >
                    <ChevronLeftIcon className={classes.backIcon} />
                  </IconButton>

                  <Typography
                    variant="subtitle1"
                    className={classes.modalTitle}
                  >
                    Login
                  </Typography>
                </div>
                <Grid item xs={12} sm={12}>
                  <InputField
                    name="email"
                    placeholder="Enter your Email ID "
                    onChange={text => props.setloginEmail(text.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormikTextField
                    name="password"
                    placeholder="Enter your Password "
                    fullWidth
                    type={ShowPass ? "password" : "text"}
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                              setShowPass(!ShowPass);
                            }}
                            // onMouseDown={handleMouseDownPassword}
                          >
                            {ShowPass ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid
                  container
                  item
                  display="flex"
                  justify="flex-end"
                  alignItems="center"
                  direction="row"
                >
                  <Link
                    onClick={() => {
                      props.forgotModelHandler();
                      props.toggleDrawer();
                    }}
                    className={classes.forgotLink}
                  >
                    Forgot Password?
                  </Link>
                  {/* <a href="" className={classes.forgotLink}>
                    Forgot Password?
                  </a> */}
                </Grid>

                <Grid
                  container
                  item
                  display="flex"
                  justify="center"
                  alignItems="center"
                  direction="row"
                  className={classes.modalFooter}
                >
                  <Button
                    className={classes.submitButton}
                    type="submit"
                    variant="contained"
                  >
                    Login
                  </Button>
                </Grid>
                <div className={classes.border}>
                  <p className={classes.loginWithText}>or Login with</p>
                </div>
                <Grid
                  container
                  item
                  display="flex"
                  justify="center"
                  alignItems="center"
                  direction="row"
                  className={classes.modalFooter}
                >
                  <Button
                    className={classes.githubbutton}
                    variant="contained"
                    href={`https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email&include_granted_scopes=true&response_type=token&redirect_uri=http://localhost:3000&client_id=${
                      googleOAuthCredentials[window.origin]
                    }`}
                  >
                    {/* <EmailIcon /> */}
                    <img
                      alt="gmail"
                      src={gmail}
                      className={classes.loginIcon}
                    />
                    {/* Google */}
                  </Button>
                  <Button
                    className={classes.githubbutton}
                    variant="contained"
                    href="https://github.com/login/oauth/authorize?client_id=Iv1.0b9a42e52d03c7d0&scope=user&redirect_url=http://localhost:3000"
                  >
                    {/* <EmailIcon /> */}
                    <img
                      alt="github"
                      src={github}
                      className={classes.loginIcon}
                    />
                    {/* Github */}
                  </Button>
                </Grid>
                <p className={classes.signupLink}>
                  Don’t have an acount?
                  <a
                    href="#"
                    onClick={() => toggleDrawerSignup()}
                    className={classes.signup}
                  >
                    &nbsp;Signup
                  </a>
                </p>
                {/* <SignupEventDrawer
                  openSignupModal={openSignupModal}
                  toggleDrawer={props.toggleDrawer}
                  toggleDrawerSignup={() => toggleDrawerSignup()}
                  toggleTeamStep1Drawer={props.toggleTeamStep1Drawer}
                  checkLoginSuccess={handleSubmit}
                /> */}
                {/* {forgotModel && (
                  <Forgotpasswordmobile
                    toggleDrawer={props.toggleDrawer}
                    toggleForgotModel={toggleForgotModel}
                  />
                )} */}
                {/* <Resetpasswordmobile
                  toggleDrawer={props.toggleDrawer}
                  /> */}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );

  return (
    <ThemeProvider theme={theme}>
      <SwipeableDrawer
        anchor="bottom"
        open={props.openModal}
        disableSwipeToOpen={true}
        className={classes.mainDrawer}
        onClose={() => {
          props.toggleDrawer(false);
        }}
        onOpen={() => {
          props.toggleDrawer(true);
        }}
        PaperProps={{
          style: { borderTopLeftRadius: "20px", borderTopRightRadius: "20px" }
        }}
      >
        {sideList()}
      </SwipeableDrawer>
    </ThemeProvider>
  );
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    errors: state.errors
  };
};

export default connect(mapStateToProps, { loginUser, getuserdata })(
  LoginEventDrawer
);
