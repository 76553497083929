/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from "react";
import {
  Paper,
  Grid,
  withStyles,
  // Button,
  // CardMedia,
  // CardContent,
  // CardActions,
  Card
} from "@material-ui/core";

import { connect } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import memberProfile from "../../Assets/applicant.png";
import classNames from "classnames";
import AddIcon from "@material-ui/icons/Add";
import { getMyTeam, getMyApplication } from "../../Actions/applicantActions";
import axios from "axios";
import { capitalize } from "../../Utils/StringFunctions";
import IconButton from "@material-ui/core/IconButton";
import InviteMemberPopup from "../Landing/InviteTeamMemberModal1";
import { spacecapitalize } from "../../Utils/StringFunctions";
import _get from "lodash/get";
import _size from "lodash/size";
import _map from "lodash/map";
import _filter from "lodash/filter";
import _merge from "lodash/merge";
import _keyBy from "lodash/keyBy";
import _values from "lodash/values";
import Avatar from "react-avatar";

const drawerWidth = 240;

const styles = () => ({
  root: {
    width: `calc(100% - ${drawerWidth}px )`,
    paddingTop: "5%",
    paddingBottom: "15px",
    position: "relative",
    left: "240px",
    height: "100vh"
  },
  myteam_main: {
    padding: "10px 24px"
  },
  layout: {
    width: "calc(100% / 4 - 40px)"
  },
  main_title: {
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "27px",
    color: "#222222",
    textAlign: "center",
    marginBottom: "0px"
  },
  member_name: {
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#000000",
    marginBottom: "5px",
    marginTop: "15px",
    wordBreak: "break-all"
  },
  no_name: {
    marginBottom: "-8px"
  },
  team_detail: {
    display: "flex",
    justifyContent: "space-between"
  },
  company_name: {
    marginBottom: "0px"
  },
  mailid: {
    marginTop: "2px",
    color: "#626262",
    fontSize: "12px",
    fontWeight: "normal",
    lineHeight: "14px",
    wordBreak: "break-all"
  },
  member_image: {
    width: "110px",
    height: "110px",
    borderRadius: "100px"
  },
  teamcard: {
    textAlign: "center",
    background: "#FFFFFF",
    width: "calc(100% / 4 - 40px)",
    boxShadow: "0px 0px 20px rgba(0,0,0,0.05)",
    padding: "30px 10px 23px !important",
    margin: "20px",
    alignItems: "center",
    borderRadius: "10px"
  },
  acepted_status: {
    color: "#009246",
    lineHeight: "19px",
    fontSize: "16px",
    textTransform: "capitalize"
  },
  rejected_status: {
    color: "#F44336",
    lineHeight: "19px",
    fontSize: "16px"
  },
  invited_status: {
    color: "#407BFF",
    fontSize: "12px",
    lineHeight: "19px"
  },
  cardImg: {
    width: "130px",
    height: "130px",
    margin: "0 auto",
    borderRadius: "100px",
    padding: "5px"
  },
  active_member: {
    border: "5px solid #B9E5F3",
    borderRadius: "100px",
    width: "100%",
    height: "100%"
  },
  add_icon: {
    color: "#EFEFEF",
    width: "3em",
    height: "3em",
    background: "#F8F8F8",
    borderRadius: "50px"
  },
  addteamcard: {
    height: 255,
    border: "5px solid #efefef",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
    margin: "20px",
    width: "21%"
  },
  disqualified: {
    color: "#f53b57"
  },
  on_hold: {
    color: "#FF6F00 "
  },
  won: {
    color: "#05c46b"
  },
  rejected: {
    color: "#f53b57"
  },
  attended: {
    color: "#ffa801"
  },
  shortlisted: {
    color: "#8854d0"
  },
  waiting: {
    color: "#FF6F00"
  }
});

function MyTeamWebView(props) {
  const { classes } = props;
  const [isInviteteammate, setinviteteammate] = useState(false);
  const [activeStatus, setActiveStatus] = useState();
  const [teamMax, setTeamMax] = useState();
  const [teamMember, setTeamMember] = useState();
  const [currentChallenge, setCurrentChallenge] = useState();
  const [application_id, setApplication_id] = useState();
  const [InvitedMember, setInvitedMember] = useState();
  const closeTeammatemodel = () => {
    setinviteteammate(false);
    getData();
  };

  const getData = async () => {
    await axios.get(`/api/application/mychallenges`).then(res => {
      const currentChallenge = _filter(
        _get(res, "data.data"),
        challange => _get(challange, "event._id") === props.match.params.id
      );
      setCurrentChallenge(currentChallenge[0]);
      setApplication_id(_get(currentChallenge[0], "application._id"));
      setActiveStatus(_get(currentChallenge[0], "application.status"));
      setTeamMax(_get(currentChallenge[0], "event.team_max"));
      setInvitedMember(_get(currentChallenge[0], "application.invitations"));

      const merged = _merge(
        _keyBy(
          _get(currentChallenge[0], "application.active_members"),
          "email"
        ),
        _keyBy(_get(currentChallenge[0], "application.invitations"), "email")
      );
      const Teammates = _values(merged);
      setTeamMember(Teammates);
    });
  };

  useEffect(() => {
    getData();
  }, []);
  // console.log("application_id : ", application_id);
  return (
    <React.Fragment>
      <CssBaseline />
      <main>
        <Paper className={classes.root}>
          <div className={classes.myteam_main}>
            <Grid container spacing={2}>
              {_map(teamMember, member => (
                <Grid item className={classes.teamcard} key={member.user}>
                  <div className={classes.cardImg}>
                    {/* <img
                      src={memberProfile}
                      alt="img"
                      className={
                        localStorage.getItem("email") === member.email
                          ? classes.active_member
                          : classes.member_image
                      }
                    />{" "} */}
                    <Avatar
                      name={member.email}
                      color="#04537d"
                      style={
                        localStorage.getItem("email") === member.email
                          ? {
                              border: "5px solid #B9E5F3",
                              borderRadius: "100px",
                              padding: "4px",
                              width: "130px",
                              height: "130px"
                            }
                          : {
                              marginTop: "9px",
                              width: "100px",
                              height: "100px"
                            }
                      }
                      size="110"
                      round={true}
                    />
                  </div>
                  {member && member.first_name ? (
                    <p className={classes.member_name}>
                      {capitalize(member.first_name).concat(
                        " ",
                        member && capitalize(member.last_name)
                      )}
                    </p>
                  ) : (
                    <p className={classes.member_name}>
                      <br></br>
                    </p>
                  )}
                  <p className={classes.mailid}>{member.email}</p>
                  {/* <p className={member.status ? classes.member.status :classes.acepted_status}> */}
                  <p
                    className={classNames(
                      classes.acepted_status,
                      classes[member.status ? member.status : activeStatus]
                    )}
                  >
                    {member.status
                      ? spacecapitalize(member.status)
                      : spacecapitalize(activeStatus)}
                  </p>
                </Grid>
              ))}
              {_size(teamMember) < teamMax && (
                <Grid item className={classes.addteamcard}>
                  <IconButton
                    onClick={() => {
                      setinviteteammate(true);
                    }}
                  >
                    <AddIcon className={classes.add_icon} />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </div>
        </Paper>
        <InviteMemberPopup
          data={_get(currentChallenge, "event")}
          participatedLength={_size(teamMember)}
          closeTeammatemodel={closeTeammatemodel}
          teammatemodel={isInviteteammate}
          applicationid={application_id}
          isEditable={true}
          invitedMember={InvitedMember}
        />
      </main>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {
    errors: state.errors,
    myTeam: state.applicants.myTeam,
    myApplication: state.applicants.myApplication
  };
};

export default connect(mapStateToProps, { getMyTeam, getMyApplication })(
  withStyles(styles)(MyTeamWebView)
);
