import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import { spacecapitalize } from "../../Utils/StringFunctions";
import { makeStyles } from "@material-ui/core/styles";
import { randomId } from "../../Utils/StringFunctions";
import { UploadFieldNew } from "../../Components/Common/Form";
const useStyles = makeStyles(theme => ({
  inputRoot: {
    "&$disabled": {
      color: "#110F24"
    }
  },
  disabled: {}
}));

export const TextareaWidget = props => {
  const classes = useStyles();
  return (
    <TextField
      disabled={props.disabled}
      autofocus={true}
      size="small"
      id={props.id}
      label={spacecapitalize(props.label)}
      value={props.value}
      onChange={event => props.onChange(event.target.value)}
      multiline
      rows={props.uiSchema["ui:options"].rows}
      required={spacecapitalize(props.required)}
      variant="outlined"
      InputProps={{
        classes: {
          root: classes.inputRoot,
          disabled: classes.disabled
        }
      }}
      InputLabelProps={{
        shrink: props.value ? true : false
      }}
      placeholder={spacecapitalize(props.placeholder)}
    />
  );
};
export const TextWidget = props => {
  const classes = useStyles();
  return (
    <TextField
      disabled={props.disabled}
      size="small"
      autofocus={true}
      id={props.id}
      label={spacecapitalize(props.label)}
      value={props.value}
      InputLabelProps={{
        shrink: props.value ? true : false
      }}
      InputProps={{
        classes: {
          root: classes.inputRoot,
          disabled: classes.disabled
        }
      }}
      onChange={event => props.onChange(event.target.value)}
      required={spacecapitalize(props.required)}
      variant="outlined"
      placeholder={spacecapitalize(props.placeholder)}
    />
  );
};
export const fileWidget = props => {
  const [bannerUrl, setbannerUrl] = useState(
    `applicationform/${randomId(10)}/banner`
  );
  const onResetBannerUrl = () => {
    setbannerUrl(`applicationform/${randomId(10)}/banner`);
  };
  return (
    <UploadFieldNew
      label={props.label}
      isclear={props.disabled}
      id={props.id}
      onChange={(e, url) => props.onChange(url)}
      onResetBannerUrl={onResetBannerUrl}
      folder={bannerUrl}
      name={props.label}
      value={props.value}
      // fileName
      required={props.required}
    />
  );
};
