import React, { useState, useEffect } from "react";
import {
  Paper,
  withStyles,
  Grid,
  ThemeProvider,
  Button
} from "@material-ui/core";
import axios from "axios";
import { Formik } from "formik";
import { Form } from "react-formik-ui";
import CssBaseline from "@material-ui/core/CssBaseline";
// import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { connect } from "react-redux";
import { createMuiTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "../../../Components/Common/Button";
import Select from "react-select";
import { LiveTv } from "@material-ui/icons";
import CommonStyles from "../../../Theme/commonStyles";

const styles = theme => ({
  root: {
    paddingTop: "18px",
    paddingBottom: "15px",
    overflow: "scroll",
    height: "calc(100vh - 200px)"
  },
  // bodyView: {
  //   height: "calc(100vh - 160px)",
  //   alignItems: "flexStart",
  //   flexWrap: "wrap",
  //   position: "relative",
  // },
  textField: {
    width: "95%",
    height: "60px",
    margin: "10px 0px 0px 10px",
    borderRadius: 10
  },
  listBox: {
    borderRadius: 10,
    border: "1px solid #EFEFEF",
    padding: 5,
    width: "95%",
    margin: "10px 0px 0px 10px"
  },
  errorShow: {
    color: "red"
  },
  tag: CommonStyles.tag,
  closeTag: CommonStyles.closeTag,
  closeTagView: CommonStyles.closeTagView,
  formFooter: { marginTop: "30px", position: "absolute", bottom: "5%" },
  cancelButton: CommonStyles.cancelButton
});

function MbTechSkill(props) {
  const { classes } = props;
  const [skill, setSkill] = useState("");
  // const [duplicateSkill, setduplicateSkill] = useState(false);
  const [allSkills, setAllSkills] = useState([]);
  const [userData, setUserData] = useState({});
  const [selectedSkill, setSelectedSkill] = useState([]);

  useEffect(() => {
    callUserApi();
    callSkillList();
  }, []);

  const callUserApi = async () => {
    const userId = localStorage.getItem("user_id");
    const query = { user_id: userId };
    const response = await axios.get(`/api/v2/userprofile`, {
      params: {
        filter: JSON.stringify(query)
      }
    });
    setUserData(response && response.data);
    const techSkill =
      response.data && response.data[0] && response.data[0].technical_skill;
    if (techSkill && techSkill.length >= 0) {
      setSelectedSkill(techSkill);
    }
  };

  const callSkillList = async () => {
    const skill = await axios.get(`/api/v2/userprofileskill`);
    setAllSkills(skill && skill.data);
  };
  const handleSubmit = async () => {
    let technical_skill = [];
    for (let i = 0; i < selectedSkill.length; i++) {
      technical_skill.push(selectedSkill[i]);
    }

    const user = userData[0];
    const _id = user && user._id;
    const apiCall = await axios.put(`/api/v2/userprofile/${_id}`, {
      technical_skill,
      isSubmit: true
    });
    if (apiCall && apiCall.status === 200) {
      props.viewProfileHandler(0, true);
    }
  };

  const deleteTab = u => {
    let newArray = [...selectedSkill];
    const data = newArray.filter(x => x.value !== u.value);
    setSelectedSkill(data);
  };
  const handleChange = newValue => {
    const newArray = [];
    if (selectedSkill.length > 0) {
      for (let i = 0; i < selectedSkill.length; i++) {
        newArray.push(selectedSkill[i]);
      }
    }
    if (newValue && newValue.length > 0) {
      for (let i = 0; i < newValue.length; i++) {
        if (!newArray.find(x => x.value === newValue[i].value)) {
          newArray.push(newValue[i]);
        }
      }
    }
    setSelectedSkill(newArray);
  };
  const options = data => {
    return data.map(u => {
      const { name, _id } = u;
      return {
        value: _id,
        label: name
      };
    });
  };

  const handleClose = () => {
    setAllSkills([]);

    userData &&
      userData[0] &&
      userData[0].isSubmit &&
      props.viewProfileHandler(0);
  };
  return (
    <ThemeProvider>
      <Paper className={classes.root}>
        <Formik
          initialValues={{
            skills: "",
            allSkills: allSkills
          }}
          enableReinitialize
        >
          {() => (
            <Form
              encType="multipart/form-data"
              autoComplete="off"
              // className={classes.bodyView}
            >
              <Select
                isMulti
                placeholder="Enter Skills"
                onChange={handleChange}
                options={options(allSkills)}
                className={classes.textField}
              />

              {/* {duplicateSkill && (
                <p className={classes.errorShow}>Skill is already added</p>
              )} */}

              {selectedSkill && selectedSkill.length > 0 && (
                <div className={classes.listBox}>
                  {selectedSkill &&
                    selectedSkill.map((u, i) => {
                      return (
                        <p className={classes.tag} key={i}>
                          {u && u.label}
                          <Button
                            color="primary"
                            className={classes.closeTagView}
                            onClick={() => deleteTab(u)}
                          >
                            <CloseIcon className={classes.closeTag} />
                          </Button>
                        </p>
                      );
                    })}
                </div>
              )}
              <Grid
                item
                container
                xs={12}
                sm={12}
                direction="row"
                justify="flex-end"
                alignItems="center"
                className={classes.formFooter}
              >
                <Button onClick={handleClose} className={classes.cancelButton}>
                  Cancel
                </Button>
                <CustomButton
                  size="yes"
                  type="submit"
                  label="Submit"
                  onClick={() => {
                    handleSubmit();
                  }}
                  disabled={allSkills.length === 0}
                ></CustomButton>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </ThemeProvider>
  );
}

export default withStyles(styles)(MbTechSkill);
