/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-else-return */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import axios from "axios";
import { connect } from "react-redux";
import {
  Dialog,
  DialogContent,
  Grid,
  Typography,
  Button
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { Form } from "react-formik-ui";
import { createMuiTheme } from "@material-ui/core/styles";
import { registerApplication } from "../../Actions/applicationActions";
import * as Yup from "yup";
import { Formik } from "formik";
// import { InputField } from "../../Components/Common/Form";
import TextField from "@material-ui/core/TextField";
// import { Redirect, Link } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import "../../Assets/css/style.css";
// import { AlignCenter } from "react-feather";
// import { handleInputChange } from "react-select/src/utils";

const theme = createMuiTheme({
  overrides: {
    MuiDialog: {
      // Name of the component ⚛️ / style sheet
      paperWidthSm: {
        maxWidth: "866px",
        borderRadius: "10px"
      }
    },
    MuiTypography: {
      body1: {
        color: "#ABABAB",
        fontSize: "12px"
      }
    },
    MuiDialogContent: {
      root: {
        "&:first-child": {
          padding: "10%"
        }
      }
    }
  }
});
const styles = () => ({
  teamModal: {
    width: "866px",
    // padding: "34px 46px",
    overflow: "hidden",
    maxWidth: "866px",
    textAlign: "center",
    padding: "50px"
  },
  teammateemailid: { height: "180px" },
  emailid: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "19px",
    color: "#222222",
    borderRadius: "50px",
    padding: "5px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.05)",
    display: "inline-block",
    fontFamily: "Open Sans",
    alignItems: "center"
  },
  closeicon: {
    color: "#ABABAB"
  },
  inputGrid: {
    display: "flex"
  },
  textField: {
    width: "100%"
  },
  errorShow: {
    color: "red"
  },

  closeButton: {
    // borderRadius: "20px",
    // alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    padding: "0px",
    paddingLeft: "0px",
    paddingRight: "0px",
    margin: "0px"
  }
});
const loginParticipantSchema = Yup.object().shape({
  mail: Yup.string().required("Required")
});
function InviteTeamMemberModal(props) {
  const {
    classes,
    teamname,
    selectedimage,
    data,
    participatedLength,
    applicationid,
    isEditable,
    invitedMember
  } = props;

  const [teammates, setteammates] = useState([]);
  const [email, setemail] = useState("");
  const [maximummemberError, setmaximummemberError] = useState(false);
  const [duplicateEmail, setduplicateEmail] = useState(false);
  const [userEmail, setuserEmail] = useState("");
  const [sameEmail, setsameEmail] = useState(false);
  const iseditable = isEditable ? isEditable : false;
  const Invited =
    invitedMember &&
    invitedMember.map(u => {
      return u.email;
    });
  const handleClose = () => {
    props.closeTeammatemodel();
    props.setteamname();
    props.setselectedimage();
    setteammates([]);
  };
  const handleInputChange = value => {
    setemail(value.target.value);
  };
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const emailvalidation = re.test(email);

  const handleSubmit = async () => {
    let invitations = teammates;
    let logo = selectedimage || "company/logo/1577699637652"; // added for teating purpose coz image not upload
    let event = data._id;
    const team_name = teamname;
    let application_id = applicationid;
    let values = { event, logo, team_name, invitations };
    if (iseditable) {
      let values = { event, application_id, invitations };
      const url = `/api/application//add/teammates`;
      await axios
        .put(url, values)
        .then(data => {
          handleClose();
          props.history.push("/participant/my-application");
        })
        .catch(error => {
          console.log(error, "error while adding team");
        });
    } else {
      await axios
        .post("/api/application", values)
        .then(data => {
          handleClose();
          props.history.push("/participant/my-application");
        })
        .catch(error => {
          console.log(error, "error while adding team");
        });
    }
  };
  // const user = localStorage.getItem("user_id");
  const getUser = () => {
    axios
      .get(`/api/user/me`)
      .then(res => {
        setuserEmail(res.data.data.email);
      })
      .catch(error => {
        console.log(error, "error while adding team");
      });
  };
  useEffect(() => {
    getUser();
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Formik
        initialValues={{
          mail: "",
          teamlogo: ""
        }}
        validationSchema={loginParticipantSchema}
        onSubmit={values => {
          //   this.props.applyToChallenge(values, this.props.history);
        }}
      >
        {({ values, setFieldValue, setFieldTouched, errors, touched }) => (
          <Form
            className={classes.form}
            // onSubmit={onSubmit}
            encType="multipart/form-data"
            autoComplete="off"
          >
            <Dialog
              open={props.teammatemodel}
              onClose={props.closeTeammatemodel}
              aria-labelledby="form-dialog-title"
            >
              <div className={classes.teamModal}>
                <div className="haasProducWrap">
                  <div className="haasLoginPage">
                    <div className="haasSelectTeamMates">
                      <div class="popUpHeadPart">
                        <div class="popHeading">Select your Teammates</div>
                        <p>
                          Turpis vehicula eget felis amet, bibendumet
                          tristiqueet porttitor. <br />
                          Vestibulum et blandit mollis a vitae adipiscing risus
                          pharetra.
                        </p>
                      </div>

                      <div className="popUpLoginForm">
                        <div className="popHaasLoginForm">
                          <div className="formGroup haasSelectTeamMateForm addbutton">
                            <input
                              name="mail"
                              placeholder="Enter Team member’s Email ID"
                              variant="outlined"
                              id="outlined-basic"
                              onChange={value => {
                                handleInputChange(value);
                                setduplicateEmail(false);
                              }}
                              className="form-control formControl"
                              value={email}
                            />

                            <button
                              className={
                                !email || !emailvalidation
                                  ? "loginSubmitdisabled add_button"
                                  : "loginSubmit add_button add_Button"
                              }
                              onClick={() => {
                                const demolength = participatedLength
                                  ? participatedLength + teammates.length <
                                    data.team_max
                                  : teammates.length < data.team_max - 1;

                                if (demolength) {
                                  let newArray = [...teammates];
                                  let data = newArray.map(u => {
                                    return u.email;
                                  });

                                  if (userEmail !== email) {
                                    let duplicate =
                                      data.includes(email) ||
                                      (Invited && Invited.includes(email));
                                    if (!duplicate) {
                                      let obj = {
                                        email: email,
                                        status: "waiting"
                                      };
                                      email && newArray.push(obj);
                                      setteammates(newArray);
                                      setemail("");
                                    } else {
                                      setduplicateEmail(true);
                                    }
                                  } else {
                                    setsameEmail(true);
                                  }
                                } else setmaximummemberError(true);
                              }}
                              disabled={!email || !emailvalidation}
                            >
                              Add
                            </button>
                          </div>
                          <div className={classes.teammateemailid}>
                            {maximummemberError && (
                              <p className={classes.errorShow}>
                                Maximum {data.team_max} team members allowed...
                              </p>
                            )}
                            {duplicateEmail && (
                              <p className={classes.errorShow}>
                                User is already part of this team
                              </p>
                            )}
                            {sameEmail && (
                              <p
                                className={classes.errorShow}
                                onClick={() => setsameEmail(false)}
                              >
                                User is already registered in the system
                              </p>
                            )}
                            {teammates &&
                              teammates.map((u, i) => {
                                return (
                                  <p className={classes.emailid} key={i}>
                                    {u.email}

                                    {/* <CloseIcon className={classes.closeicon} /> */}
                                    <Button
                                      color="primary"
                                      className={classes.closeButton}
                                      onClick={() => {
                                        let newArray = [...teammates];
                                        const data = newArray.filter(
                                          x => x.email !== u.email
                                        );
                                        setteammates(data);
                                        setmaximummemberError(false);
                                      }}
                                    >
                                      <CloseIcon
                                        className={classes.closeicon}
                                      />
                                    </Button>
                                  </p>
                                );
                              })}
                          </div>
                          <div className="formBtn">
                            <button
                              className="loginCancel"
                              type="cancel"
                              onClick={handleClose}
                            >
                              CANCEL
                            </button>
                            <button
                              className={
                                teammates.length === 0
                                  ? "loginSubmitdisabled"
                                  : "loginSubmit"
                              }
                              type="submit"
                              onClick={() => {
                                handleSubmit();
                              }}
                              disabled={teammates.length === 0}
                            >
                              SUBMIT
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Dialog>
          </Form>
        )}
      </Formik>
    </ThemeProvider>
  );
}

const mapStateToProps = state => {
  return {
    errors: state.errors
  };
};

export default connect(mapStateToProps, { registerApplication })(
  withStyles(styles)(InviteTeamMemberModal)
);
