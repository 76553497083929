export const GET_ERRORS = "GET_ERRORS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";

// PROFILE
export const GET_PROFILE = "GET_PROFILE";
export const PROFILE_LOADING = "PROFILE_LOADING";
export const PROFILE_NOT_FOUND = "PROFILE_NOT_FOUND";
export const CLEAR_CURRENT_PROFILE = "CLEAR_CURRENT_PROFILE";
export const GET_PROFILES = "GET_PROFILES";

// COMPANY
export const GET_COMPANY = "GET_COMPANY";
export const GET_ASSOCIATED_COMPANIES = "GET_ASSOCIATED_COMPANIES";
export const COMPANY_LOADING = "COMPANY_LOADING";
export const COMPANY_NOT_FOUND = "COMPANY_NOT_FOUND";
export const CLEAR_CURRENT_COMPANY = "CLEAR_CURRENT_COMPANY";
export const GET_COMPANIES = "GET_COMPANIES";
export const GET_COMPANIES_LIST = "GET_COMPANIES_LIST";
export const GET_COMPANY_USER = "GET_COMPANY_USER";
export const EDIT_COMPANY = "EDIT_COMPANY";
export const ADD_COMPANY_CONTACT = "ADD_COMPANY_CONTACT";
export const GET_COMPANY_CONTACT = "GET_COMPANY_CONTACT";
export const COMPANY_ERROR = "COMPANY_ERROR";
export const ADD_COMPANY = "ADD_COMPANY";
export const ADD_COMPANY_LOADING = "ADD_COMPANY_LOADING";
export const ADD_COMPANY_ERROR = "ADD_COMPANY_ERROR";
export const GET_ALL_COMPANIES = "GET_ALL_COMPANIES";
export const GET_ALL_COMPANIES_ERROR = "GET_ALL_COMPANIES_ERROR";
export const ADD_ASSOCIATE_TO_COMPANY = "ADD_ASSOCIATE_TO_COMPANY";
export const ADD_ASSOCIATE_TO_COMPANY_ERROR = "ADD_ASSOCIATE_TO_COMPANY_ERROR";
export const GET_ASSOCIATED_PARTNERS = "GET_ASSOCIATED_PARTNERS";
export const ADD_COMPANY_TO_PARTNER = "ADD_COMPANY_TO_PARTNER";
export const ADD_COMPANY_TO_PARTNER_ERROR = "ADD_COMPANY_TO_PARTNER_ERROR";
export const ADD_COMPANY_CONTACT_ERROR = "ADD_COMPANY_CONTACT_ERROR";
export const REMOVE_COMPANY_PARTNER = "REMOVE_COMPANY_PARTNER";
export const REMOVE_COMPANY_PARTNER_ERROR = "REMOVE_COMPANY_PARTNER_ERROR";

// EVENT
export const GET_EVENT = "GET_EVENT";
export const EVENT_LOADING = "EVENT_LOADING";
export const EVENT_NOT_FOUND = "EVENT_NOT_FOUND";
export const CLEAR_CURRENT_EVENT = "CLEAR_CURRENT_EVENT";
export const GET_EVENTS = "GET_EVENTS";
export const EVENTS_LOADING = "EVENTS_LOADING";
export const ADD_EVENT = "ADD_EVENT";
export const ADD_EVENT_LOADING = "ADD_EVENT_LOADING";
export const ADD_EVENT_ERROR = "ADD_EVENT_ERROR";
export const UPDATE_EVENT_STATUS_SUCCESS = "UPDATE_EVENT_STATUS_SUCCESS";
export const EVENT_ERROR = "EVENT_ERROR";
export const ADD_PARTNER_EVENT = "ADD_PARTNER_EVENT";
export const ADD_ASSOCIATE_EVENT = "ADD_ASSOCIATE_EVENT";
export const GET_ALL_EVENTS = "GET_ALL_EVENTS";
export const GET_ALL_EVENTS_ERROR = "GET_ALL_EVENTS_ERROR";
export const ADD_EVENT_TO_PARTNER = "ADD_EVENT_TO_PARTNER";
export const ADD_EVENT_TO_PARTNER_ERROR = "ADD_EVENT_TO_PARTNER_ERROR";
export const EDIT_EVENT = "EDIT_EVENT";

// APPLICATION
export const GET_APPLICATION = "GET_APPLICATION";
export const APPLICATION_LOADING = "APPLICATION_LOADING";
export const APPLICATION_NOT_FOUND = "APPLICATION_NOT_FOUND";
export const CLEAR_CURRENT_APPLICATION = "CLEAR_CURRENT_APPLICATION";
export const GET_APPLICATIONS = "GET_APPLICATIONS";
export const GET_APPLICATION_ERROR = "GET_APPLICATION_ERROR";
export const APPLICATIONS_LOADING = "APPLICATIONS_LOADING";
export const ADD_APPLICATION = "ADD_APPLICATION";
export const CHANGE_APPLICATION_STATUS = "CHANGE_APPLICATION_STATUS";
export const APPLICATION_STATUS_ERROR = "APPLICATION_STATUS_ERROR";
export const APPLICATION_STATUS_LOADING = "APPLICATION_STATUS_LOADING";
export const GET_PARTICIPANT_APPLICATION = "GET_PARTICIPANT_APPLICATION";
export const GET_PARTICIPANT_APPLICATION_ERROR =
  "GET_PARTICIPANT_APPLICATION_ERROR";
export const APPLICATION_FORMDATA = "APPLICATION_FORMDATA";
export const APPLICATION_FORMDATA_ERROR = "APPLICATION_FORMDATA_ERROR";
export const APPLICATIONFORM_STATUS_LOADING = "APPLICATIONFORM_STATUS_LOADING";

// APPLICANTS
export const GET_APPLICANT = "GET_APPLICANT";
export const APPLICANTS_LOADING = "APPLICANTS_LOADING";
export const GET_APPLICANTS = "GET_APPLICANTS";
export const GET_APPLICANTS_ERROR = "GET_APPLICANTS_ERROR";
export const GET_MY_TEAM = "GET_MY_TEAM";
export const GET_MY_TEAM_ERROR = "GET_MY_TEAM_ERROR";
export const GET_MY_APPLICATION = "GET_MY_APPLICATION";

// PARTNERS
export const GET_PARTNER = "GET_PARTNER";
export const ADD_PARTNER = "ADD_PARTNER";
export const PARTNER_LOADING = "PARTNER_LOADING";
export const PARTNERS_LOADING = "PARTNERS_LOADING";
export const PARTNER_NOT_FOUND = "PARTNER_NOT_FOUND";
export const CLEAR_CURRENT_PARTNER = "CLEAR_CURRENT_PARTNER";
export const GET_PARTNERS = "GET_PARTNERS";
export const PARTNER_ERROR = "PARTNER_ERROR";
export const ADD_PARTNER_LOADING = "ADD_PARTNER_LOADING";
export const ADD_PARTNER_ERROR = "ADD_PARTNER_ERROR";
export const GET_ALL_PARTNER = "GET_ALL_PARTNER";
export const EDIT_PARTNER = "EDIT_PARTNER";
export const REMOVE_PARTNER_COMPANY = "REMOVE_PARTNER_COMPANY";
export const REMOVE_PARTNER_COMPANY_ERROR = "REMOVE_PARTNER_COMPANY_ERROR";
export const REMOVE_PARTNER_EVENT = "REMOVE_PARTNER_EVENT";
export const REMOVE_PARTNER_EVENT_ERROR = "REMOVE_PARTNER_EVENT_ERROR";
// ASSOCIATES
export const GET_ASSOCIATE = "GET_ASSOCIATE";
export const ASSOCIATE_LOADING = "ASSOCIATE_LOADING";
export const ASSOCIATES_LOADING = "ASSOCIATES_LOADING";
export const ASSOCIATE_NOT_FOUND = "ASSOCIATE_NOT_FOUND";
export const CLEAR_CURRENT_ASSOCIATE = "CLEAR_CURRENT_ASSOCIATE";
export const GET_ASSOCIATES = "GET_ASSOCIATES";
export const ADD_ASSOCIATE = "ADD_ASSOCIATE";
export const ASSOCIATE_ERRORS = "ASSOCIATE_ERRORS";
export const ADD_ASSOCIATE_LOADING = "ADD_ASSOCIATE_LOADING";
export const ADD_ASSOCIATE_ERROR = "ADD_ASSOCIATE_ERROR";
export const GET_ASSOCIATES_SKILLS = "GET_ASSOCIATES_SKILLS";
export const GET_ASSOCIATES_SKILLS_LOADING = "GET_ASSOCIATES_SKILLS_LOADING";
export const GET_ASSOCIATES_SKILLS_ERROR = "GET_ASSOCIATES_SKILLS_ERROR";
export const GET_ALL_ASSOCIATE = "GET_ALL_ASSOCIATE";
export const CREATE_NOTE = "CREATE_NOTE";
export const GET_TEAM_MATES = "GET_TEAM_MATES";
export const GET_TEAM_MATES_ERROR = "GET_TEAM_MATES_ERROR";
export const EDIT_ASSOCIATE = "EDIT_ASSOCIATE";
export const REMOVE_ASSOCIATE_COMPANY = "REMOVE_ASSOCIATE_COMPANY";
export const REMOVE_ASSOCIATE_COMPANY_ERROR = "REMOVE_ASSOCIATE_COMPANY_ERROR";
export const REMOVE_ASSOCIATE_EVENT = "REMOVE_ASSOCIATE_EVENT";
export const REMOVE_ASSOCIATE_EVENT_ERROR = "REMOVE_ASSOCIATE_EVENT_ERROR";
//Common DropDown Data
export const GET_CITIES = "GET_CITIES";
export const GET_CITIES_LOADING = "GET_CITIES_LOADING";
export const GET_CITIES_ERRORS = "GET_CITIES_ERRORS";

// Challenges
export const GET_ALL_CHALLENGES = "GET_ALL_CHALLENGES";
export const GET_SINGLE_CHALLENGE = "GET_SINGLE_CHALLENGE";
export const CHALLENGES_LOADING = "CHALLENGES_LOADING";
export const CHALLENGES_ERRORS = "CHALLENGES_ERRORS";
export const APPLY_TO_CHALLENGE = "APPLY_TO_CHALLENGE";
// FOR CANDIDATE ONLY
export const GET_MY_CHALLENGES = "GET_MY_CHALLENGES";

//NOTES
export const GET_NOTES = "GET_NOTES";
export const ADD_NOTE = "ADD_NOTE";
export const NOTES_LOADING = "NOTES_LOADING";

//USER DATA
export const USER_DATA = "USER_DATA";
