import React from "react";
import { Paper, withStyles } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";

import MbPersonalDetail from "./MyProfileTabs/MbPersonalDetail";
import MbTechSkill from "./MyProfileTabs/MbTechSkill";
import MbWorkExp from "./MyProfileTabs/MbWorkExp";
import MbEduDetail from "./MyProfileTabs/MbEduDetail";
import MbProjects from "./MyProfileTabs/MbProjects";
import MbAchievement from "./MyProfileTabs/MbAchievement";
import SelectComponent from "./SelectComponent";

const styles = theme => ({
  root: {
    // paddingTop: "18px",
    paddingBottom: "15px",
    // overflow: "scroll",
    overflowX: "Hidden"
  },
  tabs: {
    // margin: theme.spacing(1),
    padding: "0px 5px 0px 30px",
    width: "100%",
    backgroundColor: "white"
  }
});

class MbProfileTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      singleData: this.PageArray[(this.props && this.props.value) || 0]
    };
  }

  PageArray = [
    {
      name: "Personal Detail",
      tabs: 1
    },
    {
      name: "Technical Skills",
      tabs: 2
    },
    {
      name: "Work Experience",
      tabs: 3
    },
    {
      name: "Projects",
      tabs: 4
    },
    {
      name: "Educational Details",
      tabs: 5
    },
    {
      name: "Achievements",
      tabs: 6
    }
  ];

  handleChange = event => {
    this.setState({ tabs: event.target.value });
  };
  onChange = Data => {
    this.setState({ singleData: Data });
  };

  render() {
    const { classes } = this.props;
    const { tabs } = this.state.singleData;

    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.main}>
          <Paper className={classes.root}>
            <SelectComponent
              myProfile
              data={this.PageArray}
              onChange={this.onChange}
              singleData={this.state.singleData}
            />

            {tabs === 1 && (
              <MbPersonalDetail
                viewProfileHandler={this.props.viewProfileHandler}
              />
            )}
            {tabs === 2 && (
              <MbTechSkill viewProfileHandler={this.props.viewProfileHandler} />
            )}
            {tabs === 3 && (
              <MbWorkExp viewProfileHandler={this.props.viewProfileHandler} />
            )}
            {tabs === 4 && (
              <MbProjects viewProfileHandler={this.props.viewProfileHandler} />
            )}
            {tabs === 5 && (
              <MbEduDetail viewProfileHandler={this.props.viewProfileHandler} />
            )}
            {tabs === 6 && (
              <MbAchievement
                viewProfileHandler={this.props.viewProfileHandler}
              />
            )}
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(MbProfileTabs);
