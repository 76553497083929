import {
  GET_APPLICATIONS,
  GET_APPLICATION,
  APPLICATIONS_LOADING,
  CHANGE_APPLICATION_STATUS,
  APPLICATION_STATUS_ERROR,
  APPLICATION_STATUS_LOADING,
  GET_TEAM_MATES,
  GET_TEAM_MATES_ERROR,
  GET_APPLICATION_ERROR,
  GET_PARTICIPANT_APPLICATION,
  GET_PARTICIPANT_APPLICATION_ERROR,
  APPLICATION_FORMDATA,
  APPLICATIONFORM_STATUS_LOADING,
  APPLICATION_FORMDATA_ERROR
} from "../Actions/types";
const initialState = {
  applications: [],
  loading: false,
  application: [],
  applicationstatus: { statusloading: false },
  team: [],
  participantAllApplication: [],
  applicationFormData: []
};
export default function(state = initialState, action) {
  switch (action.type) {
    case GET_APPLICATIONS:
      // eslint-disable-next-line no-case-declarations
      const { application, count } = action.payload && action.payload.data;
      return {
        ...state,
        loading: false,
        applications: action.payload && action.payload.data,
        ...(action.status === "all" &&
          action.payload.data &&
          count && {
            count: {
              ...count,
              all: application.length
            }
          })
      };

    case GET_APPLICATION:
      return {
        ...state,
        loading: false,
        application: action.payload && action.payload.data
      };
    case GET_PARTICIPANT_APPLICATION:
      return {
        ...state,
        loading: false,
        participantAllApplication: action.payload && action.payload.data
      };
    case GET_APPLICATION_ERROR:
      return {
        ...state,
        loading: false,
        success: false
      };

    case GET_PARTICIPANT_APPLICATION_ERROR:
      return {
        ...state,
        loading: false,
        success: false
      };
    case APPLICATION_FORMDATA:
      return {
        ...state,
        loading: false
      };
    case APPLICATION_FORMDATA_ERROR:
      return {
        ...state,
        loading: false,
        success: false
      };
    case APPLICATIONFORM_STATUS_LOADING:
      return {
        ...state,
        loading: true
      };
    case APPLICATIONS_LOADING:
      return {
        ...state,
        loading: true
      };
    case CHANGE_APPLICATION_STATUS:
      return {
        ...state,
        applicationstatus: {
          loading: false,
          success: true,
          data: action.payload.data
        }
      };
    case APPLICATION_STATUS_LOADING:
      return {
        ...state,
        applicationstatus: { loading: true }
      };
    case APPLICATION_STATUS_ERROR:
      return {
        ...state,
        applicationstatus: { loading: false, success: false }
      };
    case GET_TEAM_MATES:
      return {
        ...state,
        loading: false,
        team: action.payload && action.payload.data
      };
    case GET_TEAM_MATES_ERROR:
      return {
        ...state,
        loading: false,
        success: false
      };
    default:
      return state;
  }
}
