import React, { useState } from "react";
import {
  Paper,
  Tabs,
  Tab,
  Typography,
  Grid,
  withStyles,
  Button
} from "@material-ui/core";
import { connect } from "react-redux";
import LoginEventDrawer from "./LoginEventDrawer";
import SideDrawer from "./SideDrawer";
import TeamFormStep1Drawer from "./TeamFormStep1Drawer";
import TeamFormStep2Drawer from "./TeamFormStep2Drawer";
import ForgotModel from "./Forgotpasswordmobile";
import moment from "moment";
import SelectComponent from "./SelectComponent";
import SignupEventDrawer from "./SignupEventDrawer";
import axios from "axios";
const styles = () => ({
  root: {
    backgroundColor: "#FCFDFE",
    paddingTop: "18px",
    paddingBottom: "15px",
    overflow: "scroll"
  },
  noCoworker: {
    padding: "24px 24px 11px",
    color: "lightgrey"
  },
  layout: {
    marginTop: "19px !important",
    padding: "20px 25px"
  },
  paper: {
    backgroundColor: "#fcfdfe"
  },
  tabText: {
    textTransform: "capitalize"
  },
  eventOverview: {
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "28px",
    textAlign: "center",
    color: "#222222"
  },
  eventContent: {
    fontSize: "14px",
    lineHeight: "21px",
    color: "#626262"
  },
  submitButton: {
    background: "#4CABD0",
    borderRadius: "5px",
    // padding: "10px 30px",
    // margin: "10px auto",
    color: "#ffffff",
    // marginLeft: "-9px",
    // marginRight: "15px",
    width: "92%",

    "&:hover": {
      background: "#327C93",
      color: "#ffffff"
    }
    // margin: "0px"
    // position: "fixed"
  },
  buttonContainer: {
    width: "100%",
    textAlign: "center",
    position: "fixed",
    bottom: "0px",
    paddingBottom: "20px",
    height: "50px",
    background: "#fff",
    marginTop: "50px"
  },
  tabContainerbar: {
    marginTop: " 14px"
  },
  fromListButton: {
    // background: "#4CABD0",
    // marginTop: "20px",
    // color: "#fff"
    // marginBottom: "20px"
  },
  ListButton: {
    background: "#4CABD0",
    marginTop: "20px",
    color: "#fff",
    marginBottom: "20px"
  }
});
const AntTabs = withStyles({
  indicator: {
    color: "#f5f5f5",
    backgroundColor: " #f5f5f5",
    top: "38px"
  }
})(Tabs);

const AntTab = withStyles(() => ({
  selected: {
    color: " #327C93 !important",
    background: "#F5F5F5",
    borderRadius: "50px",
    marginLeft: "10px"
  }
}))(props => <Tab disableRipple {...props} />);

function TabContainer(props) {
  // const { classes } = props;
  return <Typography component="div">{props.children}</Typography>;
}

function EventTabs(props) {
  const { classes, eventData, select, fromList } = props;
  const [value, setValue] = React.useState(0);
  const [openModal, setopenModal] = useState(false);
  const [openTeamModal, setopenTeamModal] = useState(false);
  const [selectedimage, setselectedimage] = useState();
  const [teamname, setteamname] = useState("");
  const [openTeammateModel, setopenTeammateModel] = useState(false);
  const [drawer, setdrawer] = useState(false);
  const [forgotModel, setforgotModel] = useState(false);
  const [loginEmail, setloginEmail] = useState("");
  const [openSignupModal, setopenSignupModal] = useState(false);
  // const [sticky, setsticky] = useState(0);
  const [applied, setapplied] = useState(false);
  const user = localStorage.getItem("user_id");
  const id = eventData._id;
  const isApplied = () => {
    const data =
      props.applied &&
      props.applied.filter(
        i => i.createdBy.includes(user) && i.event.includes(id)
      );
    return data.length >= 1;
  };
  let isapplied = isApplied();

  function handleChange(event, newValue) {
    setValue(newValue);
  }
  const toggleDrawer = event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (!props.auth.isAuthenticated) {
      setopenModal(!openModal);
      setopenSignupModal(false);
    } else {
      setopenTeamModal(!openTeamModal);
    }
  };

  const forgotModelHandler = () => {
    setforgotModel(!forgotModel);
  };

  const closeLoginDrawer = () => {
    setopenModal(false);
  };

  const closeSideDrawer = () => {
    setdrawer(false);
  };

  // const openSideDrawer = () => {
  //   setdrawer(true);
  // };

  const toggleTeamStep1Drawer = event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setopenTeamModal(!openTeamModal);
  };

  const TeammateModelHandler = () => {
    checkApplied();
    setopenTeammateModel(!openTeammateModel);
  };
  const toggleDrawerSignup = event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setopenSignupModal(!openSignupModal);
  };
  const checkApplied = async () => {
    await axios
      .get(`/api/application/v2/participants`)
      .then(response => {
        response.data.filter(i => {
          if (
            i.createdBy.includes(localStorage.getItem("user_id")) &&
            i.event.includes(id)
          ) {
            setopenTeamModal(false);
            setapplied(true);
          }
        });
      })
      .catch(error => {
        console.log(error);
      });
  };
  return (
    <Paper className={classes.root}>
      {!fromList && (
        <>
          {select &&
          eventData &&
          eventData.pages &&
          eventData.pages.length > 0 ? (
            <>
              <SelectComponent data={eventData} />
            </>
          ) : (
            <AntTabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
            >
              <AntTab className={classes.tabText} label="Brief" />
              <AntTab
                className={classes.tabText}
                label="Timeline & Selection Criteria"
              />
              <AntTab
                className={classes.tabText}
                label="Fair Data Use Agreement"
              />
            </AntTabs>
          )}
        </>
      )}

      <TabContainer>
        <div className={classes.tabContainerbar}>
          {!fromList && !select && (
            <>
              {value === 0 && (
                <>
                  <p className={classes.eventOverview}>Challenge Overview</p>
                  <p className={classes.eventContent}>{eventData.details}</p>
                </>
              )}

              {value === 1 && (
                <main className={classes.layout}>
                  <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                      >
                        <Grid
                          item
                          align="center"
                          className={classes.noCoworker}
                        >
                          <span>No Data Found.</span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </main>
              )}
              {value === 2 && (
                <>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item align="center" className={classes.noCoworker}>
                      <span>No Data Found.</span>
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          )}

          <>
            {props.auth.isAuthenticated && (
              <>
                {moment() > moment(eventData.last_day_of_registration) ? (
                  <div
                    className={
                      fromList
                        ? classes.fromListButton
                        : classes.buttonContainer
                    }
                  >
                    <Button
                      className={
                        fromList ? classes.ListButton : classes.submitButton
                      }
                      type="submit"
                      variant="contained"
                      onClick={() => toggleDrawer()}
                      disabled
                    >
                      Registration Over
                    </Button>
                  </div>
                ) : (
                  <div
                    className={
                      fromList
                        ? classes.fromListButton
                        : classes.buttonContainer
                    }
                  >
                    <Button
                      className={
                        fromList ? classes.ListButton : classes.submitButton
                      }
                      disabled={isapplied || applied}
                      type="submit"
                      variant="contained"
                      onClick={() => toggleDrawer()}
                    >
                      {isapplied || applied ? "Participated" : "PARTICIPATE"}
                    </Button>
                  </div>
                )}
              </>
            )}

            {props.auth.isAuthenticated === false && (
              <div>
                {moment() > moment(eventData.last_day_of_registration) ? (
                  <div
                    className={
                      fromList
                        ? classes.fromListButton
                        : classes.buttonContainer
                    }
                  >
                    <Button
                      className={
                        fromList ? classes.ListButton : classes.submitButton
                      }
                      type="submit"
                      variant="contained"
                      onClick={() => toggleDrawer()}
                      disabled
                    >
                      Registration Over
                    </Button>
                  </div>
                ) : (
                  <div
                    className={
                      fromList
                        ? classes.fromListButton
                        : classes.buttonContainer
                    }
                  >
                    <Button
                      className={
                        fromList ? classes.ListButton : classes.submitButton
                      }
                      type="submit"
                      variant="contained"
                      onClick={() => toggleDrawer()}
                    >
                      PARTICIPATE
                    </Button>
                  </div>
                )}
                {/* <Button
                className={classes.submitButton}
                type="submit"
                variant="contained"
                onClick={() => toggleDrawer()}
              >
                PARTICIPATE
              </Button> */}
              </div>
            )}

            <LoginEventDrawer
              checkStatus={() => checkApplied()}
              openModal={openModal}
              openTeamModal={openTeamModal}
              toggleDrawer={() => toggleDrawer()}
              toggleTeamStep1Drawer={() => toggleTeamStep1Drawer()}
              closeLoginDrawer={closeLoginDrawer}
              forgotModelHandler={forgotModelHandler}
              loginEmail={loginEmail}
              setloginEmail={setloginEmail}
              setopenSignupModal={setopenSignupModal}
            />
            <SignupEventDrawer
              openSignupModal={openSignupModal}
              toggleDrawer={toggleDrawer}
              toggleDrawerSignup={() => toggleDrawerSignup()}
              toggleTeamStep1Drawer={toggleTeamStep1Drawer}
              // checkLoginSuccess={handleSubmit}
              setopenModal={setopenModal}
            />
            <TeamFormStep1Drawer
              eventData={props.eventData}
              openTeamModal={openTeamModal}
              toggleTeamStep1Drawer={() => toggleTeamStep1Drawer()}
              TeammateModelHandler={TeammateModelHandler}
              selectedimage={selectedimage}
              setselectedimage={setselectedimage}
              teamname={teamname}
              setteamname={setteamname}
              toggleTeamStep2Drawer={() => TeammateModelHandler()}
            />
            <TeamFormStep2Drawer
              eventData={props.eventData}
              selectedimage={selectedimage}
              teamname={teamname}
              setselectedimage={setselectedimage}
              setteamname={setteamname}
              openTeamSecondModal={openTeammateModel}
              toggleTeamStep2Drawer={() => TeammateModelHandler()}
            />

            <ForgotModel
              toggleForgotModel={forgotModelHandler}
              toggleDrawer={toggleDrawer}
              forgotModel={forgotModel}
              loginEmail={loginEmail}
              setloginEmail={setloginEmail}
            />

            <SideDrawer open={drawer} close={closeSideDrawer} />
          </>
        </div>
      </TabContainer>
    </Paper>
  );
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    errors: state.errors
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(EventTabs));
