import React, { useState, useEffect } from "react";
import { Paper, withStyles, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import CheckIcon from "@material-ui/icons/Check";
import CancelIcon from "@material-ui/icons/Close";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import FilterListIcon from "@material-ui/icons/FilterList";
import { getParticipantApplication } from "../../Actions/applicationActions";
import { spacecapitalize } from "../../Utils/StringFunctions";
import moment from "moment";
import { AddCircleOutlineRounded } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import TeamFormStep2Drawer from "./TeamFormStep2Drawer";
import NoDataFound from "../../Components/Common/NoDataFound";
import _get from "lodash/get";
import _concate from "lodash/concat";
import _uniqBy from "lodash/uniqBy";
import _merge from "lodash/merge";
import _keyBy from "lodash/keyBy";
import _values from "lodash/values";
import Avatar from "react-avatar";
const styles = () => ({
  root: {
    paddingTop: "18px",
    paddingBottom: "15px",
    overflow: "scroll",
    height: "90vh"
  },
  mychallenges_main: {
    padding: "10px 24px"
  },
  mychallenges_inner: {
    padding: "10px",
    background: "#FFFFFF",
    boxShadow: " 0px 0px 30px rgba(0, 0, 0, 0.05)",
    borderRadius: "5px",
    width: "100%",
    marginBottom: "10px"
  },
  challenges_name: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "25px",
    color: "#222222",
    marginBottom: "0px",
    width: "80%"
  },
  doneIcon: {
    color: "#11A711",
    // width: "0.85em",
    position: "relative",
    top: "5px",
    left: "18px",
    width: "15%"
  },
  cancelIcon: {
    color: "#F21634",
    // width: "0.85em",
    position: "relative",
    top: "5px",
    left: "18px",
    width: "15%"
  },
  filterIcon: {
    color: "#FF6F00",
    // width: "0.85em",
    position: "relative",
    top: "5px",
    left: "18px",
    width: "15%"
  },
  challenges_date: {
    fontSize: "14px",
    lineHeight: "19px",
    color: " #626262",
    marginTop: "4px"
  },
  team_detail: {
    display: "flex",
    justifyContent: "space-between"
  },
  company_name: {
    marginBottom: "0px",
    textAlign: "center",
    fontSize: "14px"
  },
  inner_title: {
    marginTop: "2px",
    color: "#ABABAB",
    fontSize: "12px"
  },
  closeIcon: {
    color: "#F21634",
    width: "0.85em",
    position: "relative",
    top: "5px",
    left: "18px"
  },
  noDataContainer: {
    textAlign: "center",
    marginTop: "100px",
    marginBottom: "200px"
  },
  eventNameContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  name: { textTransform: "capitalize" },
  status: {
    backgroundColor: "#fff",
    textAlign: "right",
    textTransform: "capitalize",
    fontSize: "12px"
  },
  emailContainer: { wordBreak: "break-all" }
});

const theme = createMuiTheme({
  overrides: {
    // MuiTypography: {
    //   displayBlock: {
    //     fontSize: "12px"
    //   }
    // }
  }
});

const statusColor = type => {
  switch (type) {
    case "disqualified":
      return {
        color: "#f53b57"
      };
    case "on_hold":
      return {
        color: "#FF6F00 "
      };
    case "won":
      return {
        color: "#05c46b"
      };
    case "rejected":
      return {
        color: "#f53b57"
      };
    case "attended":
      return {
        color: "#ffa801"
      };
    case "shortlisted":
      return {
        color: "#8854d0"
      };
    case "waiting":
      return {
        color: "#FF6F00"
      };
    default:
      return {
        color: "#009246"
      };
  }
};

function ViewTeammate(props) {
  const { classes } = props.props;
  const {
    data,
    toggleTeammate,
    openTeamSecondModal,
    toggleTeamStep2Drawer,
    membercount
  } = props;
  const [open, setopen] = useState(false);
  const handleClick = event => {
    setopen(!open);
  };
  const { invitations, active_members } = data.application;
  // const invitation = data.application.invitations
  // const activemembers = data.application.active_members
  const merged = _merge(
    _keyBy(active_members, "email"),
    _keyBy(invitations, "email")
  );
  const Teammates = _values(merged);

  return (
    <ThemeProvider theme={theme}>
      <div>
        <ListItem button onClick={event => handleClick(event)}>
          <ListItemText primary="View Teammates" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {Teammates.map(u => {
              return (
                <Grid container key={u._id}>
                  <ListItem button className={classes.nested} key={u._id}>
                    <Grid item sm={2} xs={2}>
                      <ListItemIcon>
                        <Avatar
                          name={u.email}
                          color="#04537d"
                          size="30"
                          round={true}
                        />
                      </ListItemIcon>
                    </Grid>
                    <Grid item sm={7} xs={7}>
                      {u && u.status === "waiting" ? (
                        <ListItemText className={classes.emailContainer}>
                          {u && u.email}
                        </ListItemText>
                      ) : (
                        <ListItemText className={classes.name}>
                          {u && u.first_name ? u.first_name : u.email}{" "}
                          {u && u.last_name ? u.last_name : " "}
                        </ListItemText>
                      )}
                    </Grid>
                    <Grid item sm={3} xs={3}>
                      <ListItemText>
                        <p
                          style={statusColor(
                            u && u.status ? u.status : data.application.status
                          )}
                          className={classes.status}
                        >
                          {" "}
                          {spacecapitalize(
                            u && u.status ? u.status : data.application.status
                          )}
                        </p>
                      </ListItemText>
                    </Grid>
                  </ListItem>
                </Grid>
              );
            })}
            {/* <ListItem button onClick={() => toggleTeammate()}>
              <ListItemIcon>
                <AddCircleOutlineRounded />
              </ListItemIcon>
              <ListItemText>Add Teammate</ListItemText>
            </ListItem> */}
            {membercount < data.event.team_max && (
              <>
                <ListItem button onClick={() => toggleTeammate()}>
                  <ListItemIcon>
                    <AddCircleOutlineRounded />
                  </ListItemIcon>
                  <ListItemText>Add Teammate</ListItemText>
                </ListItem>
                <TeamFormStep2Drawer
                  openTeamSecondModal={openTeamSecondModal}
                  toggleTeamStep2Drawer={() => toggleTeamStep2Drawer()}
                  eventData={data.event}
                  participatedLength={membercount}
                  applicationid={data.application._id}
                  isEditable={true}
                  invitedMember={data.application.invitations}
                />
              </>
            )}
          </List>
        </Collapse>
      </div>
    </ThemeProvider>
  );
}

function Mychallenges(props) {
  const { classes } = props;
  const [participantApplication, setparticipantApplication] = useState([]);
  const [AddTeammate, setAddTeammate] = useState(false);

  const toggleAddTeammate = () => {
    props.getParticipantApplication();
    setAddTeammate(!AddTeammate);
  };

  useEffect(() => {
    props.getParticipantApplication();
  }, []);

  useEffect(() => {
    setparticipantApplication(props.application);
  }, [props.application]);

  const status = type => {
    switch (type) {
      case "registered":
        return <FilterListIcon className={classes.filterIcon} />;
      case "on_hold":
        return <FilterListIcon className={classes.filterIcon} />;
      case "rejected":
        return <CancelIcon className={classes.cancelIcon} />;
      case "disqualified":
        return <CancelIcon className={classes.cancelIcon} />;
      case "shortlisted":
        return <CheckIcon className={classes.doneIcon} />;
      case "applied":
        return <CheckIcon className={classes.doneIcon} />;
      case "won":
        return <CheckIcon className={classes.doneIcon} />;
      default:
        return <FilterListIcon className={classes.filterIcon} />;
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.main}>
        <Paper className={classes.root}>
          {participantApplication.length <= 0 && (
            <div className={classes.noDataContainer}>
              <NoDataFound />
            </div>
          )}
          {participantApplication.length > 0 && (
            <div className={classes.mychallenges_main}>
              {participantApplication &&
                participantApplication.map(u => {
                  // for member count
                  const totalmembercount = _uniqBy(
                    _concate(
                      u.application.active_members,
                      u.application.invitations
                    ),
                    "email"
                  ).length;
                  return (
                    <div className={classes.mychallenges_inner} key={u._id}>
                      <div className={classes.eventNameContainer}>
                        <p className={classes.challenges_name}>
                          {spacecapitalize(_get(u, "event.event_name", ""))}
                        </p>
                        <Tooltip
                          title={spacecapitalize(
                            u.application && u.application.status
                          )}
                          aria-label="add"
                        >
                          {status(u.application && u.application.status)}
                        </Tooltip>
                      </div>
                      <p className={classes.challenges_date}>
                        {moment(u.event && u.application.createdAt).format(
                          "DD/MM/YYYY"
                        )}
                      </p>
                      <div className={classes.team_detail}>
                        <div className={classes.team_content}>
                          <p className={classes.company_name}>
                            {spacecapitalize(
                              _get(u, "event.company.company_name", "")
                            )}
                          </p>
                          <p className={classes.inner_title}>Company Name</p>
                        </div>
                        <div className={classes.team_content}>
                          <p className={classes.company_name}>
                            {spacecapitalize(
                              _get(u, "application.team_name", "")
                            )}
                          </p>
                          <p className={classes.inner_title}>Team Name</p>
                        </div>
                        <div className={classes.team_content}>
                          <p className={classes.company_name}>
                            {totalmembercount}
                          </p>
                          <p className={classes.inner_title}>Members</p>
                        </div>
                      </div>
                      <ViewTeammate
                        props={props}
                        data={u}
                        membercount={totalmembercount}
                        toggleTeammate={() => toggleAddTeammate()}
                        openTeamSecondModal={AddTeammate}
                        toggleTeamStep2Drawer={() => toggleAddTeammate()}
                        eventData={u.event}
                      />
                    </div>
                  );
                })}
            </div>
          )}
        </Paper>
      </main>
    </React.Fragment>
  );
}
const mapStateToProps = state => {
  return {
    errors: state.errors,
    application: state.applications.participantAllApplication || {}
  };
};

export default connect(mapStateToProps, { getParticipantApplication })(
  withStyles(styles)(Mychallenges)
);
