import React, { useEffect, useState } from "react";
import { Button, InputAdornment, IconButton } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { loginUser } from "../../Actions/authAction";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { InputField } from "../../Components/Common/Form";
import { FormikTextField } from "formik-material-fields";
// import EmailIcon from "@material-ui/icons/Email";
import { push } from "connected-react-router";
import { Link } from "react-router-dom";
import githubIcon from "./../../Assets/icons/GitHubIcon.SVG";
import gmailIcon from "./../../Assets/Gmail_Icon.png";
import googleOAuthCredentials from "../../Utils/googleOauthCredentials";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
const styles = () => ({
  registermodal: {
    width: 800,
    padding: "34px 24px",
    overflow: "hidden",
    maxWidth: 800
  },
  modalImage: {
    width: "100%"
  },
  modalTitle: {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#222",
    textTransform: "capitalize",
    lineHeight: "28px"
  },
  modalInnerCont: {
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "18px",
    color: "#626262"
  },
  forgotLink: {
    color: "#327C93",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "21px",
    textDecoration: "none"
  },
  modalForm: {
    maxWidth: "100%"
    // flexBasis: "58%"
  },
  submitButton: {
    background: "#327C93",
    borderRadius: "5px",
    padding: "10px 30px",
    color: "#ffffff",

    "&:hover": {
      background: "#327C93",
      color: "#ffffff"
    }
  },
  cancelButton: {
    background: "#efefef",
    borderRadius: "5px",
    padding: "10px 30px",
    color: "#327C93",
    marginRight: "16px",
    "&:hover": {
      background: "#efefef",
      color: "#327C93"
    }
  },
  gmailbutton: {
    boxShadow: "none",
    width: "49%",
    background: "#D44638",
    padding: "2px 14px 2px 2px",
    textTransform: "capitalize",
    "&:hover": {
      background: "#D44638"
    }
  },
  githubbutton: {
    width: "49%",
    boxShadow: "none",
    background: "#222222",
    padding: "2px 7px 2px 2px",
    textTransform: "capitalize",
    "&:hover": {
      background: "#222222"
    }
  },
  signinButton: {
    margin: "16px 0px 18px 0px",
    width: "100%"
  },
  modalFooter: {
    margin: "48px 0px 8px 0px"
  },
  logintext: {
    fontSize: "12px",
    lineHeight: "14px",
    color: "#222222",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  forgetText: {
    fontSize: "12px",
    lineHeight: "14px",
    color: "#222222",
    display: "flex",
    justifyContent: "flex-end"
  },
  mainLink: {
    textDecoration: "none",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "14px",
    color: "#327C93",
    background: "transparent",
    textTransform: "capitalize",
    boxShadow: "none",
    padding: "0px",
    margin: "0px",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none"
    }
  },
  buttonIcon: {
    marginRight: "4px"
  },
  buttonImageBackground: {
    background: "white",
    marginRight: "20px",
    borderRadius: "2px",
    // height: "38px",
    // width: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  buttonImageBackgroundBlack: {
    background: "#222",
    marginRight: "10px",
    borderRadius: "2px",
    height: "38px",
    width: "38px",
    display: "flex",
    alignItems: "center"
  },
  githubButtonIcon: {
    backgroundColor: "# "
    // marginRight: "6px"
  },
  socialMediaIcon: {
    paddingLeft: "9px",
    paddingRight: "9px",
    paddingTop: "12px",
    paddingBottom: "12px",
    boxSizing: "unset"
  }
});
const theme = createMuiTheme({
  overrides: {
    MuiDialog: {
      // Name of the component ⚛️ / style sheet
      paperWidthSm: {
        maxWidth: 800,
        borderRadius: "10px"
      }
    },
    MuiTypography: {
      body1: {
        color: "#ABABAB",
        fontSize: "12px"
      }
    },
    MuiInput: {
      root: {
        marginTop: "20px",
        // width:"150%",
        paddingLeft: "10px !important",
        fontSize: "14px",
        borderRadius: "5px",
        border: "1px solid #EDE8EF",
        height: "40px"
      }
    },
    MuiButton: {
      label: {
        justifyContent: "flex-start"
      }
    }
  }
});

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("This field is required."),
  password: Yup.string().required("This field is required.")
});

const LoginUser = props => {
  const { classes, handleClose, closeLoginPopup } = props;
  const [ShowPass, setShowPass] = useState(true);
  const [emailid, setemail] = useState("");

  const handleSubmit = (values, setErrors, setSubmitting) => {
    props.loginUser(values, setErrors, setSubmitting);
  };
  useEffect(() => {
    if (props && (props.success === "admin" || props.success === "company")) {
      props.history.push("/dashboard");
      handleClose();
    } else if (props && props.success === "company") {
      props.history.push("/dashboard");
      handleClose();
    } else if (props && props.success === "candidate") {
      // console.log(props);
      if (props.checkStatus) {
        props.checkStatus();
      }
      if (props.temp) {
        handleClose();
      } else {
        props.history.push("/challenges");
        handleClose();
      }
    }
  }, [props.success]);
  const onChange = e => {
    setemail(e.target.value);
  };
  return (
    <div className={classes.paper}>
      <div className={classes.loginContent}>
        <Formik
          initialValues={{
            email: "",
            password: ""
          }}
          validationSchema={LoginSchema}
          onSubmit={(values, { setErrors, setSubmitting }) => {
            handleSubmit(values, setErrors, setSubmitting);
          }}
        >
          {() => (
            <Form>
              <ThemeProvider theme={theme}>
                <InputField
                  noMarginBottom
                  name="email"
                  onChange={onChange}
                  placeholder="Enter your Email ID"
                />
                <FormikTextField
                  name="password"
                  placeholder="Enter your Password "
                  type={ShowPass ? "password" : "text"}
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            setShowPass(!ShowPass);
                          }}
                          // onMouseDown={handleMouseDownPassword}
                        >
                          {ShowPass ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <span className={classes.forgetText}>
                  <Link
                    to={{
                      pathname: "/forget",
                      state: { emailid: emailid || "" }
                    }}
                    className={classes.forgotLink}
                    onClick={() => handleClose()}
                  >
                    Forgot Password ?
                  </Link>
                </span>
                <Grid
                  container
                  item
                  display="flex"
                  justify="space-between"
                  className={classes.signinButton}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.gmailbutton}
                    // startIcon={<EmailIcon />}
                    // startIcon={<EmailIcon />}
                    temp={props.temp}
                    href={`https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email&include_granted_scopes=true&response_type=token&redirect_uri=http://localhost:3000&client_id=${
                      googleOAuthCredentials[window.origin]
                    }`}
                  >
                    <span className={classes.buttonImageBackground}>
                      <img
                        src={gmailIcon}
                        alt="img"
                        className={classes.socialMediaIcon}
                        height="13px"
                        width="19px"
                      />{" "}
                    </span>
                    Sign in with Gmail
                  </Button>

                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.githubbutton}
                    temp={props.temp}
                    href="https://github.com/login/oauth/authorize?client_id=Iv1.0b9a42e52d03c7d0&scope=user&redirect_url=http://localhost:3000"
                  >
                    <span className={classes.buttonImageBackground}>
                      <img
                        src={githubIcon}
                        alt="img"
                        // className={classes.githubButtonIcon}
                        className={classes.socialMediaIcon}
                        height="13px"
                        width="19px"
                      />
                    </span>
                    Sign in with Github
                  </Button>
                </Grid>
                <span className={classes.logintext}>
                  Don't have an account?&nbsp;
                  {/* <a href="" className={classes.forgotLink}> */}
                  <Button
                    variant="contained"
                    className={classes.mainLink}
                    // onClick={() => isSignupPopupHandle()}
                    onClick={() => {
                      props.isSignupPopupHandle();
                      closeLoginPopup();
                    }}
                  >
                    Signup
                  </Button>
                  {/* </a> */}
                </span>
                <Grid
                  container
                  item
                  display="flex"
                  justify="center"
                  alignItems="center"
                  direction="row"
                  className={classes.modalFooter}
                >
                  <Button
                    className={classes.cancelButton}
                    variant="contained"
                    onClick={handleClose}
                    // onClick={() => props.history.push("/challenges")}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={classes.submitButton}
                    type="submit"
                    variant="contained"
                  >
                    Login
                  </Button>
                </Grid>
              </ThemeProvider>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    errors: state.errors,
    success: state.auth.hasRole
  };
};

export default connect(mapStateToProps, { loginUser, push })(
  withStyles(styles)(LoginUser)
);
