import React from "react";
import Moment from "moment";
import _map from "lodash/map";
import axios from "axios";
import {
  withStyles,
  Grid,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Button,
  Paper,
  createMuiTheme,
  ThemeProvider
} from "@material-ui/core";
import { InputField, DatePickerField } from "../../../Components/Common/Form";
import TextareaField from "../../../Components/Common/Form/TextareaField";
import { Formik, FieldArray } from "formik";
import { Form } from "react-formik-ui";
import CustomButton from "../../../Components/Common/Button";
import AddExperience from "../../../Assets/svgs/challenges/addExperience.svg";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CommonStyles from "../../../Theme/commonStyles";

const styles = () => ({
  root: {
    width: "100%",
    position: "relative",
    boxShadow: "none",
    height: "calc(100vh - 200px)",
    paddingBottom: "30px !important"
  },
  formBox: CommonStyles.formBox,
  workHeader: CommonStyles.workHeader,
  closeIcon: CommonStyles.closeIcon,
  inputSection: CommonStyles.inputSection,
  comment_section: CommonStyles.comment_section,
  input_field: CommonStyles.input_field,
  cancelButton: CommonStyles.cancelButton,
  submitButton: CommonStyles.submitButton,
  label: CommonStyles.label,
  formFooter: { marginTop: "30px", position: "absolute", bottom: 0 },
  addExpView: CommonStyles.addExpView,
  txtAddExp: CommonStyles.txtAddExp,
  icon: CommonStyles.icon
});
const theme = createMuiTheme({
  overrides: {
    MuiExpansionPanelDetails: {
      root: {
        padding: "8px",
        border: "none",
        flexDirection: "column"
      }
    },
    MuiExpansionPanel: {
      rounded: {
        border: "none",
        borderBottom: "1px solid #EFEFEF",
        borderRadius: "0px"
      }
    },
    MuiExpansionPanelSummary: {
      root: {
        padding: "0 8px 0 8px"
      }
    },
    MuiSvgIcon: {
      root: { top: "0px", margin: "0px 10px 0px 10px" }
    }
  }
});

class WorkExperience extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      company_name: "",
      job_title: "",
      location: "",
      from_time: new Date(),
      to_time: new Date(),
      description: " ",
      expanded: 0,
      userData: {}
    };
  }

  componentDidMount() {
    const userId = localStorage.getItem("user_id");
    const query = { user_id: userId };
    axios
      .get(`/api/v2/userprofile`, {
        params: {
          filter: JSON.stringify(query)
        }
      })
      .then(response => {
        this.setState({ userData: response.data });
      })
      .catch(error => {
        console.log("error,,,,,,", error);
      });
  }

  onSubmit = async values => {
    const user = this.state.userData[0];
    const _id = user && user._id;
    let work_experience = user.work_experience || [];

    work_experience = values.workExp;

    const apiCall = await axios.put(`/api/v2/userprofile/${_id}`, {
      work_experience,
      isSubmit: true
    });
    // after click submit, open first view
    if (apiCall && apiCall.status === 200) {
      this.props.viewProfileHandler(0, true);
      if (work_experience.length > 1) this.setState({ expanded: 0 });
      else this.setState({ expanded: false });
    }
  };

  handleClose = () => {
    const { userData } = this.state;
    userData &&
      userData[0] &&
      userData[0].isSubmit &&
      this.props.viewProfileHandler(0);
  };

  handleChange = panel => (event, isExpanded) => {
    this.setState({ expanded: isExpanded ? panel : 0 });
  };

  newPush = push => {
    push({});

    var works =
      this.state.userData[0] &&
      this.state.userData[0].work_experience &&
      this.state.userData[0].work_experience.length;
    this.setState({ expanded: works });
  };

  render() {
    const { classes } = this.props;
    const {
      company_name,
      job_title,
      location,
      from_time,
      to_time,
      description,
      expanded,
      userData
    } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <Paper className={classes.root}>
          <Formik
            initialValues={{
              company_name: company_name || "",
              job_title: job_title || "",
              location: location || "",
              from_time: from_time || new Date(),
              to_time: to_time || new Date(),
              description: description || " ",
              workExp:
                (userData && userData[0] && userData[0].work_experience) || []
            }}
            enableReinitialize
            onSubmit={values => this.onSubmit(values)}
          >
            {({
              values,
              errors,
              touched,
              setFieldTouched,
              setFieldValue,
              handleSubmit
            }) => (
              <Form
                onSubmit={handleSubmit}
                className={classes.form}
                encType="multipart/form-data"
                autoComplete="off"
              >
                <div>
                  <FieldArray
                    name="workExp"
                    render={({ insert, remove, push }) => (
                      <div>
                        {_map(values.workExp, (v, i) => (
                          <Grid className={classes.formBox}>
                            <ExpansionPanel
                              expanded={expanded === i}
                              onChange={this.handleChange(i)}
                            >
                              <Grid
                                container
                                spacing={2}
                                justify="space-between"
                              >
                                <label className={classes.workHeader}>
                                  Work {i + 1}
                                </label>
                                <ExpansionPanelSummary
                                  expandIcon={<ExpandMoreIcon />}
                                >
                                  <label
                                    className={classes.closeIcon}
                                    onClick={() => remove(i)}
                                  >
                                    <DeleteIcon />
                                  </label>
                                </ExpansionPanelSummary>
                              </Grid>
                              <ExpansionPanelDetails>
                                <Grid container spacing={2}>
                                  <Grid
                                    item
                                    lg={6}
                                    sm={6}
                                    className={classes.inputSection}
                                  >
                                    <InputField
                                      name={`workExp.${i}.company_name`}
                                      // value={v.company_name}
                                      label="Company Name"
                                      placeholder="Enter Company Name"
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    lg={6}
                                    sm={6}
                                    className={classes.inputSection}
                                  >
                                    <InputField
                                      name={`workExp.${i}.job_title`}
                                      label="Job Title"
                                      placeholder="Enter Job Title"
                                    />
                                  </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                  <Grid
                                    item
                                    xs={6}
                                    className={classes.inputSection}
                                  >
                                    <InputField
                                      name={`workExp.${i}.location`}
                                      label="Location"
                                      placeholder="Enter Location"
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs
                                    sm
                                    className={classes.inputSection}
                                  >
                                    <DatePickerField
                                      name={`workExp.${i}.from_time`}
                                      onChange={setFieldValue}
                                      onBlur={setFieldTouched}
                                      experience="experience"
                                      format="MMM, YYYY"
                                      value={Moment(v && v.from_time).format(
                                        "MMM,YYYY"
                                      )}
                                      disableFuture={false}
                                      label="Duration"
                                      errors={errors.from_time}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs
                                    sm
                                    className={classes.inputSection}
                                  >
                                    <DatePickerField
                                      name={`workExp.${i}.to_time`}
                                      onChange={setFieldValue}
                                      onBlur={setFieldTouched}
                                      format="MMM, YYYY"
                                      value={Moment(v && v.to_time).format(
                                        "MMM,YYYY"
                                      )}
                                      experience="experience"
                                      disableFuture={false}
                                      label=" "
                                      errors={errors.to_time}
                                    />
                                  </Grid>
                                </Grid>

                                <Grid item lg={12} xs={12}>
                                  <label className={classes.label}>
                                    Description
                                  </label>
                                  <div className={classes.comment_section}>
                                    <TextareaField
                                      name={`workExp.${i}.description`}
                                      className={classes.input_field}
                                      placeholder="Enter Work Description"
                                    />
                                  </div>
                                </Grid>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </Grid>
                        ))}
                        <div>
                          <Grid
                            item
                            sg={2}
                            xs={10}
                            className={classes.addExpView}
                          >
                            <img
                              src={AddExperience}
                              alt="AddExperience"
                              className={classes.icon}
                            />
                            <label
                              className={classes.txtAddExp}
                              onClick={() => this.newPush(push)}
                            >
                              Add Work Experience
                            </label>
                          </Grid>
                        </div>
                      </div>
                    )}
                  />
                </div>

                <Grid
                  item
                  container
                  xs={12}
                  sm={12}
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                  className={classes.formFooter}
                >
                  <Button
                    onClick={this.handleClose}
                    className={classes.cancelButton}
                  >
                    Cancel
                  </Button>
                  <CustomButton
                    type="submit"
                    label="Submit"
                    className={classes.submitButton}
                  ></CustomButton>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(WorkExperience);
