/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from "react";
import axios from "axios";
import { Fab, Grid, withStyles } from "@material-ui/core";
import { ClearRounded, InsertDriveFile } from "@material-ui/icons";
import { ErrorMessage } from "formik";
import UploadIcon from "../../../Assets/UploadIcon.png";

import classNames from "classnames";
const ASSET_URL = process.env.REACT_APP_ASSET_URL;

class Thumb extends React.Component {
  state = {
    loading: false
    // thumb: undefined
  };

  // componentWillReceiveProps() {
  // if (!nextProps.file) {
  //   return;
  // }
  // this.setState({ loading: true }, () => {
  //   let reader = new FileReader();
  //   reader.onloadend = () => {
  //     this.setState({ loading: false, thumb: reader.result });
  //   };
  //   reader.readAsDataURL(nextProps.file);
  // });
  // }

  render() {
    const { file, classes, fileType } = this.props;
    const { loading } = this.state;
    const clear = this.props.isclear || false;

    if (!file) {
      return null;
    }

    if (loading) {
      return <p>loading...</p>;
    }

    return (
      <React.Fragment>
        {fileType === "doc" ? (
          <span>
            <InsertDriveFile className="img-thumbnail mt-2" />
            {file.name}
            <Fab className={classes.fab_icon} color="primary" aria-label="Add">
              <ClearRounded
                className={classes.delete_icon}
                onClick={this.props.handleClick}
              />
            </Fab>
          </span>
        ) : (
          <span>
            <img
              // src={this.props && this.props.value ? this.props.value : previewUrl}
              src={file}
              className={
                this.props.isCircle ? classes.cirPreview : classes.previewImage
              }
              alt={this.props.label}
            />
            {!clear && (
              <Fab
                className={
                  this.props.isCircle ? classes.fab_icon1 : classes.fab_icon
                }
                color="primary"
                aria-label="Add"
              >
                <ClearRounded
                  className={classes.delete_icon}
                  onClick={this.props.handleClick}
                />
              </Fab>
            )}
          </span>
        )}
        {/* (
          <img
            src={file}
            alt={file.name}
            style={{ height: "100px", width: "150px", borderRadius: "5px" }}
            className="img-thumbnail mt-2"
            height={50}
            width="auto"
          />
        )} */}
        {/* <Fab className={classes.fab_icon} color="primary" aria-label="Add">
          <ClearRounded
            className={classes.delete_icon}
            onClick={this.props.handleClick}
          />
        </Fab> */}
      </React.Fragment>
    );
  }
}
const styles = theme => ({
  form_control: {
    display: "none",
    marginTop: "10px",
    marginBottom: "10px",
    border: "none"
  },
  fab_icon: {
    width: "29px",
    height: "29px",
    position: "relative",
    top: "-100px",
    left: "-16px",
    minHeight: "17px"
  },
  fab_icon1: {
    width: "20px",
    height: "20px",
    position: "relative",
    top: "-3px",
    left: "-44px",
    minHeight: "17px"
  },
  previewImage: { height: "100px", width: "150px", borderRadius: "5px" },
  cirPreview: { height: "100px", width: "100px", borderRadius: "100%" },
  delete_icon: {
    width: "19px",
    height: "19px"
  },
  error: {
    color: "#f53b57",
    fontSize: "0.75rem",
    fontWeight: "400",
    padding: "9px 0px 0px 8px",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ],
    marginTop: "-5px",
    textAlaign: "left",
    lineHeight: "1em"
  },
  uploadLogo: { padding: theme.spacing(2) },
  spacingLogo: {
    padding: theme.spacing(2),
    borderRadius: "50%",
    background: "whiteSmoke",
    width: "47px",
    height: "48px",
    borderLeft: props => (props.required ? "2px solid #F3A7A7" : "")
  },
  uploadImage: {
    background: "none"
  },
  editProfile: {
    borderRadius: "50%"
  },
  imageInput: {
    paddingTop: "16px"
  },
  formGroup: {
    paddingTop: "13px"
  }
});
class UploadField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // key: "",
      img_visible: this.props.value
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.value && prevProps.value !== this.props.value) {
      this.setState({ img_visible: true });
    }
  }

  handleChange = value => {
    let imgUrl;
    let imgKey;
    const { files } = value.currentTarget;
    const { multiple } = this.props;
    if (multiple) {
      imgUrl = [];
      imgKey = [];
    }
    // const callApis = new Promise((resolve, reject) => {
    // promise to upload all the files on the server first and then set state and send data to parent component
    if (files) {
      Array.from(files).map(file => {
        let file_new = file;
        const bodyFormData = new FormData();
        bodyFormData.append("type", this.props.folder);
        bodyFormData.append("file", file_new);
        axios
          .post("/api/upload", bodyFormData)
          .then(res => {
            const { key } = res.data.data;
            // this.setState({ key: res.data.data.key });
            const url = `${ASSET_URL}${res.data.data.key}`;
            console.log("url :", url);

            if (multiple) {
              imgUrl.push(url);
              imgKey.push(key);
            } else {
              imgUrl = url;
              imgKey = key;
            }
            this.setState(
              {
                img_visible: true
                // key: res.data.data.key
              },
              () => {
                this.props.onChange(this.props.name, imgUrl);
              }
            );
          })
          .catch(err => {
            return err;
          });
        return null;
      });
    }

    // if (multiple && imgUrl.length > 0) {
    // resolve({ imgUrl });
    // } else if (!multiple && imgUrl) {
    //   resolve({ imgUrl });
    // } else {
    // }
    // });

    // this.props.onChange("preview", value.currentTarget.files[0]);

    // callApis
    //   .then(response => {
    //     this.setState({ img_visible: true }, () => {
    //       this.props.onChange(this.props.name, response.imgUrl);
    //     });
    //   })
    //   .catch(err => {
    //   });
  };

  handleClick = key => {
    // var key = this.state.key;
    // const key = url && url.substring(url.lastIndexOf("/") + 1);

    if (key) {
      axios
        .post("/api/deleteImage", { key })
        .then(() => {})
        .catch(() => {});
      this.setState({ img_visible: false });
    }
  };

  render() {
    // return <div>ccc</div>;
    const {
      classes,

      multiple,
      fileType,
      thumbContainerClass
    } = this.props;
    let acceptedFIles = "*";
    if (!fileType) {
      acceptedFIles = "image/*";
    } else if (fileType === "doc") {
      acceptedFIles = "application/vnd.ms-powerpoint,  application/pdf";
    }
    return (
      <div className={classes.formGroup}>
        {!this.state.img_visible && (
          <div className={classes.imageInput}>
            <Grid container alignItems="center" direction="row">
              <Grid className={classes.spacingLogo}>
                <label>
                  {/* {type === "club" ? (
                    <img
                      src={UploadImage}
                      alt=" icon"
                      className={classes.uploadImage}
                    />
                  ) : (
                  )} */}
                  <img src={UploadIcon} alt=" icon" />
                  <input
                    type="file"
                    id={this.props.id}
                    name={this.props.name}
                    onChange={this.handleChange}
                    className={classes.form_control}
                    multiple={multiple}
                    accept={acceptedFIles}
                  />
                </label>
              </Grid>
              <Grid className={classes.uploadLogo}>
                <label htmlFor={this.props.id}>{this.props.label}</label>
              </Grid>
            </Grid>
            {/* <input
              id={this.props.id}
              name={this.props.name}
              type="file"
              onChange={this.handleChange}
              className={classes.form_control}
              style={{ color: "transparent" }}
            /> */}
          </div>
        )}
        <div className={classes.error}>
          <ErrorMessage name={this.props.name} />
        </div>

        {this.state.img_visible && (
          <div className={classNames("impage_preview", thumbContainerClass)}>
            {multiple ? (
              this.props.value &&
              this.props.value.map(url => {
                return (
                  <Thumb
                    file={url}
                    classes={classes}
                    key
                    handleClick={() => this.handleClick(url)}
                  />
                );
              })
            ) : (
              <Thumb
                isclear={this.props.isclear}
                file={this.props.value}
                classes={classes}
                handleClick={() => this.handleClick(this.props.value)}
                fileType={this.props.fileType}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(UploadField);
