import React from "react";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import _map from "lodash/map";
import Sticky from "react-stickynode";
import DOMPurify from "dompurify";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

const styles = theme => {
  return {
    button: {
      width: "100%",
      textTransform: "capitalize",
      borderColor: "#EFEFEF",
      margin: "0px",
      backgroundColor: "#fff"
    },
    SelectButton: {
      backgroundColor: "#EFEFEF",
      width: "100%",
      textTransform: "capitalize"
    },
    line: { borderTop: "1px solid #EFEFEF", margin: "0px" },
    innerDiv: {
      positon: "fixed",
      top: "10px"
    },
    htmlContainer: {
      margin: "10px",
      padding: "0px 20px",
      maxWidth: "100%",
      overflow: "hidden",
      marginBottom: "50px"
    },
    displayContent: {
      width: "100%"
    },
    htmlEditorContent: {
      margin: "10px 0 0 0"
    }
  };
};
const theme = createMuiTheme({
  overrides: {
    MuiButton:{
      endIcon:{
        position:"absolute",
        // marginRight: "-270px",
        marginLeft: "650px",
        width: "100%",
        boxShadow:"none",
        textTransform: "capitalize",
        borderColor: "#EFEFEF",
        // margin: "0px",
        // backgroundColor: "#fff"
      }
    }
  }})
class SelectComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false,
      selectedPage:
        this.props.myProfile === true
          ? this.props.data[0].name
          : this.props.data.pages[0].name,
      htmlFile:
        this.props.myProfile === true
          ? ""
          : this.props.data.pages[0].htmlContent
    };
  }

  render() {
    const { data, classes, onChange, myProfile, singleData } = this.props;

    const sanitizer = DOMPurify.sanitize;

    return (
      <div>
        <Sticky top={0} enabled={true}>
          <div className={classes.innerDiv}>
            <hr className={classes.line} />
            <div>
              <ThemeProvider theme={theme}>
              <Button
                className={classes.button}
                title="Select"
                variant="contained"
                endIcon={
                  this.state.selected ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )
                }
                onClick={() =>
                  this.setState({ selected: !this.state.selected })
                }
              >
                {myProfile ? singleData.name : this.state.selectedPage}
              </Button>
              </ThemeProvider>
            </div>

            <hr className={classes.line} />
          </div>
          {this.state.selected === true && (
            <>
              {myProfile ? (
                <>
                  {_map(data, data => (
                    <>
                      <Button
                        className={classes.button}
                        onClick={() => {
                          this.setState({
                            selected: !this.state.selected
                          });
                          onChange(data);
                        }}
                      >
                        {data.name}
                      </Button>
                    </>
                  ))}
                </>
              ) : (
                <>
                  {_map(data.pages, data => (
                    <>
                      <Button
                        onClick={() => {
                          this.setState({
                            selectedPage: data.name,
                            htmlFile: data.htmlContent,
                            selected: !this.state.selected
                          });
                        }}
                        className={classes.button}
                      >
                        {data.name}
                      </Button>
                    </>
                  ))}
                </>
              )}
            </>
          )}
        </Sticky>

        {!myProfile && (
          <div className={classes.htmlContainer}>
            <div className={classes.displayContent}>
              <div
                className="htmlEditorContent"
                dangerouslySetInnerHTML={{
                  __html: sanitizer(this.state.htmlFile)
                }}
              ></div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(SelectComponent);
