export default class PubSub {
  static _events = {};

  static addEventListener(type, f) {
    const events = PubSub._events[type];
    if (events) {
      events.push(f);
    } else {
      PubSub._events[type] = [f];
    }
  }

  static removeEventListener(type, f) {
    // eslint-disable-next-line no-unused-vars
    PubSub._events =
      PubSub._events[type] && PubSub._events[type].filter(fn => f !== fn);
  }

  static send(type, value) {
    PubSub._events[type] &&
      PubSub._events[type].forEach(e => {
        e(value);
      });
  }
}
