import React, { useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { Grid, Paper } from "@material-ui/core";
import myProfile from "../../../Assets/Profileimage.png";
import { Phone, Mail, MapPin, Edit2 } from "react-feather";
import LinkedinA from "../../../Assets/icons/Linkedin.svg";
import LinkedinG from "../../../Assets/icons/linkedin_grey.svg";
import FacebookA from "../../../Assets/icons/facebook_a.svg";
import FacebookG from "../../../Assets/icons/facebook_grey.svg";
import GithubA from "../../../Assets/icons/Github.svg";
import GithubG from "../../../Assets/icons/github_grey.svg";
import ViewMyTechnicalSkills from "./ViewMyTechnicalSkills";
import CommonStyles from "../../../Theme/commonStyles";
import Tooltip from "../../../Components/Common/NameToolTip";
import { capitalize } from "../../../Utils/StringFunctions";

const $quoteSym = " ";

const styles = () => ({
  root: {
    // width: `calc(100% - ${drawerWidth}px - 80px)`,
    // width: "94%",
    // marginTop: theme.spacing.unit * 3,
    overflowY: "auto",
    padding: "14px 40px",
    paddingTop: "5%",
    position: "relative",
    minHeight: "100vh",
    zIndex: 1,
    background: "#FAFAFA",
    boxShadow: "none"
    // left: "240px"
  },
  formContent: {
    width: 500,
    padding: "34px 24px"
  },
  modelHeader: {
    paddingBottom: "5px",
    position: "relative",
    "&:after": {
      top: "48px",
      left: "24px",
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute"
    }
  },
  personalDetail: {
    background: "#ffffff",
    padding: "18px 20px"
  },
  profilePhoto: {
    width: "130px",
    height: "130px",
    margin: "0 auto"
  },
  profile: {
    borderRadius: "50%",
    height: "100%",
    width: "100%"
  },
  userName: {
    fontWeight: "bold",
    // fontSize: "26px",
    fontSize: props => (props.editData === "no" ? "22px" : "26px"),
    lineHeight: "44px",
    color: "#000000",
    textAlign: "center",
    margin: "4px 0px 10px",
    textTransform: "capitalize"
  },
  subTitle: {
    // marginTop: "10px",
    fontWeight: "normal",
    fontSize: props => (props.editData === "no" ? "16px" : "18px"),
    lineHeight: "25px",
    color: "#000000",
    display: "flex"
  },
  locationTitle: {
    fontWeight: "normal",
    fontSize: props => (props.editData === "no" ? "16px" : "18px"),
    lineHeight: "25px",
    color: "#000000",
    display: "flex",
    textTransform: "capitalize"
  },
  mainTitle: {
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "28px",
    color: "#222222"
  },
  profileIcon: {
    color: "#4CABD0",
    // position: "absolute",
    // top: "4px",
    right: "6px",
    marginRight: "5px"
  },
  socialIcon: CommonStyles.socialIcon,
  socialData: {
    display: "flex",
    justifyContent: "center",
    borderTop: "1px solid #EFEFEF",
    borderBottom: "1px solid #efefef",
    background: "#ffffff",
    padding: " 20px 0px",
    margin: "14px 0px"
  },
  titleLeft: {
    paddingBottom: "5px",
    position: "relative",
    fontSize: props => (props.editData === "no" ? "18px" : "24px"),
    lineHeight: "32px",
    "&:after": {
      top: "35px",
      left: "4px",
      width: "24px",
      borderBottom: "3px solid #4CABD0",
      content: `'${$quoteSym}'`,
      position: "absolute"
    }
  },
  bioContent: {
    fontSize: props => (props.editData === "no" ? "14px" : "16px"),
    lineHeight: props => (props.editData === "no" ? "24px" : "32px"),
    color: "#222222"
  },
  editButton: {
    background: "transparent",
    border: "none",
    color: "#CDCDCD",
    position: "relative",
    right: "-20px",
    cursor: "pointer"
  },
  mainHeader: {
    display: "flex",
    justifyContent: "flex-end"
  },
  editProfile: {
    position: "relative",
    top: "-28px",
    right: "-84px",
    background: "#fff",
    borderRadius: "50%",
    border: "none",
    color: "#4CABD0",
    padding: "2px 6px",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)"
  },
  contactContainer: {
    display: "flex",
    textAlign: "center",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "10px"
  }
});

function ViewMyProfile(props) {
  const { classes, userData } = props;
  // console.log("userdata in viewprofile", userData);
  const editPersonal = () => {
    props.viewProfileHandler(0);
  };
  const emailNameChar = userData && userData.email;
  const locationNameChar = userData && userData.location;
  return (
    <React.Fragment>
      <Paper className={classes.root}>
        <Grid container spacing={2}>
          <Grid item sm={3} lg={3} xs={6}>
            <div className={classes.personalDetail}>
              {props.editData === "no" ? (
                ""
              ) : (
                <div className={classes.mainHeader}>
                  <button className={classes.editButton} onClick={editPersonal}>
                    <Edit2 />
                  </button>
                </div>
              )}
              <div className={classes.profilePhoto}>
                <img
                  src={userData && userData.photo ? userData.photo : myProfile}
                  className={classes.profile}
                  alt="My Profile"
                />
                {/* {props.editData === "no" ? (
                  ""
                ) : (
                  <button className={classes.editProfile}>
                    <Edit2 size={22} />
                  </button>
                )} */}
              </div>
              <p className={classes.userName}>
                {userData && userData.first_name ? userData.first_name : "-"}{" "}
                {userData && userData.last_name ? userData.last_name : "-"}
              </p>
              <div className={classes.contactContainer}>
                <Phone
                  size={props.editData === "no" ? 15 : 20}
                  className={classes.profileIcon}
                />

                <p className={classes.subTitle}>
                  {userData && userData.contact_number
                    ? userData.contact_number
                    : "-"}
                </p>
              </div>
              <div className={classes.contactContainer}>
                <Mail
                  size={props.editData === "no" ? 15 : 20}
                  className={classes.profileIcon}
                />
                <p className={classes.subTitle}>
                  {emailNameChar && emailNameChar.length > 10 ? (
                    <Tooltip
                      amount={props.editData === "no" ? "15" : "25"}
                      text={userData && userData.email}
                    />
                  ) : (
                    userData && userData.email
                  )}
                </p>
              </div>

              <div className={classes.contactContainer}>
                <MapPin
                  size={props.editData === "no" ? 15 : 20}
                  className={classes.profileIcon}
                />
                <p className={classes.locationTitle}>
                  {locationNameChar && locationNameChar.length > 10 ? (
                    <Tooltip
                      amount={props.editData === "no" ? "15" : "20"}
                      text={userData && userData.location}
                    />
                  ) : (
                    capitalize(userData && userData.location)
                  )}
                </p>
              </div>

              <div className={classes.socialData}>
                <img
                  src={userData && userData.github ? GithubA : GithubG}
                  alt=""
                  className={classes.socialIcon}
                />
                <img
                  src={userData && userData.linkedin ? LinkedinA : LinkedinG}
                  alt=""
                  className={classes.socialIcon}
                />
                {/* <img src={Facebook} alt="" className={classes.socialIcon} /> */}
                <img
                  src={userData && userData.facebook ? FacebookA : FacebookG}
                  alt=""
                  className={classes.socialIcon}
                />
              </div>
              <div className={classes.bioDetail}>
                <p className={classes.titleLeft}>Bio</p>
                <p className={classes.bioContent}>
                  {userData && userData.bio ? userData.bio : "-"}
                </p>
              </div>
            </div>
          </Grid>
          <Grid item sm={9} lg={9} xs={6}>
            <ViewMyTechnicalSkills
              userData={userData}
              viewProfileHandler={props.viewProfileHandler}
              editData={props.editData}
            />
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {})(withStyles(styles)(ViewMyProfile));
