/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-else-return */
/* eslint-disable no-unused-expressions */
import React from "react";
import axios from "axios";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import {
  Dialog,
  DialogContent,
  Grid,
  Typography,
  Button
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { Form } from "react-formik-ui";
import { createMuiTheme } from "@material-ui/core/styles";
import * as Yup from "yup";
import { Formik } from "formik";

import TextField from "@material-ui/core/TextField";
import Dropzone from "react-dropzone-uploader";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import UploadIcon from "../../Assets/teamUploadIcon.PNG";

const theme = createMuiTheme({
  overrides: {
    MuiDialog: {
      // Name of the component ⚛️ / style sheet
      paperWidthSm: {
        maxWidth: 630,
        borderRadius: "10px"
      }
    },

    MuiTypography: {
      body1: {
        color: "#ABABAB",
        fontSize: "12px"
      }
    },
    MuiFormControl:{
      root:{
        width:"100%"
      }
    }   
  }
});
const styles = () => ({
  teamModal: {
    width: 630,
    padding: "34px 46px",
    overflow: "hidden",
    maxWidth: 630,
    textAlign: "center"
  },
  member_modal: {
    minWidth: "400px"
  },
  main: {
    padding: "24px"
  },
  modalImage: {
    width: "100%"
  },

  UserDetail: {
    position: "relative",
    bottom: "54px",
    left: "57px"
  },
  coworkerSection: {
    borderBottom: "1px solid #efefef",
    height: "74%"
  },
  modalTitle: {
    fontSize: "24px",
    fontWeight: "normal",
    color: "#222",
    // textTransform: "capitalize",
    lineHeight: "28px"
  },
  modalInnerCont: {
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "18px",
    color: "#626262"
  },
  forgotLink: {
    color: "#327C93",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "21px",
    textDecoration: "none"
  },
  modalForm: {
    maxWidth: "100%",
    textAlign: "center"
  },
  submitButton: {
    background: "#327C93",
    borderRadius: "5px",
    padding: "10px 30px",
    color: "#ffffff",
    // marginLeft: "20px",
    marginLeft: "9px",
    "&:hover": {
      background: "#327C93",
      color: "#ffffff"
    }
  },
  cancelButton: {
    background: "#efefef",
    borderRadius: "5px",
    padding: "10px 30px",
    color: "#327C93",
    "&:hover": {
      background: "#efefef",
      color: "#327C93"
    }
  },
  gmailbutton: {
    background: "#D44638",
    padding: " 6px 8px",
    textTransform: "capitalize",
    "&:hover": {
      background: "#D44638"
    }
  },
  githubbutton: {
    background: "#222222",
    padding: " 6px 8px",
    textTransform: "capitalize",
    "&:hover": {
      background: "#222222"
    }
  },
  signinButton: {
    margin: "16px 0px 18px 0px"
  },
  modalFooter: {
    margin: "54px 0px 10px 0px"
  },
  imageContainer: {
    cursor:"pointer",
    borderStyle: "solid",
    borderWidth: 2,
    borderRadius: 20,
    margin: "40px 0px 60px 0px",
    borderColor: "#EFEFEF",
    justifyContent: "center",
    width: "60%",
    alignItems: "center",
    // borderColor: "#EFEFEF"
  },
  boxContainer:{
    marginLeft:"20px",
  },
  imgContainer:{
    cursor:"pointer",
  },
  imagepreview:{
    height: "100px",
    width: "150px",
    borderRadius: "15px"
  },
  preview:{
    alignSelf: "flex-start",
    margin: "10px 3%",
    fontFamily: "Helvetica",
    display: "flex",
    justifyContent: "space-between"
  },
  InputComponent:{
  cursor:"pointer",
  textAlign: "center",
  fontSize: "18px",
  color: "#222222",
  marginTop: "0px"
  },
  dropzoneComponent:{
    dropzone: {
      minHeight: 100,
      display: "grid"
    },
  },
  InputLabel:{
  display: "none",
  },
  layoutVIew:{
  cursor: "unset",
  },
});
const loginParticipantSchema = Yup.object().shape({
  teamname: Yup.string().required("Team name Required")
});
function AddParticipantTeamModal(props) {
  const {
    classes,
    closeTeamModal,
    // teammatemodel,
    setteammatemodel,
    selectedimage,
    setselectedimage,
    teamname,
    setteamname
  } = props;

  const getFilesFromEvent = e => {
    return new Promise(resolve => {
      getDroppedOrSelectedFiles(e).then(chosenFiles => {
        resolve(chosenFiles.map(f => f.fileObject));
      });
    });
  };

  const handleClose = () => {
    closeTeamModal();
  };

  const getUploadParams = async ({ file, meta: { name } }) => {
    const url = { fileKey: `team/banner/${name}` };

    const response = await axios.post("/api/s3/presigned_url", url);

    const res = { method: "PUT", url: response.data.url, body: file };
    setselectedimage(res.url.replace(/(.+?\?).*/, "$1").slice(0, -1));
    return res;
  };

  const settingTeamname = name => {
    setteamname(name.target.value);
  };

  const Preview = ({ meta }) => {
    // console.log(meta, "Helooo metas");

    const { percent, previewUrl } = meta;
    return (
      <span
        className={classes.preview}
      >
        <img
          src={previewUrl}
          className={classes.imagepreview}
          alt=""
        />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="50%"
          className={classes.boxContainer}
        >
          <Box width="100%" mr={1}>
            <LinearProgress variant="buffer" value={percent} />
          </Box>
          <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary">{`${Math.round(
              percent
            )}%`}</Typography>
          </Box>
        </Box>
      </span>
    );
  };

  const InputComponent = ({ accept, onFiles, files, getFilesFromEvent }) => {

    return (
      <>
        {files.length <= 0 && (
          <label>
            <img alt="Upload" src={UploadIcon} className={classes.imgContainer} />
            <p
              className={classes.InputComponent}
            >
              Upload Team Banner
            </p>
            <input
              className={classes.InputLabel}
              type="file"
              accept={accept}
              multiple
              onChange={e => {
                getFilesFromEvent(e).then(chosenFiles => {
                  onFiles(chosenFiles);
                });
              }}
            />
          </label>
          )}
      </>
    );
  };

  const Layout = ({ previews, submitButton, dropzoneProps, files, input }) => {
    return (
      <div {...dropzoneProps}>
        {files.length === 0 && <span classNames={classes.layoutView}></span>}

        {previews}
        {input}
        {/* {files.length > 0 && submitButton} */}
      </div>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Formik
        initialValues={{
          teamname: "",
          teamlogo: ""
        }}
        validationSchema={loginParticipantSchema}
        onSubmit={values => {
          //   this.props.applyToChallenge(values, this.props.history);
          }}
      >
        {() => (
          <Form className={classes.form}>
            <Dialog
              open={props.openTeamModal}
              onClose={() => {
                props.closeTeamModal();
                setteamname("");
              }}
              aria-labelledby="form-dialog-title"
            >
              <DialogContent className={classes.teamModal}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} className={classes.modalForm}>
                    <Typography
                      variant="subtitle1"
                      className={classes.modalTitle}
                    >
                      Fill all the Details to Participate in the Challenge
                    </Typography>
                    <p className={classes.modalInnerCont}>
                      Turpis vehicula eget felis amet, bibendum et tristique et
                      porttitor.
                      <br /> Vestibulum et blandit mollis a vitae adipiscing
                      risus pharetra.
                    </p>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      container
                      alignItems="center"
                      justify="center"
                      
                    >
                      <div className={classes.imageContainer} >
                        <Dropzone
                          getUploadParams={getUploadParams}
                          // onChangeStatus={handleChangeStatus}
                          // onSubmit={handleSubmit}
                          maxFiles={1}
                          classNames={classes.dropzoneComponent}
                          accept="image/*"
                          PreviewComponent={Preview}
                          InputComponent={InputComponent}
                          LayoutComponent={Layout}
                          getFilesFromEvent={getFilesFromEvent}
                          // autoUpload
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        id="outlined-basic"
                        label="Team name"
                        variant="outlined"
                        placeholder="Give your Team a Name"

                        onChange={text => settingTeamname(text)}
                        name="teamname"
                        value={teamname}
                        inputProps={{ maxLength: 50 }}
                      />
                    </Grid>

                    <Grid
                      container
                      item
                      display="flex"
                      justify="center"
                      alignItems="center"
                      direction="row"
                      className={classes.modalFooter}
                    >
                      <Button
                        className={classes.cancelButton}
                        type="cancel"
                        variant="contained"
                        onClick={() => {
                          handleClose();
                          setteamname("");
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        className={classes.submitButton}
                        type="submit"
                        variant="contained"
                        onClick={() => {
                          const values = { teamname, selectedimage };
                          console.log(values, "Valuessss");

                          // teamModalOpen();
                          handleClose();
                          setteammatemodel(true);
                        }}
                        disabled={!teamname || !selectedimage}
                      >
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
            </Dialog>
          </Form>
        )}
      </Formik>
    </ThemeProvider>
  );
}

const mapStateToProps = state => {
  return {
    errors: state.errors
  };
};

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles)(AddParticipantTeamModal));
